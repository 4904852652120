import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useAsyncFn } from 'react-use';
import { Button, Input, Divider, message, Skeleton, Spin } from 'antd';
import DragDropUpload from 'components/DragDropUpload';
import {
  getNotReadyForQCTemplate,
  getProviderOnProbationCurrentFamilyTemplate,
  getProviderOnProbationNewFamilyTemplate,
  getReadyForQCTemplate,
  getTemplateBody,
} from 'templates/index';
import communicationsService from 'services/communications.service';
import filesService from 'services/files.service';
import styles from './SendEmailModal.module.less';
import Form from 'components/Form';
import Editor from 'components/Editor';
import { getDisabledDateValidator, getFullName } from 'utils';
import SwitchField from 'components/Switch/SwitchField';
import useApplicationOwner from 'hooks/useApplicationOwner';
import { Add, Subtract } from '@carbon/icons-react';
import Space, { Spacer } from 'components/Space/Space';
import AriaDatePicker from 'components/AriaDatePicker';

export default function ScheduleEmail({
  className,
  setVisible,
  entityId,
  entityType,
  toMail,
  showSchedule,
  onCancel,
  loading: dataLoading,
  record,
  reload,
}) {
  const [emailForm] = Form.useForm();
  const sendNow = Form.useWatch('sendNow', emailForm);
  const scheduledDate = Form.useWatch('scheduledDate', emailForm);
  const { owner, loading: isOwnerDataLoading } = useApplicationOwner({ entityType });
  const templates = useMemo(
    () => [
      {
        title: 'Ready For QC Template',
        description: 'Ready For QC Template',
        content: getTemplateBody({
          entityType,
          subject: 'Ready For QC',
          content: getReadyForQCTemplate({
            fullName: getFullName(owner) || 'Sponsor Name',
          }),
        }),
      },
      {
        title: 'Not Ready For QC Template',
        description: 'Not Ready For QC Template',
        content: getTemplateBody({
          entityType,
          subject: 'Not Ready For QC',
          content: getNotReadyForQCTemplate({
            fullName: getFullName(owner) || 'Sponsor Name',
          }),
        }),
      },
      {
        title: 'Provider on Probation - Current Family',
        description: 'Provider on Probation - Current Family',
        content: getTemplateBody({
          entityType,
          subject: 'Provider on Probation',
          content: getProviderOnProbationCurrentFamilyTemplate({ fullName: getFullName(owner) || 'Sponsor Name' }),
        }),
      },
      {
        title: 'Provider on Probation - New Family & COP',
        description: 'Provider on Probation - New Family & COP',
        content: getTemplateBody({
          entityType,
          subject: 'Provider on Probation',
          content: getProviderOnProbationNewFamilyTemplate({ fullName: getFullName(owner) || 'Sponsor Name' }),
        }),
      },
    ],
    [entityType, owner],
  );
  useEffect(() => {
    if (sendNow && scheduledDate) {
      emailForm.setFieldsValue({ scheduledDate: '' });
    }
  }, [emailForm, scheduledDate, sendNow]);
  const [{ value: documents }, getDocuments] = useAsyncFn(
    async (entityId, entityType) => {
      const attachments = await filesService.getDocuments({
        entityId,
        entityType,
      });
      emailForm.setFieldsValue({ attachments });
      return attachments;
    },
    [entityId, entityType, emailForm],
  );
  const [{ loading }, sendEmail] = useAsyncFn(
    async ({ attachments, ...values }) => {
      attachments = attachments.map((doc) => ({ ...doc, entityType: 'Attachment' }));
      values.toMail = values?.toMail?.map((item) => item.email).join(',');
      try {
        if (showSchedule && !sendNow) {
          const scheduledDate = dayjs(values.scheduledDate).format('YYYY-MM-DD');
          const { body } = values;
          const payload = {
            ...values,
            attachments,
          };
          if (record?.id) {
            payload.scheduledDate = scheduledDate;
            payload.content = body;
            await communicationsService.updateScheduleEmail(record?.id, payload);
            onCancel();
          } else {
            payload.scheduleDates = [scheduledDate];
            await communicationsService.scheduleEmail(entityType, entityId, payload);
          }
          message.success('Email has been scheduled.');
        } else {
          await communicationsService.sendEmail(entityType, entityId, { ...values, attachments });
          message.success('Email will be sent in few seconds.');
        }
        if (!record?.id) setVisible(false);
        if (reload && typeof reload === 'function') {
          reload();
        }
      } catch (error) {
        newrelic.noticeError(error);
        message.error(showSchedule ? 'Unable to schedule email.' : 'Unable to send email.', 5);
      }
    },
    [entityType, entityId, showSchedule, record, documents, sendNow],
  );
  useEffect(() => {
    if (toMail) {
      emailForm.setFieldsValue({ toMail: [{ email: toMail }] });
    }
  }, [toMail, emailForm]);
  useEffect(() => {
    if (record?.id) {
      emailForm.setFieldsValue({ ...record, body: record.content });
    }
  }, [record, emailForm]);

  useEffect(() => {
    if (record?.id) {
      getDocuments(record.id, 'Attachment');
    }
  }, [getDocuments, record]);

  return (
    <div className={classNames(styles.sendEmailModal, className)}>
      <div className="">
        {dataLoading ? (
          <Skeleton
            loading={dataLoading}
            active={dataLoading}
            avatar
            className="flex py-1 px-3 w-full"
            paragraph={{ rows: 9 }}
          />
        ) : (
          <Form
            layout="vertical"
            id="new-email-form"
            form={emailForm}
            initialValues={record || { toMail: [{ email: '' }] }}
            onFinish={sendEmail}
          >
            <div className="label mr-3 mb-3 md:mb-0">
              <span className="text-md font-semibold uppercase">Sent To:</span>
            </div>

            <div className="flex flex-col sm:flex-row md:items-baseline width-full md:w-3/4 xl:1/2">
              <div className="emails flex-1" data-testid="recipients-container">
                <Form.List name={record?.id ? 'recipients' : 'toMail'} label="Sent to" className="m-0">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                        <div key={key} className="flex md:items-baseline">
                          <Form.Item
                            {...restField}
                            name={[name, record?.id ? 'name' : 'email']}
                            fieldKey={[fieldKey, 'email']}
                            rules={[
                              { required: true, message: 'Email is required.' },
                              { type: 'email', message: 'Email is invalid' },
                            ]}
                            className={classNames(restField.className, 'flex-1 md:w-3/4 items-baseline m-0')}
                          >
                            <Input placeholder="Email" />
                          </Form.Item>

                          {index > 0 && (
                            <Button
                              className="icon-btn ml-2 top-1 rounded-full"
                              onClick={() => remove(name)}
                              icon={<Subtract />}
                              data-testid="remove-recipient"
                            />
                          )}
                          {index === 0 && (
                            <Button
                              className="icon-btn ml-2 top-2 rounded-full"
                              onClick={() => add()}
                              icon={<Add size={24} />}
                              data-testid="add-recipient"
                            />
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </Form.List>
              </div>
            </div>

            <Divider />

            {showSchedule && !record?.id && (
              <div className="w-full">
                <Form.Item name="sendNow" valuePropName="checked" className="mb-2">
                  <SwitchField>Send Now</SwitchField>
                </Form.Item>
              </div>
            )}
            <div
              className={classNames({
                'flex flex-col md:flex-row md:space-x-3': showSchedule,
              })}
            >
              <Form.Item
                name={record?.id ? 'subject' : ['subjects', 0]}
                label="Subject"
                rules={[{ required: true, message: 'Subject is required.' }]}
                className="w-3/5"
              >
                <Input name="Subject" placeholder="Enter Email Subject" />
              </Form.Item>
              {showSchedule && !sendNow && (
                <Form.Item
                  name="scheduledDate"
                  label="Send Date"
                  rules={[
                    { required: true, message: 'Send Date is required.' },
                    getDisabledDateValidator((date) => {
                      if (!date) return;
                      const startDate = dayjs();
                      return startDate.startOf('day').isSameOrAfter(dayjs(date).startOf('day'));
                    }, 'Send Date should be in future.'),
                  ]}
                  className="w-2/5"
                >
                  <AriaDatePicker placeholder="Send date" disabled={sendNow} data-testid="scheduledDate" />
                </Form.Item>
              )}
            </div>

            <Spin spinning={isOwnerDataLoading}>
              <Form.Item name="body" label="Email Message" rules={[{ required: true, message: 'Message is required' }]}>
                <Editor compact={false} height={200} templateData={owner} templates={templates} />
              </Form.Item>
            </Spin>

            <Form.Item label="Attach any supporting documents" className="w-full">
              <DragDropUpload
                name="attachments"
                entityType="Attachment"
                abbr="OTHER"
                keyPrefix="forms/email/attachments"
              />
            </Form.Item>

            <Divider />

            <Space>
              <Spacer />

              <Button data-testid="cancel-button" onClick={() => (record?.id ? onCancel() : setVisible(false))}>
                Cancel
              </Button>

              <Button data-testid="submit-button" type="primary" loading={loading} disabled={loading} htmlType="submit">
                {showSchedule && !sendNow ? 'Schedule' : 'Send'}
              </Button>
            </Space>
          </Form>
        )}
      </div>
    </div>
  );
}
