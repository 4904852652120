import React, { useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import { commonService } from 'services';
import Space, { Spacer } from 'components/Space/Space';
import { Button, Checkbox, Divider, Dropdown, Input, Radio, Switch } from 'antd';
import { Checkmark, ChevronDown, Close, Search } from '@carbon/icons-react';
import { ROLES } from 'constants';
import { getFullName } from 'utils';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/auth';
import { startCase } from 'lodash-es';
import { UNASSIGNED_ID } from './AssignedToCell';
import { useUrlParams } from 'hooks/useUrlParams';

export default function ProviderDashboardFiltersForm({ isFamilyDashboard, fetchData, filters, setFilters }) {
  const { get: getUrlParams, update: updateUrlParams } = useUrlParams();

  const currentUser = useSelector(selectUser);
  const userFullName = [startCase(currentUser?.firstName), startCase(currentUser?.lastName)].join(' ');

  const { result: statusOptions = [] } = useAsync(
    async (isFamily) => {
      return commonService.get(
        isFamily ? '/families/application-status-types/internal' : '/providers/application-status-types/internal',
        { cache: { interpretHeader: false } },
      );
    },
    [isFamilyDashboard],
  );

  const { result: programOptions = [] } = useAsync(async () => {
    return await commonService.getAllProgramTypes();
  }, []);

  const { result: assigneeOptions = [] } = useAsync(async () => {
    return commonService.get(
      `/users/all?role=${isFamilyDashboard ? ROLES.FAMILY_COORDINATOR : ROLES.PROVIDER_COORDINATOR}`,
    );
  }, []);

  const _thereAreFiltersApplied = useMemo(
    () =>
      filters?.statuses?.length ||
      filters?.programIds?.length ||
      filters?.assigned === false ||
      filters?.assigneeId ||
      (filters?.includeTests && !isFamilyDashboard),
    [filters, isFamilyDashboard],
  );

  const [programIdsDropdownOpen, setProgramIdsDropdownOpen] = React.useState(false);
  const [temporaryProgramIds, setTemporaryProgramIds] = React.useState(filters?.programIds);
  const _applicationTypeFilterApplied = !!filters?.programIds?.length;

  const [statusDropdownOpen, setStatusDropdownOpen] = React.useState(false);
  const [temporaryStatuses, setTemporaryStatuses] = React.useState(filters?.statuses);
  const _statusFilterApplied = !!filters?.statuses?.length;

  const [assignedToDropdownOpen, setAssignedToDropdownOpen] = React.useState(false);
  const [temporaryAssignedTo, setTemporaryAssignedTo] = React.useState(filters?.assigneeId);
  const _assignedToFilterApplied = filters?.assigneeId !== undefined || filters?.assigned === false;
  const [assignedToSearchQuery, setAssignedToSearchQuery] = React.useState('');

  return (
    <Space className="m-7 flex-wrap">
      {/*
       * APPLICATION TYPE filter
       */}
      <Dropdown
        trigger={['click']}
        open={programIdsDropdownOpen}
        onOpenChange={(open) => {
          setProgramIdsDropdownOpen(open);
          setTemporaryProgramIds(filters?.programIds);
        }}
        dropdownRender={() => (
          <div
            className="bg-white rounded-lg"
            style={{
              boxShadow: `0 6px 16px 0 rgba(0, 0, 0, 0.08),
            0 3px 6px -4px rgba(0, 0, 0, 0.12),
            0 9px 28px 8px rgba(0, 0, 0, 0.05)`,
            }}
          >
            <div className="p-5">
              <Checkbox.Group
                onChange={(_newValue) => {
                  setTemporaryProgramIds(_newValue);
                }}
                value={temporaryProgramIds}
              >
                <Space direction="vertical" vCentered={false}>
                  {programOptions
                    ?.map((_option) => ({
                      text: _option.title,
                      value: _option.id,
                    }))
                    .map((option) => (
                      <Checkbox key={option.value} value={option.value}>
                        {option.text}
                      </Checkbox>
                    ))}
                </Space>
              </Checkbox.Group>
            </div>

            <Divider className="m-0" />

            <div className="py-4 px-5 flex">
              <Spacer />

              <Button
                size="small"
                type="primary"
                disabled={temporaryProgramIds === filters?.programIds}
                onClick={() => {
                  setProgramIdsDropdownOpen(false);

                  const _newProgramIdsValue = temporaryProgramIds?.length ? temporaryProgramIds : undefined;
                  setFilters({
                    ...filters,
                    programIds: _newProgramIdsValue,
                  });

                  // Update URL params
                  updateUrlParams({
                    filters: {
                      ...getUrlParams()?.filters,
                      includeTests: isFamilyDashboard ? undefined : filters?.includeTests,
                      programIds: _newProgramIdsValue?.join(','),
                    },
                  });

                  // Fetch new data
                  fetchData({
                    programIds: _newProgramIdsValue,
                  });
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      >
        <Button
          color={_applicationTypeFilterApplied ? 'primary' : 'default'}
          variant="filled"
          data-testid="filters__programIds"
        >
          <div className="flex gap-2 items-center">
            Application type
            {_applicationTypeFilterApplied && (
              <span className="w-7 rounded-full bg-primary text-white">{filters?.programIds?.length}</span>
            )}
            <ChevronDown className="mr-[-3px] mb-[-2px]" />
          </div>
        </Button>
      </Dropdown>

      {/*
       * STATUS filter
       */}
      <Dropdown
        trigger={['click']}
        open={statusDropdownOpen}
        onOpenChange={(open) => {
          setStatusDropdownOpen(open);
          setTemporaryStatuses(filters?.statuses);
        }}
        dropdownRender={() => (
          <div
            className="bg-white rounded-lg"
            style={{
              boxShadow: `0 6px 16px 0 rgba(0, 0, 0, 0.08),
            0 3px 6px -4px rgba(0, 0, 0, 0.12),
            0 9px 28px 8px rgba(0, 0, 0, 0.05)`,
            }}
          >
            <div className="p-5">
              <Checkbox.Group
                onChange={(_newValue) => {
                  setTemporaryStatuses(_newValue);
                }}
                value={temporaryStatuses}
              >
                <Space direction="vertical" vCentered={false}>
                  {statusOptions?.map((_option) => (
                    <Checkbox key={_option.title} value={_option.title}>
                      {_option.description}
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </div>

            <Divider className="m-0" />

            <div className="py-4 px-5 flex">
              <Spacer />

              <Button
                size="small"
                type="primary"
                disabled={temporaryStatuses === filters?.statuses}
                onClick={() => {
                  setStatusDropdownOpen(false);

                  setFilters({
                    ...filters,
                    statuses: temporaryStatuses,
                  });

                  // Update URL params
                  updateUrlParams({
                    filters: {
                      ...getUrlParams()?.filters,
                      includeTests: isFamilyDashboard ? undefined : filters?.includeTests,
                      statuses: temporaryStatuses?.join(','),
                    },
                  });

                  // Fetch new data
                  fetchData({
                    statuses: temporaryStatuses,
                  });
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      >
        <Button color={_statusFilterApplied ? 'primary' : 'default'} variant="filled">
          <div className="flex gap-2 items-center">
            Status
            {_statusFilterApplied && (
              <span className="w-7 rounded-full bg-primary text-white">{filters?.statuses?.length}</span>
            )}
            <ChevronDown className="mr-[-3px] mb-[-2px]" />
          </div>
        </Button>
      </Dropdown>

      {/*
       * ASSIGNEE filter
       */}
      <Dropdown
        trigger={['click']}
        open={assignedToDropdownOpen}
        onOpenChange={(open) => {
          setAssignedToDropdownOpen(open);
          setTemporaryAssignedTo(filters?.assigneeId);
          setAssignedToSearchQuery('');
        }}
        dropdownRender={() => (
          <div
            className="bg-white rounded-lg"
            style={{
              boxShadow: `0 6px 16px 0 rgba(0, 0, 0, 0.08),
            0 3px 6px -4px rgba(0, 0, 0, 0.12),
            0 9px 28px 8px rgba(0, 0, 0, 0.05)`,
            }}
          >
            <div className="p-2 border-b border-solid border-[#f0f0f0]">
              {/* Search Input */}
              <Input
                placeholder="Search"
                value={assignedToSearchQuery}
                onChange={(e) => setAssignedToSearchQuery(e.target.value)}
                prefix={<Search />}
                // ref={searchInputRef}
              />
            </div>

            <div className="p-5 max-h-[300px] overflow-y-scroll">
              <Radio.Group
                onChange={(e) => {
                  setTemporaryAssignedTo(e?.target?.value);
                }}
                value={
                  filters?.assigned === false && filters?.assigneeId === undefined ? UNASSIGNED_ID : temporaryAssignedTo
                }
                options={[
                  { text: 'Unassigned', value: UNASSIGNED_ID, id: UNASSIGNED_ID },
                  { text: userFullName, value: currentUser?.id, id: currentUser?.id },
                  ...(assigneeOptions || [])
                    .filter((assignee) => assignee.id !== currentUser?.id)
                    .map((assignee) => ({ text: getFullName(assignee), value: assignee.id }))
                    .sort((a, b) => a?.text?.localeCompare?.(b?.text)),
                ]
                  ?.filter((filter) => filter.text.toLowerCase().includes(assignedToSearchQuery.toLowerCase()))
                  ?.map((_option) => ({
                    label: _option.text,
                    value: _option.value,
                  }))}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                }}
              />
            </div>

            <Divider className="m-0" />

            <div className="py-4 px-5 flex gap-2">
              <Spacer />

              <Button
                size="small"
                disabled={!_assignedToFilterApplied}
                onClick={() => {
                  setAssignedToDropdownOpen(false);
                  setFilters({
                    ...filters,
                    assigned: undefined,
                    assigneeId: undefined,
                  });

                  // Update URL params
                  updateUrlParams({
                    filters: {
                      ...getUrlParams()?.filters,
                      includeTests: isFamilyDashboard ? undefined : filters?.includeTests,
                      assigned: undefined,
                      assigneeId: undefined,
                    },
                  });

                  // Fetch new data
                  fetchData({
                    assigned: undefined,
                    assigneeId: undefined,
                  });
                }}
                ghost
                danger
              >
                Clear
              </Button>

              <Button
                size="small"
                type="primary"
                disabled={temporaryAssignedTo === filters?.assigneeId}
                onClick={() => {
                  setAssignedToDropdownOpen(false);

                  const _newFilterValue =
                    temporaryAssignedTo === UNASSIGNED_ID
                      ? { assigned: false, assigneeId: undefined }
                      : {
                          assigned: undefined,
                          assigneeId: temporaryAssignedTo,
                        };

                  setFilters({
                    ...filters,
                    ..._newFilterValue,
                  });

                  // Update URL params
                  updateUrlParams({
                    filters: {
                      ...getUrlParams()?.filters,
                      includeTests: isFamilyDashboard ? undefined : filters?.includeTests,
                      ..._newFilterValue,
                    },
                  });

                  // Fetch new data
                  fetchData(_newFilterValue);
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      >
        <Button color={_assignedToFilterApplied ? 'primary' : 'default'} variant="filled">
          <div className="flex gap-2 items-center">
            Assigned to
            {_assignedToFilterApplied && <span className="w-7 rounded-full bg-primary text-white">1</span>}
            <ChevronDown className="mr-[-3px] mb-[-2px]" />
          </div>
        </Button>
      </Dropdown>

      {/*
       * INCLUDE TESTS filter - only for PROVIDER view
       */}
      {!isFamilyDashboard && (
        <Button
          color={filters?.includeTests ? 'primary' : 'default'}
          variant="filled"
          onClick={() => {
            setFilters({
              ...filters,
              includeTests: !!!filters?.includeTests,
            });

            updateUrlParams({
              filters: {
                ...getUrlParams()?.filters,
                includeTests: !!!filters?.includeTests || undefined,
              },
            });
            fetchData({
              includeTests: !!!filters?.includeTests,
            });
          }}
        >
          <div className="flex gap-2 items-center">
            Test accounts
            <Switch
              size="small"
              checked={filters?.includeTests}
              checkedChildren={<Checkmark />}
              unCheckedChildren={<Close className="mt-[-3px]" />}
              className="pointer-events-none"
            />
          </div>
        </Button>
      )}

      {_thereAreFiltersApplied && (
        <Button
          type="primary"
          ghost
          onClick={() => {
            const _newFilters = {
              assigned: undefined,
              assigneeId: undefined,
              includeTests: undefined,
              programIds: undefined,
              statuses: undefined,
            };

            setFilters(_newFilters);

            // Clear URL params
            updateUrlParams({
              filters: null,
            });

            // Fetch new data
            fetchData(_newFilters);
          }}
        >
          Clear filters
        </Button>
      )}
    </Space>
  );
}
