import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BiTrash } from 'react-icons/bi';
import { AiOutlineEdit } from 'react-icons/ai';
import { useAsyncCallback } from 'react-async-hook';
import { useSelector } from 'react-redux';
import { Button, message, Tooltip, Modal as AntdModal, Spin } from 'antd';

import Form from 'components/Form';
import Table from 'components/Table';
import Modal from 'components/Modal';
import useStateCallback from './useStateCallback';
import { formatDates } from 'utils';
import { DATE_FORMAT, ROLES } from 'constants/index';
import { selectActiveRole } from 'features/auth';
import { selectors } from 'features/add-center';
import filesService from 'services/files.service';
import { Add } from '@carbon/icons-react';

export default function useEntityGroup({
  items = [],
  onChange,
  entity,
  getAllColumns,
  getFormFields,
  tableProps,
  entityTitle,
  disableAddButton,
  dateFields = [['startDate'], ['endDate']],
  dateFormat = DATE_FORMAT[2],
  initialValues,
  entityName,
  modalWidth,
  hideDeleteButton = false,
  getRowActions = () => null,
  onValuesChange = () => {},
  getFormInitialValues = (v) => v,
  onAddItem = async (data) => data,
  onDeleteItem = async (data) => data,
  disableDelete,
  deleteTooltipText,
  readOnly = false,
  documentTypeAbbr = 'OTHER',
  entityType,
  subEntityType,
  actionsColumnProps = {},
  onMount,
  rowClassName,
  hideTable = false,
  customAddButton,
  rules,
}) {
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useStateCallback({});
  const [isEditing, setIsEditing] = useState(false);

  const [ruleFormItemName] = useState(`${Math.random()}__section__rule`);

  const activeRole = useSelector(selectActiveRole);
  const appStatus = useSelector(selectors.selectInternalStatus);
  const {
    result: subEntityDocuments,
    execute: getDocuments,
    loading: documentsLoading,
  } = useAsyncCallback(async (entityType, entityId, subEntityType) => {
    let documents = [];
    if (entityType && entityId) {
      documents = await filesService.getDocuments({ entityType, entityId, subEntityType });
    }
    return documents;
  }, []);

  useEffect(() => {
    onMount?.(setVisible, setItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDocuments(entityType, entity?.id, subEntityType);
  }, [entity?.id, entityType, getDocuments, subEntityType]);

  const { execute: addItem, loading: addUpdateLoading } = useAsyncCallback(
    async (values) => {
      /* istanbul ignore else */
      if (dateFields) {
        values = formatDates(values, dateFields, dateFormat);
      }
      values = await onAddItem(values);
      let updatedItems = [...items];

      // const index = updatedItems.findIndex((v) => v.id === values.uid || v.uid === values.uid);
      const index = updatedItems.findIndex(
        (v) =>
          (v.id && values.uid && v.id === values.uid) ||
          (values.uid && v.uid === values.uid) ||
          (values.id && v.id === values.id),
      );
      if (index > -1) {
        updatedItems.splice(index, 1, values);
      } else {
        updatedItems.push(values);
      }

      onChange(updatedItems);

      setTimeout(() => {
        // setKey(Date.now());
        setItem({});
      }, 200);
    },
    [
      dateFields,
      dateFormat,
      items,
      onAddItem,
      onChange,
      setItem,
      subEntityDocuments,
      documentTypeAbbr,
      getDocuments,
      entityType,
      entity?.id,
      subEntityType,
    ],
  );
  const deleteItem = useCallback(
    async (id) => {
      const updatedItems = [...items];
      const index = items.findIndex((v) => v.id === id || v.uid === id);
      /* istanbul ignore else */
      if (index > -1) {
        updatedItems.splice(index, 1);
      }

      onChange(updatedItems);
    },
    [items, onChange],
  );
  const onDeleteClick = useCallback(
    (record) => {
      AntdModal.confirm({
        title: 'Are you sure you want to delete this?',
        icon: null,
        async onOk() {
          await onDeleteItem(record);
          deleteItem(record.id);
        },
        onCancel() {},
      });
    },
    [deleteItem, onDeleteItem],
  );

  const allColumns = useMemo(() => {
    const columns = getAllColumns({
      addItem,
      deleteItem,
      setItem,
      item,
      items,
      onChange,
      visible,
      setVisible,
      onDeleteClick,
      documents: subEntityDocuments,
    });

    /* istanbul ignore else */
    if (!readOnly && !columns.find((col) => col.key === 'actions')) {
      columns.push({
        title: <span className="action-header">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        align: 'center',
        fixed: 'right',
        // dataIndex: 'actions',
        width: 100,
        // eslint-disable-next-line react/display-name
        render: (_, record, index) => {
          return (
            <div className="flex space-x-2">
              {!readOnly && (
                <Tooltip placement="top" title="Edit">
                  <Button
                    onClick={() => {
                      setItem({ ...record, index });
                      setIsEditing(true);
                      setVisible(true);
                    }}
                    icon={<AiOutlineEdit />}
                    aria-label="Edit"
                    style={{ borderRadius: '2px' }}
                  />
                </Tooltip>
              )}

              {!readOnly && !hideDeleteButton && (
                <Tooltip
                  placement="top"
                  title={(typeof deleteTooltipText === 'function' && deleteTooltipText(record)) || 'Remove'}
                >
                  <Button
                    className="icon-btn alert remove-button"
                    icon={<BiTrash />}
                    aria-label="Remove"
                    onClick={() => onDeleteClick(record)}
                    disabled={
                      (typeof disableDelete === 'function' && disableDelete(record)) ||
                      (activeRole === ROLES.PROVIDER &&
                        appStatus?.order > 2 &&
                        Number.isInteger(record.id) &&
                        record.id > 0)
                    }
                    style={{ borderRadius: '2px' }}
                  />
                </Tooltip>
              )}
              {getRowActions(record)}
            </div>
          );
        },
        ...actionsColumnProps,
      });
    }

    return columns;
  }, [
    getAllColumns,
    addItem,
    deleteItem,
    setItem,
    item,
    items,
    onChange,
    visible,
    onDeleteClick,
    subEntityDocuments,
    actionsColumnProps,
    readOnly,
    hideDeleteButton,
    deleteTooltipText,
    disableDelete,
    activeRole,
    appStatus?.order,
    getRowActions,
  ]);

  const section = useMemo(
    () => (
      <div>
        {(!!customAddButton || !readOnly) && (
          <div className="flex items-center pb-6">
            {customAddButton
              ? customAddButton
              : !readOnly && (
                  <Button
                    type="primary"
                    disabled={disableAddButton}
                    className="flex items-center"
                    icon={<Add size={22} className="mt-[-2px] mr-[-4px] ml-[-4px]" />}
                    onClick={() => {
                      setItem({ ...initialValues, index: items?.length || 0 });
                      setVisible(true);
                    }}
                    data-testid={`add-${entityName}-btn`}
                    style={{ borderRadius: '2px' }}
                  >
                    Add {entityTitle}
                  </Button>
                )}

            <div className="flex-grow" />
          </div>
        )}

        {!hideTable && items.length !== 0 && (
          <Table
            id={`${entityName}-table`}
            data-testid={`${entityName}-table`}
            className="separated"
            // key={key}
            data={items}
            pagination={{ pageSize: 5, hideOnSinglePage: true }}
            allColumns={allColumns}
            showColSeparator={false}
            showRowSeparator
            rowClassName={rowClassName}
            rowKey="id"
            scroll={{ x: 1200 }}
            {...tableProps}
            loading={tableProps.loading || documentsLoading}
            style={{ borderRadius: '2px' }}
          />
        )}
        {!readOnly && (
          <AddEntityModal
            key={JSON.stringify(item)}
            visible={visible}
            setVisible={(status) => {
              setVisible(status);
              setItem({});

              if (!status) {
                setIsEditing(false);
              }
            }}
            addItem={addItem}
            modalWidth={modalWidth}
            item={item}
            items={items}
            entity={entity}
            getFormFields={getFormFields}
            entityName={entityName}
            onValuesChange={onValuesChange}
            getFormInitialValues={getFormInitialValues}
            addUpdateLoading={addUpdateLoading}
            index={item.index}
            documents={subEntityDocuments}
            documentsLoading={documentsLoading}
            documentTypeAbbr={documentTypeAbbr}
            title={`${isEditing ? 'Edit' : 'Add'} ${entityTitle}`}
          />
        )}

        {rules && <Form.Item name={ruleFormItemName} rules={rules} className="m-0"></Form.Item>}
      </div>
    ),
    [
      customAddButton,
      readOnly,
      disableAddButton,
      entityName,
      entityTitle,
      hideTable,
      items,
      allColumns,
      rowClassName,
      tableProps,
      documentsLoading,
      item,
      visible,
      addItem,
      modalWidth,
      entity,
      getFormFields,
      onValuesChange,
      getFormInitialValues,
      addUpdateLoading,
      subEntityDocuments,
      documentTypeAbbr,
      isEditing,
      rules,
      ruleFormItemName,
      setItem,
      initialValues,
    ],
  );

  return {
    section,
    visible,
    setVisible,
    allColumns,
    addItem,
    deleteItem,
    setItem,
  };
}

const AddEntityModal = ({
  title,
  visible,
  setVisible,
  addItem,
  item,
  items,
  entity,
  getFormFields,
  addUpdateLoading,
  entityName,
  modalWidth,
  index,
  documents,
  documentsLoading,
  documentTypeAbbr,
  onValuesChange = () => {},
  getFormInitialValues = (v) => v,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (Array.isArray(documents)) {
      form.setFieldsValue({
        documents: {
          [documentTypeAbbr]: documents.filter((doc) => doc.subEntityId === item?.id),
        },
      });
    }
  }, [documentTypeAbbr, documents, form, item?.id]);
  return (
    <Modal
      width={modalWidth || 900}
      visible={visible}
      setVisible={setVisible}
      id={`${entityName}-modal`}
      data-testid={`${entityName}-modal`}
      maskClosable={false}
      destroyOnClose
      title={title}
    >
      <Spin spinning={documentsLoading}>
        <Form
          preserve
          key={JSON.stringify(item)}
          form={form}
          layout="vertical"
          id={`${entityName}-form`}
          onFinish={async (values) => {
            try {
              if (entity?.id !== undefined) {
                values.careFacility = {
                  id: entity?.id,
                };
              }

              const data = {
                ...values,
                ...(item?.id ? { id: item.id } : {}),
                uid: item?.id || item?.uid || Date.now(),
              };
              await addItem(data);
              setVisible(false);
              form.resetFields();
            } catch (error) {
              newrelic.noticeError(error);
              message.error('Unable to add Item.');
            }
          }}
          onValuesChange={onValuesChange(form)}
          initialValues={getFormInitialValues(item, index)}
        >
          {(values) => {
            return getFormFields({
              values,
              visible,
              setVisible,
              addItem,
              item,
              entity,
              items,
              form,
              addUpdateLoading,
            });
          }}
        </Form>
      </Spin>
    </Modal>
  );
};

AddEntityModal.propTypes = {
  entity: PropTypes.object,
  item: PropTypes.shape({
    id: PropTypes.any,
    uid: PropTypes.any,
  }),
  items: PropTypes.array,
  onSubmit: PropTypes.func,
  setVisible: PropTypes.func,
  getFormInitialValues: PropTypes.func,
  visible: PropTypes.bool,
};

export const GetEntityListData = ({ name, getData, children, className, rules = [], noStyle = false }) => {
  const form = Form.useFormInstance();
  const fetchData = useCallback(
    async (params) => {
      try {
        if (form) {
          const response = await getData(params);
          form.setFieldsValue({ [name]: response });
        }
      } catch (error) {
        newrelic.noticeError(error);
      }
    },
    [form, getData, name],
  );
  useEffect(() => {
    fetchData({});
  }, [fetchData]);
  return (
    <Form.Item name={name} className={className} rules={rules} noStyle={noStyle}>
      {React.cloneElement(children, { fetchData })}
    </Form.Item>
  );
};
