import React, { useMemo, useState, useEffect } from 'react';
import { Button, message, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useAsyncCallback } from 'react-async-hook';
import classNames from 'classnames';

import Form from 'components/Form';
import Modal from 'components/Modal';
import Actions from '../../Actions';
import Section from 'components/Section';
import ChildInfoTab from './ChildInfoTab';
import { AsyncSelect } from 'components/Select';
import { ADD_FAMILY_STEPS } from 'constants/index';
import familyService from 'services/family.service';
import { getFullName } from 'utils';
import { selectors } from 'features/family';
import { BiPlus } from 'react-icons/bi';
import ChildCreation from './ChildCreation';
import { Add } from '@carbon/icons-react';

const CREATE_NEW_ID = '__cc__app__entity__create-new__';

export default function ChildrenInfo({
  id,
  application,
  next,
  setStep,
  actions,
  dispatch,
  tab = '0',
  openSteps,
  onCancel,
  household,
  ...rest
}) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const isLoading = useSelector(selectors.selectLoading);

  const sponsorId = application?.applicant?.id;

  // const [familyRelationData, setFamilyRelationData] = useState({});
  const [newChildIds, setNewChildIds] = useState([]);
  // Stores parent info based on the __id__ of the parent
  const [newChildrenForm] = Form.useForm();

  const commonProps = useMemo(
    () => ({
      id,
      application,
      next,
      setStep,
      actions,
      dispatch,
      tab,
      openSteps,
      parentForm: form,
      household,
      ...rest,
    }),
    [id, application, next, setStep, actions, dispatch, tab, openSteps, form, household, rest],
  );

  useEffect(() => {
    /*
     * Get family relation data for all the new parents
     * And populate the antd Form with the data
     */
    const getFamilyRelationData = async (_newChildIds) => {
      const _familyRelationData = {};
      _newChildIds.forEach(async (_childId) => {
        const _relationshipData = await familyService.getFamilyRelation(household?.id, _childId);
        const _relationshipToSponsor = _relationshipData.find(
          (_relationshipData) => _relationshipData?.relatedPerson?.id === sponsorId,
        );

        if (_relationshipToSponsor) {
          _familyRelationData[_childId] = _relationshipToSponsor;

          newChildrenForm.setFieldValue([_childId, 'relationshipType'], _relationshipToSponsor?.relationshipType);
        }
      });

      // setFamilyRelationData(_familyRelationData);
    };

    if (newChildIds.length === 0 && Array.isArray(application?.children) && application?.children?.length !== 0) {
      const _newChildIds = application?.children?.map((_child) => _child.id);
      setNewChildIds(_newChildIds);

      getFamilyRelationData(_newChildIds);
    }
  }, [
    application?.children,
    application?.applicant?.id,
    application?.applicant?.singleParent,
    household?.id,
    newChildrenForm,
    newChildIds,
    sponsorId,
  ]);

  const { execute: saveChildren } = useAsyncCallback(
    async (values) => {
      try {
        newChildIds.forEach(async (_childId) => {
          const _payload = values[_childId];
          if (_payload) {
            if (_isNewUnregisteredChild(_childId)) {
              const _registeredChild = await familyService.addUpdateFamilyChild(household?.id, null, [_payload]);
              const _registeredChildId = _registeredChild?.[0]?.id;

              await familyService.saveChild(id, { id: _registeredChildId });
              await familyService.addUpdateFamilyRelationship(household?.id, _registeredChildId, {
                // "typeOfMember": {
                //     "title": "Adult",
                //     "id": "adults"
                // },
                relatedPerson: {
                  id: sponsorId,
                },
                relationshipType: _payload.relationshipType,
                person: {
                  id: _registeredChildId,
                },
              });
            } else {
              await familyService.saveChild(id, { id: _childId });

              await familyService.addUpdateFamilyRelationship(household?.id, _childId, {
                relatedPerson: {
                  id: sponsorId,
                },
                relationshipType: _payload.relationshipType,
                person: {
                  id: _childId,
                },
              });
            }
          }
        });

        await new Promise((resolve) => setTimeout(resolve, 300));

        const newApplication = await familyService.getApplication(id);
        dispatch(actions.setApplication(newApplication));

        form.resetFields();
        newChildrenForm.resetFields();

        next({
          paths: {
            step: ADD_FAMILY_STEPS.CHILDREN,
            id: application.id,
          },
        });
      } catch (e) {
        message.error({
          content: 'Unable to add parent(s) to application.',
          duration: 5,
        });
      }
    },
    [actions, familyService, id, newChildIds],
  );

  const { execute: addChild } = useAsyncCallback(
    async (values) => {
      setNewChildIds((ids) => [...ids, values.child?.id]);

      setVisible(false);
    },
    [application, dispatch, actions, id],
  );

  return (
    <div>
      <Spin spinning={isLoading}>
        <div className={classNames('white-box', { 'mb-6': newChildIds.length !== 0 })}>
          <Form form={form} layout="vertical" className="border-0">
            <Section heading="CHILDREN INFORMATION" headingClassName="page-title" collapsible={false}>
              <div className="flex flex-col xl:flex-row xl:justify-between mb-5 items-center">
                <Form.Item
                  name="children"
                  rules={[
                    {
                      validator: async () => {
                        if (application?.children?.length < 1) {
                          throw new Error('Please add at least one child.');
                        }
                      },
                    },
                  ]}
                  className="m-0 p-0"
                >
                  <p>Please add all children attending child care within the program's age limits.</p>
                </Form.Item>

                <Button
                  type="primary"
                  onClick={() => {
                    // dispatch(actions.addChild());
                    setVisible(true);
                  }}
                  icon={<Add className="w-7 h-7 ml-[-6px] mb-[-2px]" />}
                >
                  Add Child
                </Button>
              </div>
            </Section>
          </Form>
        </div>

        {newChildIds.length !== 0 && (
          <Form layout="vertical" form={newChildrenForm} onFinish={saveChildren}>
            {newChildIds.map((_id, i) => (
              <div key={_id}>
                {!_isNewUnregisteredChild(_id) && (
                  <ChildInfoTab
                    {...{
                      ...rest,
                      id,
                      application,
                      next,
                      setStep,
                      actions,
                      dispatch,
                      tab,
                      openSteps,
                      index: i,
                      childIds: newChildIds,
                      childId: _id,
                      parentForm: form,
                      household,
                    }}
                  />
                )}

                {_isNewUnregisteredChild(_id) && (
                  <ChildCreation {...commonProps} childIds={newChildIds} childId={_id} index={i} />
                )}
              </div>
            ))}
          </Form>
        )}
      </Spin>

      <Modal width={750} visible={visible} setVisible={setVisible} destroyOnClose>
        <Form layout="vertical" onFinish={addChild}>
          <h3 className="block text-md font-semibold uppercase text-primary my-2">Application Child Selection</h3>
          <p className="instructions mb-5 ml-2">Select the Child that you wish to add to this Application.</p>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-x-8 mb-6">
            <Form.Item
              name={['child']}
              label="Select Child"
              rules={[{ required: true, message: 'Child is required.' }]}
            >
              <AsyncSelect
                name="child"
                placeholder="Select Child"
                ariaLabel="Select Child"
                apiPrefix="families"
                optionsApiUrl={`/households/${household?.id}/children`}
                optionFormatter={(opt) => {
                  const _uniqueId = `${CREATE_NEW_ID}${_generateRandomId()}`;

                  return [
                    {
                      icon: <BiPlus className="w-4 h-4 align-middle" />,
                      label: 'Create New',
                      id: _uniqueId,
                      value: _uniqueId,
                    },
                  ].concat(opt.filter((_opt) => !newChildIds.includes(_opt.id)));
                }}
                getOptionLabel={(opt) => {
                  return _isNewUnregisteredChild(opt?.id) ? opt.label : getFullName(opt);
                }}
                getOptionValue={(opt) => opt.id}
                isOptionDisabled={(opt) => application?.children?.some((item) => item.id === opt.id)}
              />
            </Form.Item>
          </div>

          <div className="actions flex">
            <Button onClick={() => setVisible(false)}>Cancel</Button>
            <Button
              data-testid="submit-btn"
              // loading={assigningChild}
              // disabled={assigningChild}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>

      {newChildIds.length !== 0 && (
        <Actions
          onSubmit={async () => {
            try {
              await newChildrenForm.validateFields();
              newChildrenForm.submit();
            } catch (error) {}
          }}
          onBack={() => {
            if ((application.additionalParents || []).length !== 0) {
              setStep(id, ADD_FAMILY_STEPS.SECOND_PARENTS_DOCS);
            } else {
              setStep(id, ADD_FAMILY_STEPS.SECOND_PARENTS);
            }
          }}
          // // onClear={activeRole === ROLES.FAMILY ? null : () => form.resetFields()}
          // onCancel={onCancel}
          // testId={`${ADD_FAMILY_STEPS.SECOND_PARENTS}-${index}-${ADD_FAMILY_TABS.INFO}`}
          style={{ marginTop: '1rem' }}
        />
      )}
    </div>
  );
}

const _isNewUnregisteredChild = (childId) => `${childId || ''}`.includes(CREATE_NEW_ID);
function _generateRandomId() {
  let result = Math.floor(Math.random() * 9 + 1).toString();

  for (let i = 1; i < 10; i++) {
    result += Math.floor(Math.random() * 10).toString();
  }

  return parseInt(result);
}
