import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, getFullName, getProgramsColumn } from 'utils';
import { actions as dashboardActions } from 'features/dashboard';
import { Table, Tooltip } from 'antd';
import { useAsync } from 'react-async-hook';
import { commonService } from 'services';
import { ROLES } from 'constants';
import { selectUser } from 'features/auth';
import { DATE_FORMAT } from 'constants';
import { DashboardTableAssignedToCell } from './AssignedToCell';
import { EMDASH } from 'constants';
import { startCase } from 'lodash-es';
import { Chemistry } from '@carbon/icons-react';

export default function DashboardTable({
  state,
  colKeys,
  isFamilyDashboard,
  rowKey,
  rowClassName,
  loading,
  hideSelection,
  onRow,
  hidePinAction,
  pinApplication,
  mapper = (data) => data,
  className,
  filteredInfo,
  sorterInfo,
  fetchData,
  ...rest
}) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys ?? [],
    onChange: (keys) => {
      dispatch(dashboardActions.setSelectedRowKeys(keys));
    },
  };
  const data = useMemo(() => {
    if (mapper) {
      return (state.data || []).map(mapper);
    }

    return state.data || [];
  }, [mapper, state.data]);

  const { result: statusOptions = [] } = useAsync(
    async (isFamily) => {
      return commonService.get(
        isFamily ? '/families/application-status-types/internal' : '/providers/application-status-types/internal',
        { cache: { interpretHeader: false } },
      );
    },
    [isFamilyDashboard],
  );

  const { result: programOptions = [] } = useAsync(async () => {
    return await commonService.getAllProgramTypes();
  }, []);

  const { result: assigneeOptions = [] } = useAsync(async () => {
    return commonService.get(
      `/users/all?role=${isFamilyDashboard ? ROLES.FAMILY_COORDINATOR : ROLES.PROVIDER_COORDINATOR}`,
    );
  }, []);

  const allColumns = useMemo(
    () =>
      getDashboardColumns({
        assigneeOptions,
        colKeys,
        currentUser,
        fetchData,
        filteredInfo,
        sorterInfo,
        isFamilyDashboard,
        programOptions,
        statusOptions,
      }),
    [
      assigneeOptions,
      colKeys,
      currentUser,
      fetchData,
      filteredInfo,
      sorterInfo,
      isFamilyDashboard,
      programOptions,
      statusOptions,
    ],
  );

  return (
    <Table
      {...rest}
      columns={allColumns}
      rowKey={rowKey}
      dataSource={data}
      loading={state.loading || loading}
      pagination={state.pagination}
      onChange={state.onChange}
      rowClassName={rowClassName}
      rowSelection={hideSelection ? undefined : rowSelection}
      expandable={{
        rowExpandable: () => false,
        showExpandColumn: false,
      }}
      onRow={onRow}
      showColSeparator={false}
      scroll={{ x: 'max-content' }}
      ref={rest.ref}
      locale={{
        filterReset: 'Clear', // Custom text for filter 'Reset' button
        filterConfirm: 'Apply filters', // Custom text for filter 'OK' button
      }}
    />
  );
}

function getDashboardColumns({
  assigneeOptions,
  colKeys,
  currentUser,
  fetchData,
  filteredInfo,
  sorterInfo,
  isFamilyDashboard,
  programOptions,
  statusOptions,
}) {
  const userFullName = [startCase(currentUser?.firstName), startCase(currentUser?.lastName)].join(' ');

  let colMapping = {
    businessLegalName: {
      title: 'Provider Name',
      dataIndex: 'careFacility.displayName',
      key: 'displayName',
      sorter: true,
      width: 200,
      className: 'truncate max-w-xs',
      sortOrder: sorterInfo?.columnKey === 'displayName' && sorterInfo?.order,
      render: (_, record) => record?.careFacility?.displayName,
    },
    providerId: {
      title: 'Application ID',
      dataIndex: 'id',
      key: 'applicationId',
      sorter: true,
      width: 180,
      render: (id, record) =>
        (
          <div className="flex items-center gap-2">
            <Link onClick={(e) => e.stopPropagation()} to={`/providers/applications/${id}`} className="underline">
              {id}
            </Link>

            {record?.careFacility?.organization?.testRecord ? (
              <Tooltip title="Test account">
                <Chemistry className="opacity-70" />
              </Tooltip>
            ) : (
              false
            )}
          </div>
        ) || EMDASH,
      sortOrder: sorterInfo?.columnKey === 'applicationId' && sorterInfo?.order,
    },
    programType: {
      title: isFamilyDashboard ? 'Program' : 'Application Type',
      dataIndex: isFamilyDashboard ? 'program.name' : 'programType.title',
      key: 'programType',
      sorter: !isFamilyDashboard,
      width: 190,
      className: 'truncate max-w-xs',
      render: (_, record) => {
        const programName = isFamilyDashboard ? record?.programType?.title : record?.programType?.title;
        return programName ? <span title={programName}>{programName}</span> : 'N/A';
      },
      sortOrder: isFamilyDashboard ? undefined : sorterInfo?.columnKey === 'programType' && sorterInfo?.order,
    },
    providerStreet: {
      title: 'Street',
      dataIndex: 'addresses.street',
      key: 'providerStreet',
      width: 200,
      sorter: true,
      className: 'truncate max-w-xs',
      render: (_, record) => {
        const value = record?.careFacility?.address?.street;
        return value ? (
          <p className="truncate max-w-xs" title={value}>
            {value}
          </p>
        ) : (
          'N/A'
        );
      },
      sortOrder: sorterInfo?.columnKey === 'providerStreet' && sorterInfo?.order,
    },
    providerCity: {
      title: 'City',
      dataIndex: 'addresses.city',
      key: 'providerCity',
      width: 120,
      sorter: true,
      className: 'truncate max-w-xs',
      render: (_, record) => {
        const value = record?.careFacility?.address?.city;
        return value ? value : 'N/A';
      },
      sortOrder: sorterInfo?.columnKey === 'providerCity' && sorterInfo?.order,
    },
    providerState: {
      title: 'State',
      dataIndex: 'addresses.state',
      key: 'providerState',
      width: 90,
      sorter: true,
      className: 'truncate max-w-xs',
      render: (_, record) => {
        const value = record?.careFacility?.address?.state;
        return value ? value : 'N/A';
      },
      sortOrder: sorterInfo?.columnKey === 'providerState' && sorterInfo?.order,
    },
    providerZip: {
      title: 'Zip',
      dataIndex: 'addresses.postalCode',
      key: 'providerZip',
      width: 90,
      sorter: true,
      className: 'truncate max-w-xs',
      render: (_, record) => {
        const value = record?.careFacility?.address?.postalCode;
        return value ? value : 'N/A';
      },
      sortOrder: sorterInfo?.columnKey === 'providerZip' && sorterInfo?.order,
    },
    caseId: {
      title: 'App ID',
      headerText: 'App ID',
      dataIndex: 'id',
      key: 'caseId',
      sorter: true,
      width: 110,
      render: (id, record) => (
        <Link
          onClick={(e) => e.stopPropagation()}
          className="underline px-3 py-1"
          to={`/families/${record.householdId}/applications/${id}`}
        >
          {id}
        </Link>
      ),
      sortOrder: sorterInfo?.columnKey === 'caseId' && sorterInfo?.order,
    },
    familyId: {
      title: 'Family ID',
      headerText: 'Family ID',
      dataIndex: ['householdId'],
      key: 'familyId',
      sorter: true,
      width: 120,
      render: (householdId) => (
        <Link
          onClick={(e) => e.stopPropagation()}
          className="!text-black hover:underline px-3 py-1"
          to={`/families/${householdId}/applications`}
        >
          {householdId}
        </Link>
      ),
      sortOrder: sorterInfo?.columnKey === 'familyId' && sorterInfo?.order,
    },
    sponsor: {
      title: 'Sponsor',
      headerText: 'Sponsor',
      dataIndex: 'applicant.firstName',
      key: 'sponsor',
      sorter: true,
      className: 'truncate max-w-xs',
      render: (firstName, { applicant }) => {
        return (
          <span className="truncate max-w-xs" title={applicant ? getFullName(applicant) : undefined}>
            {getFullName(applicant)}
          </span>
        );
      },
      width: 170,
      sortOrder: sorterInfo?.columnKey === 'sponsor' && sorterInfo?.order,
    },
    submittedDate: () => ({
      title: 'Submitted Date',
      dataIndex: 'submittedDate',
      key: 'submittedDate',
      sorter: true,
      width: 180,
      render: (date) => <span>{formatDate(date, DATE_FORMAT[1], EMDASH)}</span>,
      sortOrder: sorterInfo?.columnKey === 'submittedDate' && sorterInfo?.order,
    }),
    expiryDate: (testIdPrefix = '') => ({
      title: 'Expiry Date',
      headerText: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (date) => {
        return <span>{formatDate(date)}</span>;
      },
    }),
    assignedTo: () => ({
      title: isFamilyDashboard ? 'Case Owner' : 'Assigned to',
      width: 170,
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      // sorter: true,
      render: (_, record) => {
        if (isFamilyDashboard) {
          const assignedTo = record?.assignedTo;
          return getFullName(assignedTo) || EMDASH;
        }

        return (
          <DashboardTableAssignedToCell
            options={[
              { text: userFullName, value: currentUser?.id },
              ...(assigneeOptions || [])
                .filter((assignee) => assignee.id !== currentUser?.id)
                .map((assignee) => ({ text: getFullName(assignee), value: assignee.id }))
                .sort((a, b) => a?.text?.localeCompare?.(b?.text)),
            ]}
            record={record}
            fetchData={fetchData}
          />
        );
      },
    }),
    status: () => {
      const dataIndex = isFamilyDashboard ? 'status.status.description' : 'application.status.status.description';
      return {
        title: 'Status',
        headerText: 'Status',
        dataIndex: dataIndex,
        key: 'status',
        width: 160,
        sorter: true,
        className: 'truncate max-w-xs',
        render: (_, record) => {
          const status = record?.status?.status?.description;
          return status ? <span title={status}>{status}</span> : 'N/A';
        },
        sortOrder: sorterInfo?.columnKey === 'status' && sorterInfo?.order,
      };
    },
    startDate: () => {
      const dataIndex = isFamilyDashboard ? 'startDate' : 'application.startDate';
      return {
        title: 'Start Date',
        headerText: 'Start Date',
        dataIndex: dataIndex,
        key: 'startDate',
        width: 140,
        sorter: true,
        render: (date) => {
          return formatDate(date);
        },
        sortOrder: isFamilyDashboard ? sorterInfo?.columnKey === 'startDate' && sorterInfo?.order : undefined,
      };
    },
    endDate: () => {
      const dataIndex = isFamilyDashboard ? 'endDate' : 'application.endDate';
      return {
        title: 'End Date',
        headerText: 'End Date',
        dataIndex: dataIndex,
        key: 'endDate',
        width: 140,
        sorter: true,
        render: (date) => {
          return formatDate(date);
        },
        sortOrder: isFamilyDashboard ? sorterInfo?.columnKey === 'endDate' && sorterInfo?.order : undefined,
      };
    },
    contact: {
      title: 'Contact',
      headerText: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
      width: 200,
      render: (value) =>
        value ? (
          <div className="flex flex-col">
            <p>{value}</p>
          </div>
        ) : (
          'N/A'
        ),
    },
    eligibility: getProgramsColumn({ key: 'eligibility', width: 200, title: 'Programs' }),
    careFacilityType: {
      title: 'Facility Type',
      headerText: 'Facility Type',
      dataIndex: ['careFacilityType', 'title'],
      key: 'careFacilityType',
      width: 200,
      render: (value) =>
        value ? (
          <div className="flex flex-col">
            <p>{value}</p>
          </div>
        ) : (
          'N/A'
        ),
    },
    careFacilityId: {
      title: 'Provider ID',
      dataIndex: isFamilyDashboard ? 'careFacilityId' : 'careFacility.id',
      key: 'careFacilityId',
      width: 125,
      sorter: true,
      sortOrder: sorterInfo?.columnKey === 'careFacilityId' && sorterInfo?.order,
      render: (_, record) => (isFamilyDashboard ? record?.careFacilityId : record?.careFacility?.id),
    },
  };

  const allColumns = colKeys
    .map((key) => (typeof colMapping[key] === 'function' ? colMapping[key]() : colMapping[key]))
    .filter(Boolean);
  return allColumns;
}
