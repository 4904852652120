import axios from 'axios';
import config from 'config';
import { sleep } from 'utils';
import { setupCache, cache } from './interceptors';

const instance = axios.create({ baseURL: `${config.API_BASE_URL}/families` });
const request = setupCache(instance);

export const FAMILY_FILE_TYPES = {
  // optional files
  ORDERS: { title: 'Sponsor employment verification', code: 'ORDERS', required: false },
  SPONSOR_LES: { title: 'Sponsor income verification', code: 'SPONSOR_LES', required: false },
  SPONSOR_OTHER: { title: 'Other sponsor documents', code: 'SPONSOR_OTHER', required: false },
  SPONSOR_PAF: { title: 'Civilian personnel action form', code: 'SPONSOR_PAF', required: false },
  SPOUSE_LOOKING_FOR_WORK: { title: 'Spouse looking for work', code: 'SPOUSE_LOOKING_FOR_WORK', required: false },
  SPOUSE_SCHOOL: { title: 'Spouse schooling schedule', code: 'SPOUSE_SCHOOL', required: false },
  SPOUSE_PAYSTUB: { title: 'Spouse paystub', code: 'SPOUSE_PAYSTUB', required: false },
  SPOUSE_WORK: { title: 'Spouse employment verification', code: 'SPOUSE_WORK', required: false },
  SPOUSE_OTHER: { title: 'Other spouse documents', code: 'SPOUSE_OTHER', required: false },
  CHILD_CYS: { title: 'Child & Youth Services (CYS) verification', code: 'CHILD_CYS', required: false },
  SELF_CERTIFICATION: { title: "Children's birth verification", code: 'SELF_CERTIFICATION', required: false },
  PROVIDER_RATES: { title: "Children's rates verification", code: 'PROVIDER_RATES', required: false },
  CHILD_OTHER: { title: 'Other child documents', code: 'CHILD_OTHER', required: false },
};

const api = {
  getHouseHold: async () => {
    const { data } = await request.get(`/households`);
    return data;
  },
  getChildren: async (id) => {
    const { data } = await request.get(`/households/${id}/children`);
    return data;
  },
  getAdults: async (id) => {
    const { data } = await request.get(`/households/${id}/adults`);
    return data;
  },
  getAdultById: async (id, adultId) => {
    const { data } = await request.get(`/households/${id}/adults/${adultId}`);
    return data;
  },
  getChildSpecialNeeds: async (id, childId) => {
    const { data } = await request.get(`/households/${id}/children/${childId}/special-needs`);
    return data;
  },
  addUpdateChildSpecialNeeds: async (id, childId, payload) => {
    const { data } = await request.post(`/households/${id}/children/${childId}/special-needs`, payload);
    return data;
  },
  getApplication: async (id) => {
    if (id) {
      const { data } = await request.get(`/applications/${id}`);
      return data;
    } else {
      const { data } = await request.get(`/applications`);
      if (data.length === 0) return null;
      const { data: application } = await request.get(`/applications/${data.at(-1)?.id}`);
      return application;
    }
  },
  getFamilyApplication: async (householdId, id) => {
    if (householdId && id) {
      const { data } = await request.get(`/households/${householdId}/applications/${id}`);
      return data;
    }
    return { householdId, id };
  },
  getFamilyApplications: async () => {
    const { data } = await request.get(`/applications`);
    return data;
  },
  getAdditionalParents: async (househodId) => {
    // const { data } = await request.get(`/households/${householdId}/programs`);
    // return data;
  },
  getHouseholdPrograms: async (householdId) => {
    return householdPrograms;
    // const { data } = await request.get(`/households/${householdId}/programs`);
    // return data;
  },
  saveApplication: async (payload) => {
    if (payload.id) {
      const { data } = await request.put(`/applications/${payload.id}`, payload);
      return data;
    } else {
      const { data } = await request.post(`/applications`, payload);
      return data;
    }
  },
  saveApplicationStatus: async (appId, statusId, reasonId) => {
    const { data } = await request.put(
      `/applications/${appId}/status?statusId=${statusId}${reasonId ? `&reasonId=${reasonId}` : ''}`,
    );
    return data;
  },
  saveApplicationDates: async (payload) => {
    const { data } = await request.put(`/applications/${payload.id}/dates`, payload);
    return data;
  },
  addSecondParent: async (appId, parentId) => {
    const { data } = await request.put(`/applications/${appId}/parents/${parentId}`);
    return data;
  },
  updateEligibilityProgram: async (appId, programId) => {
    const { data } = await request.put(`/applications/${appId}/programs/${programId}`);
    return data;
  },
  deleteProgram: async (appId, programId) => {
    const { data } = await request.delete(`/applications/${appId}/program`);
    return data;
  },
  submitApplication: async (id) => {
    const { data } = await request.put(`/applications/${id}/submit`);
    return data;
  },
  acceptAgreement: async (id, payload) => {
    const { data } = await request.post(`/applications/${id}/agreement/accept`, payload);
    return data;
  },
  getApplicationAgreements: async (id) => {
    const { data } = await request.get(`/applications/${id}/agreements`);
    return data;
  },
  saveSecondParent: async (id, payload) => {
    if (payload.id) {
      const { data } = await request.put(`/applications/${id}/parents/${payload.id}`, payload);
      return data;
    } else {
      const { data } = await request.post(`/applications/${id}/parents`, payload);
      return data;
    }
  },
  deleteParent: async (id, parentId) => {
    const { data } = await request.delete(`/applications/${id}/parents/${parentId}`);
    return data;
  },
  updateChild: async (id, childrenId) => {
    const { data } = await request.put(`/applications/${id}/children/${childrenId}`);
    return data;
  },
  deleteChild: async (id, childrenId) => {
    const { data } = await request.delete(`/applications/${id}/children/${childrenId}`);
    return data;
  },
  saveChild: async (id, payload) => {
    if (payload?.id) {
      const { data } = await request.put(`/applications/${id}/children/${payload?.id}`, payload);
      return data;
    } else {
      const { data } = await request.post(`/applications/${id}/children`, payload);
      return data;
    }
  },
  getChildInfo: async (id) => {
    await sleep(500);
    return { id, firstName: 'John', lastName: 'Doe', family: { id: 1234567 } };
  },
  getChildSchedules: async (applicationId, childId) => {
    const { data } = await request.get(`/applications/${applicationId}/children/${childId}/schedules`);
    return data;
  },
  getApplications: async (statuses = []) => {
    const query = statuses.reduce((prev, status) => `${prev}${prev ? '&' : '?'}statuses=${status}`, '');
    const response = await request.get(`/applications${query}`);
    const { data } = response;
    return data;
  },
  assignCoordinator: async (payload) => {
    if (!payload) return;
    const response = await request.post('/applications/assign', payload);
    const { data } = response;
    return data;
  },
  assignReviewer: async (payload) => {
    const response = await request.post('/applications/reviewer/assign', payload);
    const { data } = response;
    return data;
  },
  setApplicationStatus: async (id, statusId) => {
    const response = await request.put(`/applications/${id}/status?statusId=${statusId}`);
    const { data } = response;
    return data;
  },
  saveProviderRequest: async (payload) => {
    const { data } = await request.post(`/care-facilities/requests`, payload);
    return data;
  },
  createERRequest: async (applicationId, childId, scheduleId) => {
    const { data } = await request.post(
      `/applications/${applicationId}/children/${childId}/schedules/${scheduleId}/requests/er`,
    );
    return data;
  },
  saveSchedule: async (id, childId, payload) => {
    if (payload?.id) {
      const { data } = await request.put(`/applications/${id}/children/${childId}/schedules/${payload.id}`, payload);
      return data;
    } else {
      const { data } = await request.post(`/applications/${id}/children/${childId}/schedules`, payload);
      return data;
    }
  },
  newProviderRequest: async (id, childId, scheduleId, careFacilityRequestId) => {
    const { data } = await request.post(
      `/applications/${id}/children/${childId}/schedules/${scheduleId}/requests/new-provider?careFacilityRequestId=${careFacilityRequestId}`,
    );
    return data;
  },
  deleteSchedule: async (id, childId, scheduleId) => {
    const { data } = await request.delete(`/applications/${id}/children/${childId}/schedules/${scheduleId}`);
    return data;
  },
  getEmergencyContact: async (appId, childId) => {
    const { data } = await request.get(`/applications/${appId}/children/${childId}/emergency-contact`);
    return data;
  },
  addUpdateEmergencyContact: async (applicationId, childId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${applicationId}/children/${childId}/emergency-contact${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },
  deleteEmergencyContact: async (applicationId, childId, id) => {
    const { data } = await request.delete(`/applications/${applicationId}/children/${childId}/emergency-contact/${id}`);
    return data;
  },
  updatePrograms: async (applicationId, programId, payload) => {
    const { data } = await request.put(`/applications/${applicationId}/programs/${programId}`, payload);
    return data;
  },
  addSearchProviderAddress: async () => {
    await sleep(50);
    return {};
  },
  addProvider: async () => {
    await sleep(100);
    return {};
  },
  getOptions: async (url) => {
    const response = await request.get(url, { cache });
    const { data } = response;
    return data;
  },
  getDraftNote: async (applicationId) => {
    const { data } = await request.get(`/applications/${applicationId}/notes?draft=true&sort=id,desc&page=0&size=1`);
    return data.content?.[0];
  },
  saveNote: async (applicationId, params) => {
    const { data } = await request[params.id ? 'put' : 'post'](
      `/applications/${applicationId}/notes/${params.id ? params.id : ''}`,
      params,
    );
    return data;
  },
  updateChecklist: async (id, items) => {
    await sleep(200);
    return items;
  },
  getEmailAlerts: async (id) => {
    await sleep(200);
    return FAMILY_EMAILS;
  },
  getEmailDetails: async () => {
    await sleep(200);
    return {};
  },
  getPersonInstallation: async (appId, personId) => {
    const { data } = await request.get(`/applications/${appId}/persons/${personId}/installation`);
    return data;
  },
  updatePersonInstallation: async (appId, personId, payload) => {
    const { data } = await request.put(`/applications/${appId}/persons/${personId}/installation`, payload);
    return data;
  },
  getParentEligibilityDates: async (appId, parentId) => {
    const { data } = await request.get(`/applications/${appId}/parents/${parentId}/eligibility-dates`);
    return data;
  },
  updateParentEligibilityDates: async (appId, parentId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${appId}/parents/${parentId}/eligibility-dates${payload.id ? `/${payload.id}` : ''}`,
      payload.id ? payload : [payload],
    );
    return data;
  },
  getSponsorEligibilityDates: async (appId, sponsorId) => {
    const { data } = await request.get(`/applications/${appId}/applicant/${sponsorId}/eligibility-dates`);
    return data;
  },
  updateSponsorEligibilityDates: async (appId, sponsorId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${appId}/applicant/${sponsorId}/eligibility-dates${payload.id ? `/${payload.id}` : ''}`,
      payload.id ? payload : [payload],
    );
    return data;
  },
  setApplicationPriority: async (id, priority) => {
    const { data } = await request.put(`/applications/${id}/priority?highPriority=${priority || false}`);
    return data;
  },
  getParentEducations: async (parentId) => {
    const { data } = await request.get(`/persons/${parentId}/education`);
    return data;
  },
  addUpdateParentEducations: async (parentId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/persons/${parentId}/education${payload.id ? `/${payload.id}` : ''}`,
      payload.id ? payload : [payload],
    );
    return data;
  },
  deleteParentEducation: async (parentId, id) => {
    const { data } = await request.delete(`/persons/${parentId}/education/${id}`);
    return data;
  },
  getApplicationIncome: async (appId) => {
    const { data } = await request.get(`/applications/${appId}/income`);
    return data;
  },
  getIncomes: async (appId, parentId) => {
    const { data } = await request.get(`/applications/${appId}/adults/${parentId}/income`);
    return data;
  },
  updateIncome: async (appId, parentId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${appId}/adults/${parentId}/income${payload.id ? `/${payload.id}` : ''}`,
      payload.id ? payload : [payload],
    );
    return payload.id ? data : data[0];
  },
  deleteIncome: async (id, personId, incomeId) => {
    const { data } = await request.delete(`/applications/${id}/adults/${personId}/income/${incomeId}`);
    return data;
  },
  getParentEligibility: async (appId, parentId) => {
    const { data } = await request.get(`/applications/${appId}/persons/${parentId}/eligibility`);
    return data;
  },
  updateParentEligibility: async (appId, parentId, eligibilityId, payload) => {
    const { data } = await request.put(
      `/applications/${appId}/persons/${parentId}/eligibility/${eligibilityId}`,
      payload,
    );
    return data;
  },
  getEmploymentDocuments: async (apiPath, appId, parentId) => {
    const { data } = await request.get(`/applications/${appId}/${apiPath}/${parentId}/eligibility-dates`);
    return data;
  },
  addUpdateEmploymentDocument: async (apiPath, appId, parentId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/applications/${appId}/${apiPath}/${parentId}/eligibility-dates${payload.id ? `/${payload.id}` : ''}`,
      payload.id ? payload : [payload],
    );
    return payload.id ? data : data[0];
  },
  deleteEmploymentDocument: async (apiPath, appId, parentId, id) => {
    const { data } = await request.delete(`/applications/${appId}/${apiPath}/${parentId}/eligibility-dates/${id}`);
    return data;
  },
  getAdultEmploymentInfo: async (appId) => {
    // const { data } = await request.get(`/applications/${appId}/$adult/employmentInfo`);
    return [];
  },
  getFamilyRelation: async (houseHoldId, personId) => {
    const { data } = await request.get(`/households/${houseHoldId}/members/${personId}/relationships`);
    return data;
  },
  getSchoolInfo: async (personId) => {
    const { data } = await request.get(`/persons/${personId}/education`);
    return data;
  },
  addUpdateSchool: async (personId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/persons/${personId}/education${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },
  getAdultContactInformation: async (appId) => {
    // const { data } = await request.get(`/applications/${appId}/$adult/employmentInfo`);
    return [];
  },
  addUpdateReservation: async (appId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](`/applications/${appId}/ntc-requests`, payload);
    return data;
  },
  deleteReservation: async (appId, id) => {
    const { data } = await request.delete(`/applications/${appId}/ntc-requests/${id}`);
    return data;
  },
  getChildFamilyRelation: async (appId) => {
    // const { data } = await request.get(`/applications/${appId}/$adult/employmentInfo`);
    return [];
  },
  addUpdateChildFamilyRelation: async (appId, payload) => {
    // const { data } = await request[payload.id ? 'put' : 'post'](`/applications/${appId}/adult`, payload);
    return payload;
  },
  deleteChildFamilyRelation: async (payload) => {
    // const { data } = await request[payload.id ? 'put' : 'post'](`/applications/${appId}/adult`, payload);
    return payload;
  },
  addUpdateAdult: async (id, adultId, payload) => {
    const { data } = await request[adultId ? 'put' : 'post'](
      `/households/${id}/adults${adultId ? `/${adultId}` : ''}`,
      payload,
    );
    return data;
  },
  addUpdateAdultContactInfo: async (appId, payload) => {
    // const { data } = await request[payload.id ? 'put' : 'post'](`/applications/${appId}/adult`, payload);
    return payload;
  },
  deleteAdultContactInfo: async (appId, payload) => {
    // const { data } = await request[payload.id ? 'put' : 'post'](`/applications/${appId}/adult`, payload);
    return payload;
  },
  addUpdateSchoolInfo: async (personId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/persons/${personId}/education${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },
  deleteSchoolInfo: async (personId, educationId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/persons/${personId}/education/${educationId}`,
      payload,
    );
    return data;
  },
  addUpdateAdultEmploymentInfo: async (appId, payload) => {
    // const { data } = await request[payload.id ? 'put' : 'post'](`/applications/${appId}/adult`, payload);
    return payload;
  },
  deleteAdultEmploymentInfo: async (appId, payload) => {
    // const { data } = await request[payload.id ? 'put' : 'post'](`/applications/${appId}/adult`, payload);
    return payload;
  },
  addUpdateFamilyRelationship: async (houseHoldId, personId, payload) => {
    const { data } = await request[payload.id ? 'put' : 'post'](
      `/households/${houseHoldId}/members/${personId}/relationships${payload.id ? `/${payload.id}` : ''}`,
      payload,
    );
    return data;
  },
  deleteFamilyRelationship: async (houseHoldId, personId, id) => {
    const { data } = await request.delete(`/households/${houseHoldId}/members/${personId}/relationships/${id}`);
    return data;
  },
  addUpdateFamilyChild: async (id, childId, payload) => {
    const { data } = await request[childId ? 'put' : 'post'](
      `/households/${id}/children${childId ? `/${childId}` : ''}`,
      payload,
    );
    return data;
  },
  deleteFamilyChild: async (appId, payload) => {
    // const { data } = await request[payload.id ? 'put' : 'post'](`/applications/${appId}/adult`, payload);
    return payload;
  },
  deleteAdult: async (appId, id) => {
    // const { data } = await request.delete(`/applications/${appId}/adult/${id}`);
    return {};
  },
  getReservation: async (appId) => {
    const { data } = await request.get(`/applications/${appId}/ntc-requests`);
    return data;
  },
  getPublicStatusList: async () => {
    const { data } = await request.get(`/options/13`);
    return data;
  },
  getStatusChangelog: async (id) => {
    const { data } = await request.get(`/applications/${id}/status`);
    return data;
  },
  getNotes: async (id, params) => {
    const { data } = await request.get(`/applications/${id}/notes`, { params });
    return data;
  },
  getHouseHoldCertificates: async (houseHoldId) => {
    const { data } = await request.get(`/certificates/households/${houseHoldId}`);
    return data;
  },
  getCertificateById: async (id) => {
    const { data } = await request.get(`/certificates/${id}`);
    return data;
  },
  updateCertificate: async (id, payload) => {
    const { data } = await request.put(`/certificates/${id}`, payload);
    return data;
  },
  updateCertificateStatus: async (id, payload) => {
    const { data } = await request.put(`/certificates/${id}/status`, payload);
    return data;
  },
  deleteCertificate: async (id) => {
    const { data } = await request.delete(`/certificates/${id}`);
    return data;
  },
  createCertificate: async (payload) => {
    const { data } = await request.post(`/certificates`, payload);
    return data;
  },
  caculateCertificacteFeeAssistance: async (id) => {
    const { data } = await request.put(`/certificates/${id}/calculate`);
    return data;
  },
  caculateFeeAssistance: async (payload) => {
    const { data } = await request.post(`/certificates/standalone/calculate`, payload);
    return data;
  },
  getCertificateTypes: async () => {
    const { data } = await request.get(`/certificates/types`);
    return data;
  },
  getCertificateStatusTypes: async () => {
    const { data } = await request.get(`/certificates/status-types`);
    return data;
  },
  /*
   * APPLICATION FILES
   */
  getApplicationFiles: async (appId) => {
    const { data } = await request.get(`/applications/${appId}/files`);
    return data;
  },
  uploadApplicationFile: async (appId, file, fileType) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileType || FAMILY_FILE_TYPES.OTHER.code);

    const { data } = await request.post(`/applications/${appId}/files`, formData);
    return data;
  },
  deleteApplicationFile: async (appId, fileId) => {
    const { data } = await request.delete(`/applications/${appId}/files/${fileId}`);
    return data;
  },
  downloadAllApplicationFiles: async (appId) => {
    const { data } = await request.post(
      `/applications/${appId}/files/download`,
      {},
      {
        responseType: 'blob',
      },
    );
    return data;
  },
};
export { request };
export default api;

const FAMILY_EMAILS = [
  {
    id: 15,
    careFacilityId: 9243544,
    type: 'EMAIL',
    createdBy: {
      id: 27,
      firstName: 'Dev',
      lastName: 'Coordinator',
      email: 'dev-coordinator@mailpoof.com',
    },
    createdAt: '2022-01-31T20:06',
    subject: 'WELCOME TO CCAOA, YOUR APPLICATION HAS BEEN SUBMITTED',
    content:
      '\t<p>\n      <span style="font-family: Arial, sans-serif; color: rgb(33, 42, 62)">Hello Test Business,</span>\n    </p>\n    <p>\n      <span style="font-family: Arial, sans-serif; color: rgb(33, 42, 62)">Thank you for submitting your application for . We\'ve received your information and will review it to make sure it\'s complete and accurate. If we need more information from you, we\'ll follow up by sending you an email and posting a message on your portal. You can always check the status of your application through your portal here:</span>\n    </p>\n    <p>\n      <a style="font-family: Arial, sans-serif; color: rgb(30, 53, 255);" href="http://origin.dev.greatchildcare.org:80/provider/9243544/program">My Application</a>\n    </p>\n',
    status: 'QUEUED',
    errorMessage: null,
    errorCode: null,
    scheduledDate: null,
    deliveryDateTime: null,
    read: true,
    recipients: [
      {
        id: 15,
        name: 'waheedakhtar694@gmail.com',
      },
    ],
    fromEmail: null,
    messageId: 'cc7f3886-b186-42e9-a7ef-ab5acb12f6d5',
    templateId: null,
    incoming: false,
    attachments: null,
  },
  {
    id: 14,
    careFacilityId: 9243445,
    type: 'EMAIL',
    createdBy: {
      id: 53,
      firstName: 'Zohaib',
      lastName: 'Ijaz',
      email: 'zohaib.enquizit+prm@gmail.com',
    },
    createdAt: '2022-01-28T13:47',
    subject: 'Some Subject',
    content: '<p><span style="font-size: 30pt;">Hello</span></p>',
    status: 'QUEUED',
    errorMessage: null,
    errorCode: null,
    scheduledDate: null,
    deliveryDateTime: null,
    read: true,
    recipients: [
      {
        id: 14,
        name: 'zohaib.enquizit@gmail.com',
      },
    ],
    fromEmail: null,
    messageId: 'd044c267-af44-44da-b0bd-09c5f0fdd660',
    templateId: null,
    incoming: true,
    attachments: null,
  },
  {
    id: 82,
    careFacilityId: 9244644,
    type: 'EMAIL',
    createdBy: {
      id: 48,
      firstName: 'Zohaib',
      lastName: 'Ijaz',
      email: 'zohaib.enquizit+test22@gmail.com',
    },
    createdAt: '2022-03-02T19:45',
    subject: 'WELCOME TO CCAOA, YOUR APPLICATION HAS BEEN SUBMITTED',
    content:
      '\t<p>\n      <span style="font-family: Arial, sans-serif; color: rgb(33, 42, 62)">Hello Test Care Facility,</span>\n    </p>\n    <p>\n      <span style="font-family: Arial, sans-serif; color: rgb(33, 42, 62)">Thank you for submitting your application for . We\'ve received your information and will review it to make sure it\'s complete and accurate. If we need more information from you, we\'ll follow up by sending you an email and posting a message on your portal. You can always check the status of your application through your portal here:</span>\n    </p>\n    <p>\n      <a style="font-family: Arial, sans-serif; color: rgb(30, 53, 255);" href="http://origin.dev.greatchildcare.org:80/provider/9244644/program">My Application</a>\n    </p>\n',
    status: 'PENDING',
    errorMessage: null,
    errorCode: null,
    scheduledDate: null,
    deliveryDateTime: null,
    read: true,
    recipients: [
      {
        id: 59,
        name: 'zohaib.enquizit@gmail.com',
      },
    ],
    fromEmail: null,
    messageId: '2d26f899-ce52-4859-b098-09ff0e351e1f',
    templateId: null,
    incoming: false,
    attachments: null,
  },
];

const householdPrograms = [
  {
    id: 1,
    program: 'MCCYN',
    branch: { title: 'Army' },
    installation: "John's Installation",
    ulaComplete: true,
    renewalDate: '01/01/2024',
    status: 'Verifying',
  },
  {
    id: 2,
    program: 'CCYH',
    branch: { title: 'NGA' },
    installation: 'Harry Installation',
    ulaComplete: false,
    renewalDate: '02/02/2024',
    status: 'Pending',
  },
  {
    id: 3,
    program: 'Army Respite',
    branch: { title: 'Air Force' },
    installation: 'Tom Installation',
    ulaComplete: true,
    renewalDate: '03/03/2024',
    status: 'Verifying',
  },
  {
    id: 3,
    program: 'WDCC',
    branch: { title: 'Navy' },
    installation: 'Nancy Installation',
    ulaComplete: true,
    renewalDate: '04/04/2024',
    status: 'Approved',
  },
];
