import React, { useMemo, useCallback } from 'react';
import { message, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import classNames from 'classnames';

import MilitaryChildCareInstructions from './MilitaryChildCareInstructions';
import ArmyInstructions from './ArmyInstructions';
import ChildCareInHomeInstructions from './ChildCareInHomeInstructions';
import NavyInstructions from './NavyInstructions';
import TroopProgramUnitInstructions from './TroopProgramUnitInstructions';
import WeekendDrillChildCareInstructions from './WeekendDrillChildCareInstructions';
import styles from './Submitted.module.less';
import commonService from 'services/common.service';
export default function Submitted({ setStep, next, application, household }) {
  const history = useHistory();
  const { loading, result: programs = [] } = useAsync(async () => {
    try {
      return await commonService.getPrograms();
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Programs not found.', 5);
      return [];
    }
  }, []);

  const selectedProgramType = useMemo(
    () => programs.find((item) => item.id === application?.programType?.id)?.type,
    [application?.programType?.id, programs],
  );

  const onContinueToDashboard = useCallback(() => {
    history.push(`/family/application/${application?.id}/dashboard`);
  }, [application?.id, history]);

  const onReturnToHome = useCallback(() => {
    history.push('/family');
  }, [history]);

  const commonProps = useMemo(
    () => ({
      application,
      household,
      onContinueToDashboard,
      onReturnToHome,
    }),
    [application, household, onContinueToDashboard, onReturnToHome],
  );

  return (
    <Spin spinning={loading}>
      <div className={classNames(styles.nextSteps, 'mb-4')}>
        {selectedProgramType?.title === 'MCCYN' && <MilitaryChildCareInstructions {...commonProps} />}
        {selectedProgramType?.title === 'TPU' && <TroopProgramUnitInstructions {...commonProps} />}
        {selectedProgramType?.title === 'CCYH' && <ChildCareInHomeInstructions {...commonProps} />}
        {selectedProgramType?.title === 'EFM Respite' && <NavyInstructions {...commonProps} />}
        {selectedProgramType?.title === 'Army Respite' && <ArmyInstructions {...commonProps} />}
        {selectedProgramType?.title === 'WDCC' && <WeekendDrillChildCareInstructions {...commonProps} />}
      </div>
    </Spin>
  );
}
