import React, { useCallback, useContext, useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

const context = React.createContext({});

export default function Group({ as, className, children, onChange, value, multiple, comparator, id }) {
  const [keys, setKeys] = useState([]);
  return React.createElement(
    as,
    as === Fragment ? {} : { id, className },
    <context.Provider value={{ state: value, setState: onChange, multiple, comparator, keys, setKeys, id }}>
      {children}
    </context.Provider>,
  );
}

Group.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any,
  multiple: PropTypes.bool,
  comparator: PropTypes.func,
  as: PropTypes.oneOfType([PropTypes.string, Fragment]),
};
Group.defaultProps = {
  multiple: false,
  comparator: (v) => v,
  as: Fragment,
};

export function GroupContext({ children, valuePropName = 'checked', value }) {
  const { state, setState, multiple, comparator, id } = useContext(context);
  const onChange = useCallback(
    (checked) => {
      if (multiple) {
        if (checked) {
          const newState = [...Array.from(state || []), value];
          setState(newState);
        } else {
          setState(Array.from(state || []).filter((v) => comparator(v) !== comparator(value)));
        }
      } else {
        if (checked) {
          setState(value);
        } else {
          setState(null);
        }
      }
    },
    [comparator, multiple, setState, state, value],
  );

  useEffect(() => {
    if (children.props.defaultChecked) {
      setState(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children.props.defaultChecked]);

  return React.cloneElement(children, {
    id: `${id}_${comparator(value)}`,
    onChange,
    checked: multiple
      ? Boolean(Array.from(state || []).find((v) => comparator(v) === comparator(value)))
      : comparator(value) === comparator(state),
    defaultChecked: children.props.defaultChecked,
  });
}
export function GroupMapContext({ children, value }) {
  const { state, setState, multiple, keys, setKeys, id } = useContext(context);
  useEffect(() => {
    setKeys((v) => [...v, value]);
  }, [setKeys, value]);
  const onChange = useCallback(
    (checked) => {
      const defaultState = keys.reduce((prev, key) => ({ ...prev, [key]: false }), {});
      if (multiple) {
        const newState = {
          ...defaultState,
          ...(state || {}),
          [value]: checked,
        };
        setState(newState);
      } else {
        const newState = { ...state, ...defaultState };
        newState[value] = checked;
        setState(newState);
      }
    },
    [keys, multiple, setState, state, value],
  );
  return React.cloneElement(children, {
    id: `${id}_${value}`,
    onChange,
    checked: state?.[value] || false,
  });
}
