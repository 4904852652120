import PropTypes from 'prop-types';
import React, { useState, Fragment, useMemo } from 'react';
import { Button, Tabs } from 'antd';
import classNames from 'classnames';
import { Popover, Transition } from '@headlessui/react';
import { useLocation, useHistory } from 'react-router-dom';
import SendEmailModal from 'components/Modals/SendEmailModal';
import { ENTITY_TYPES } from 'constants/index';
import { Close, Email, Switcher } from '@carbon/icons-react';

export default function ApplicationNavBar({
  className,
  breakpoints,
  toMail,
  items,
  entity,
  entityType = ENTITY_TYPES.CARE_FACILITY,
  hideEmail = false,
}) {
  const [emailPopupVisible, setEmailPopupVisible] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();

  const flatItems = items.reduce((prev, curr) => {
    return prev.concat(curr).concat(curr.children || []);
  }, []);
  const selectedKeys = flatItems
    .filter((item) => {
      if (Array.isArray(item.regex)) {
        return item.regex.some((regex) => regex.test(pathname));
      }
      return item.regex.test(pathname);
    })
    .map((item) => item.key);

  const onMenuItemClick = (key) => {
    const target = items.find((item) => item.key === key);

    if (target) {
      history.push(target.to);
      /* istanbul ignore else */
    }
  };

  const actionItems = (isMenuItem, className) => (
    <div className={classNames({ flex: !isMenuItem, ...className })}>
      <Button
        type="primary"
        icon={<Email />}
        onClick={() => setEmailPopupVisible(true)}
        data-testId="email-popup-trigger"
      >
        New Email
      </Button>
    </div>
  );

  const menuItems = useMemo(() => {
    return items.map((item) => ({ label: item.label, key: item.key, tabIndex: '0', children: item.children }), []);
  }, [items]);

  const menu = (
    <Tabs items={menuItems} onChange={onMenuItemClick} activeKey={(selectedKeys || [])[0]} data-testid="menu" />
  );

  return (
    <div className={classNames(className, 'mb-8')}>
      <div className="w-full h-12 sm:flex sm:justify-between relative">
        {breakpoints.xs && (
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  data-testid="main-menu-trigger"
                  className={'h-12 w-12 bg-white outline-none focus:ring-2 focus:ring-inset'}
                >
                  {open ? <Close className="w-6 h-6 text-gray-400" /> : <Switcher className="w-6 h-6 text-gray-400" />}
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="main-menu-popover absolute bg-white shadow-2xl z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-3xl">
                    {menu}
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        )}
        {!breakpoints.xs && menu}
        {!hideEmail &&
          actionItems(false, {
            'absolute top-0 right-0': breakpoints.xs,
            relative: !breakpoints.xs,
          })}
      </div>

      <SendEmailModal
        visible={emailPopupVisible}
        setVisible={setEmailPopupVisible}
        entityId={entity?.id}
        entityType={entityType}
        toMail={toMail}
        showSchedule
      />
    </div>
  );
}

ApplicationNavBar.propTypes = {
  breakpoints: PropTypes.shape({
    xm: PropTypes.bool,
    sm: PropTypes.bool,
    md: PropTypes.bool,
    lg: PropTypes.bool,
    xl: PropTypes.bool,
    xxl: PropTypes.bool,
  }),
  className: PropTypes.string,
};
ApplicationNavBar.defaultProps = {
  className: '',
  breakpoints: { xs: false, sm: true, md: true, lg: true, xl: true, xxl: true },
};
