import React, { useEffect, useState } from 'react';
import { Alert, Spin } from 'antd';
import { useSelector } from 'react-redux';
import Actions from '../../Actions';
import Section from 'components/Section';
import { ADD_FAMILY_STEPS } from 'constants/index';
import { selectors } from 'features/family';
import { familyService } from 'services';
import UploadFile, { UploadFileEntityTypes } from 'components/UploadFile/UploadFile';
import { FAMILY_FILE_TYPES } from 'services/family.service';
import Card from 'components/Card/Card';

export default function SecondParentDocs({ id, application, next, setStep }) {
  const isLoading = useSelector(selectors.selectLoading);

  const [applicationFiles, setApplicationFiles] = useState(null);
  useEffect(() => {
    familyService
      .getApplicationFiles(id)
      .then((data) => {
        setApplicationFiles(data);
      })
      .catch((error) => {
        console.error('Error fetching application files', error);
      });
  }, [id]);

  return (
    <div>
      <Spin spinning={isLoading}>
        <Card noBodyPadding className="mt-8">
          <Section
            contentClassName="px-6"
            className="mb-6"
            heading={
              <>
                ADDITIONAL PARENT INCOME <span className="text-gray-500 normal-case">(if applicable)</span>
              </>
            }
            collapsible={false}
          >
            <p className="mb-4">If your second parent is employed, upload documentation of their income:</p>

            <ul className="list-disc pl-6">
              <li>
                <b className="font-bold">Pay stubs:</b> All stubs covering a recent 30-day period showing hours worked
                with gross income
              </li>
              <li>
                Documentation must clearly show <b className="font-bold">both income AND hours worked</b> to determine
                eligibility
              </li>
              <li>
                <b className="font-bold">For multiple jobs:</b> Include documents from all employers to show total hours
              </li>
              <li>
                <b className="font-bold">Self-employed:</b> Upload your last year’s tax documents or year-to-date
                earnings statements and supply the average hours worked weekly
              </li>
              <li>Part-time employment qualifies for reduced subsidy amount (70% of full-time fee assistance)</li>
            </ul>

            <Alert
              message="COMMON REJECTION REASONS"
              description={
                <ul className="list-disc pl-6">
                  <li>Pay stubs don't cover a full 30-day period</li>
                  <li>Hours worked not clearly indicated (must show on documents)</li>
                  <li>Documentation older than 90 days from submission date or care start date</li>
                  <li>Missing employer information or missing pay from additional jobs</li>
                  <li>Gross pay is not clearly shown</li>
                </ul>
              }
              type="warning"
              showIcon
              className="my-6"
            />

            <UploadFile
              entityType={UploadFileEntityTypes.FAMILY}
              fileCode={FAMILY_FILE_TYPES.SPOUSE_PAYSTUB.code}
              files={applicationFiles}
              setFiles={setApplicationFiles}
              applicationId={id}
              className="mt-4"
            />
          </Section>
        </Card>

        <Card noBodyPadding className="mt-8">
          <Section
            className="mb-6"
            contentClassName="px-6"
            heading={
              <>
                SPOUSE SCHOOL SCHEDULE <span className="text-gray-500 normal-case">(if student)</span>
              </>
            }
            collapsible={false}
          >
            <p className="mb-4">If your second parent is a student (otherwise skip this section):</p>

            <ul className="list-disc pl-6">
              <li>Must be CURRENT OFFICIAL schedule from the school (screenshots not accepted)</li>
              <li>Schedule MUST SHOW CREDIT HOURS for each course - this determines eligibility level</li>
              <li>
                <b className="font-bold">For Army:</b> Full-time status requires 12+ undergraduate or 9+ graduate credit
                hours
              </li>
              <li>
                <b className="font-bold">For Air Force/Marines/Space Force:</b> Full-time status requires
              </li>
              <li>Part-time students qualify for reduced subsidy amount (70%)</li>
            </ul>

            <Alert
              message="COMMON REJECTION REASONS"
              description={
                <ul className="list-disc pl-6">
                  <li>Pay stubs don't cover a full 30-day period</li>
                  <li>Hours worked not clearly indicated (must show on documents)</li>
                  <li>Documentation older than 90 days from submission date or care start date</li>
                  <li>Missing employer information or missing pay from additional jobs</li>
                  <li>Gross pay is not clearly shown</li>
                </ul>
              }
              type="warning"
              showIcon
              className="my-6"
            />

            <UploadFile
              entityType={UploadFileEntityTypes.FAMILY}
              fileCode={FAMILY_FILE_TYPES.SPOUSE_SCHOOL.code}
              files={applicationFiles}
              setFiles={setApplicationFiles}
              applicationId={id}
              className="mt-4"
            />
          </Section>
        </Card>

        <Card noBodyPadding className="mt-8">
          <Section
            contentClassName="px-8"
            className="mb-6"
            heading={
              <>
                SPECIAL EMPLOYMENT VERIFICATION <span className="text-gray-500 normal-case">(if needed)</span>
              </>
            }
            collapsible={false}
          >
            <p className="mb-4">Only for additional parents with special employment situations:</p>

            <ul className="list-disc pl-6">
              <li>
                <b className="font-bold">Dual military:</b> Upload applicable orders (if both parents are service
                members)
              </li>
              <li>
                <b className="font-bold">Newly employed:</b> Upload a copy of an Employment Verification Form signed by
                their supervisor showing expected pay and hours
              </li>
              <li>
                <b className="font-bold">Parental leave:</b> Upload a copy of a Maternity/Paternity Verification Form
                signed by their supervisor showing expected pay and hours
              </li>
              <li>
                <b className="font-bold">Commission/tips:</b> Documentation explaining income variations
              </li>
              <li>
                <b className="font-bold">Variable schedules:</b> Employer letter verifying typical weekly hours
              </li>
              <li>
                <b className="font-bold">Teachers paid over 12 months:</b> Contract showing schedule and annual salary
              </li>
            </ul>

            <Alert
              message="COMMON REJECTION REASONS"
              description={
                <ul className="list-disc pl-6">
                  <li>Missing verification of actual hours worked</li>
                  <li>Self-employment documentation doesn't establish regular business activity</li>
                  <li>Documentation for only one job when spouse has multiple positions</li>
                  <li>Employment verification older than 90 days</li>
                </ul>
              }
              type="warning"
              showIcon
              className="my-6"
            />

            <UploadFile
              entityType={UploadFileEntityTypes.FAMILY}
              fileCode={FAMILY_FILE_TYPES.SPOUSE_WORK.code}
              files={applicationFiles}
              setFiles={setApplicationFiles}
              applicationId={id}
              className="mt-4"
            />
          </Section>
        </Card>

        <Card noBodyPadding className="mt-8">
          <Section
            className="mb-6"
            contentClassName="px-8"
            heading={
              <>
                SPOUSE LOOKING FOR WORK <span className="text-gray-500 normal-case">(if applicable)</span>
              </>
            }
            collapsible={false}
          >
            <p className="mb-4">
              If your additional parent is actively seeking employment (otherwise skip this section):
            </p>

            <ul className="list-disc pl-6">
              <li>Upload signed Looking For Work document</li>
              <li>After 90 days, you MUST provide employment or student verification to continue receiving benefits</li>
            </ul>

            <Alert
              message="COMMON REJECTION REASONS"
              description={
                <ul className="list-disc pl-6">
                  <li>Documentation older than 90 days</li>
                  <li>
                    IMPORTANT: "Looking for work" status provides ONLY 90 DAYS of temporary eligibility per PCS move
                  </li>
                  <li>Missing spouse's name on documentation</li>
                  <li>Document missing signatures</li>
                </ul>
              }
              type="warning"
              showIcon
              className="my-6"
            />

            <UploadFile
              entityType={UploadFileEntityTypes.FAMILY}
              fileCode={FAMILY_FILE_TYPES.SPOUSE_LOOKING_FOR_WORK.code}
              files={applicationFiles}
              setFiles={setApplicationFiles}
              applicationId={id}
              className="mt-4"
            />
          </Section>
        </Card>

        <Card noBodyPadding className="mt-8">
          <Section
            className="mb-6"
            contentClassName="px-8"
            heading={
              <>
                OTHER SPOUSE DOCUMENTS <span className="text-gray-500 normal-case">(if applicable)</span>
              </>
            }
            collapsible={false}
          >
            <p className="mb-4">Upload any other relevant additional parent documentation:</p>

            <ul className="list-disc pl-6">
              <li>Any special circumstances documentation that may affect eligibility</li>
              <li>
                <p>
                  <b className="font-bold">Medical exemption requests:</b> If the additional parent cannot meet the
                  minimum eligibility requirements with a combination of school and work
                  <ul className="list-[revert] pl-6">
                    <li>
                      <p>
                        Upload a letter from the parent’s doctor on official letter head with the following information:
                      </p>

                      <ul className="list-[square] pl-6">
                        <li>Name of the parent</li>

                        <li>
                          Explicitly verifying the parent not able to work, go to school, and why not able to watch the
                          children
                        </li>

                        <li>Duration of how long not able to work and watch the children</li>

                        <li>Dated within 90 days of start date of care</li>

                        <li>Hand signature of doctor</li>
                      </ul>
                    </li>
                  </ul>
                </p>
              </li>
            </ul>

            <UploadFile
              entityType={UploadFileEntityTypes.FAMILY}
              fileCode={FAMILY_FILE_TYPES.SPOUSE_OTHER.code}
              files={applicationFiles}
              setFiles={setApplicationFiles}
              applicationId={id}
              className="mt-4"
            />
          </Section>
        </Card>
      </Spin>

      <Actions
        onSubmit={async () => {
          next({
            paths: {
              step: ADD_FAMILY_STEPS.SECOND_PARENTS_DOCS,
              id,
            },
          });
        }}
        onBack={() => {
          setStep(id, ADD_FAMILY_STEPS.SECOND_PARENTS);
        }}
        style={{ marginTop: '1rem' }}
      />
    </div>
  );
}
