import React, { useCallback, useEffect, useState } from 'react';
import Form from 'components/Form';
import Section from 'components/Section';
import { ADD_FAMILY_STEPS } from 'constants/index';
import { Alert } from 'antd';
import { familyService } from 'services';
import UploadFile, { UploadFileEntityTypes } from 'components/UploadFile/UploadFile';
import { FAMILY_FILE_TYPES } from 'services/family.service';
import Card from 'components/Card/Card';

export default function Documents({ id, next }) {
  const onFinish = useCallback(() => {
    next({
      paths: {
        step: ADD_FAMILY_STEPS.CHILDREN,
        id,
      },
    });
  }, [id, next]);

  const [applicationFiles, setApplicationFiles] = useState(null);
  useEffect(() => {
    familyService
      .getApplicationFiles(id)
      .then((data) => {
        setApplicationFiles(data);
      })
      .catch((error) => {
        console.error('Error fetching application files', error);
      });
  }, [id]);

  return (
    <Form onFinish={onFinish}>
      <Card noBodyPadding>
        <Section
          className="mb-4"
          contentClassName="px-8"
          heading={
            <>
              BIRTH CERTIFICATE <span className="text-red-500 normal-case">(required for each child)</span>
            </>
          }
          collapsible={false}
        >
          <p className="mb-4">Upload the official government birth certificate for each child in your application:</p>

          <ul className="list-disc pl-6">
            <li>MUST be an official government certificate with seal/watermark (hospital records NOT accepted)</li>
            <li>Child's name on certificate MUST match exactly what you entered in your application</li>
            <li>Certificate must clearly show sponsor as the parent (if not, additional documentation required)</li>
          </ul>

          <Alert
            message="COMMON REJECTION REASONS"
            description={
              <ul className="list-disc pl-6">
                <li>Child's name doesn't match what you entered in application</li>
                <li>Service member not listed as parent (requires additional documentation)</li>
                <li>Poor quality scan making information unreadable</li>
              </ul>
            }
            type="warning"
            showIcon
            className="my-6"
          />

          <UploadFile
            entityType={UploadFileEntityTypes.FAMILY}
            fileCode={FAMILY_FILE_TYPES.SELF_CERTIFICATION.code}
            files={applicationFiles}
            setFiles={setApplicationFiles}
            applicationId={id}
            className="mt-4"
          />
        </Section>
      </Card>

      <Card noBodyPadding className="mt-8">
        <Section
          className="mb-4"
          contentClassName="px-8"
          heading={
            <>
              STATEMENT OF NON-AVAILABILITY (SNA) <span className="text-gray-500 normal-case">(if applicable)</span>
            </>
          }
          collapsible={false}
        >
          <p className="mb-4">This form confirms on-base childcare is not available for your child:</p>

          <ul className="list-disc pl-6">
            <li>Must be signed by Child and Youth Services representative at an eligible installation</li>
            <li>Must be dated within the last 90 days or your application will be delayed</li>
            <li>Must specifically reference your child's age</li>
            <li>
              <b className="font-bold">Exception:</b> If you live more than 20 miles from an installation, upload proof
              of address instead
            </li>
          </ul>

          <Alert
            message="COMMON REJECTION REASONS"
            description={
              <ul className="list-disc pl-6">
                <li>SNA is outdated (older than 90 days) - check the date before uploading</li>
                <li>Missing required signature from Child and Youth Services representative</li>
                <li>SNA from wrong installation</li>
                <li>Child's date of birth not clearly indicated on the form</li>
              </ul>
            }
            type="warning"
            showIcon
            className="my-6"
          />

          <UploadFile
            entityType={UploadFileEntityTypes.FAMILY}
            fileCode={FAMILY_FILE_TYPES.CHILD_CYS.code}
            files={applicationFiles}
            setFiles={setApplicationFiles}
            applicationId={id}
            className="mt-4"
          />
        </Section>
      </Card>

      <Card noBodyPadding className="mt-8">
        <Section
          className="mb-4"
          contentClassName="px-8"
          heading={
            <>
              PROVIDER COST VERIFICATION FORM (PCVF) <span className="text-red-500 normal-case">(required)</span>
            </>
          }
          collapsible={false}
        >
          <p className="mb-4">This form documents your childcare costs and is used to calculate your fee assistance:</p>

          <ul className="list-disc pl-6">
            <li>BOTH you AND your provider MUST sign the form - missing signatures cause major delays</li>
            <li>Form must show your child's EXACT care schedule (days and hours) clearly marked</li>
            <li>All applicable discounts MUST be applied to the rates shown</li>
            <li>
              <b className="font-bold">For Army only:</b> Registration fees up to $150 per child may be covered
            </li>
            <li>
              <b className="font-bold">For Army only:</b> 15% sibling assistance bonus applies to older siblings
            </li>
          </ul>

          <Alert
            message="COMMON REJECTION REASONS"
            description={
              <ul className="list-disc pl-6">
                <li>Missing parent or provider signature</li>
                <li>Child's care schedule not clearly indicated</li>
                <li>Rates don't match provider's published rates</li>
                <li>Available provider discounts not reflected in rate</li>
              </ul>
            }
            type="warning"
            showIcon
            className="my-6"
          />

          <UploadFile
            entityType={UploadFileEntityTypes.FAMILY}
            fileCode={FAMILY_FILE_TYPES.PROVIDER_RATES.code}
            files={applicationFiles}
            setFiles={setApplicationFiles}
            applicationId={id}
            className="mt-4"
          />
        </Section>
      </Card>

      <Card noBodyPadding className="mt-8">
        <Section
          className="mb-4"
          contentClassName="px-8"
          heading={
            <>
              OTHER CHILD DOCUMENTS <span className="text-gray-500 normal-case">(if applicable)</span>
            </>
          }
          collapsible={false}
        >
          <p className="mb-4">Upload any remaining documents needed for your children:</p>

          <ul className="list-disc pl-6">
            <li>
              <p className="font-bold">Sponsor doesn’t appear on the birth certificate:</p>

              <ul className="list-[revert] pl-6">
                <li>Upload the final version of the sponsor’s DEERS enrollment</li>
                <li>
                  A screenshot of the Beneficiary Web Enrollment (BWE) page with the child’s name selected is an
                  accepted form of verification
                </li>
                <li>Adoption documentation if applicable</li>
                <li>Foster care documentation if applicable</li>
              </ul>
            </li>

            <li>
              <p className="font-bold">
                If child’s last name does not match the sponsor and birth certificate has not been uploaded:
              </p>

              <ul className="list-[revert] pl-6">
                <li>Upload the final version of the sponsor’s DEERS enrollment</li>
                <li>
                  A screenshot of the Beneficiary Web Enrollment (BWE) page with the child’s name selected is an
                  accepted form of verification
                </li>
              </ul>
            </li>
          </ul>

          <Alert
            message="COMMON REJECTION REASONS"
            description={
              <ul className="list-disc pl-6">
                <li>
                  Please note: the DEERS Enrollment Application (DD 1172-2) is not an acceptable form of verification
                </li>
                <li>Child is not a legal dependent of the sponsor</li>
              </ul>
            }
            type="warning"
            showIcon
            className="my-6"
          />

          <UploadFile
            entityType={UploadFileEntityTypes.FAMILY}
            fileCode={FAMILY_FILE_TYPES.CHILD_OTHER.code}
            files={applicationFiles}
            setFiles={setApplicationFiles}
            applicationId={id}
            className="mt-4"
          />
        </Section>
      </Card>
    </Form>
  );
}
