import React, { useCallback, useEffect, useState, useMemo } from 'react';
import produce from 'immer';
import { InputNumber, message } from 'antd';
import { useAsync } from 'react-async-hook';

import Form from 'components/Form';
import Section from 'components/Section';
import { AsyncSelect } from 'components/Select';
import AddressFields from 'components/AddressFields';
import CommanderInfo from 'components/CommanderInfo';
import ContactInfo from 'containers/Family/AddApplication/Steps/ContactInfo.jsx';
import SponsorInstallationModal from 'components/Modals/SponsorInstallationModal';
import familyService from 'services/family.service';
import commonService from 'services/common.service';
import { setDefaultCountry, deepClone, getFullName } from 'utils';
import useHasRole from 'hooks/useHasRole';
import usersService from 'services/users.service.js';

import { ADD_FAMILY_STEPS, ROLES } from 'constants/index';
import ApplicationEntityReview from 'containers/Family/AddApplication/__components/ApplicationEntityReview';
import Card from 'components/Card/Card';

export default function Sponsor({ id, setStep, next, household, application, dispatch, actions, onCancel, loading }) {
  const [visibleInstallationModal, setVisibleInstallationModal] = useState(false);
  const prefixName = useMemo(() => ['applicant'], []);
  const militaryInstallationIdFieldName = useMemo(() => ['applicant', 'position', 'militaryInstallationId'], []);
  const [form] = Form.useForm();
  const applicantId = Form.useWatch(['applicantId'], form);
  const programSponsorId = Form.useWatch(['applicant', 'position', 'programSponsor', 'id'], form);
  const militaryComponentId = Form.useWatch(['applicant', 'position', 'militaryComponent', 'id'], form);
  const militaryStatusId = Form.useWatch(['applicant', 'position', 'militaryStatus', 'id'], form);

  const isFamily = useHasRole(ROLES.FAMILY);

  const { result: currentUser } = useAsync(async () => {
    if (!application.applicant?.position?.programSponsor?.id) {
      return await usersService.getCurrentUser();
    }
    return {};
  }, []);

  const { result: programs = [] } = useAsync(async () => {
    return await commonService.get(`/common/programs?active=true`, { cache: { interpretHeader: false } });
  }, []);

  const applicant = useMemo(() => household?.adults?.find?.((a) => a.id === applicantId), [applicantId, household]);
  const program = useMemo(() => {
    return (programs || []).find((prog) => prog?.id === application?.program?.id);
  }, [application?.program?.id, programs]);

  const onSubmit = useCallback(
    async (values) => {
      values = deepClone(values);
      dispatch(actions.setIsFormSubmitting(true));
      try {
        if (isFamily) {
          // any actions specific to family
          const { programTypeId } = values;
          const program = programs.find(
            (prog) => prog.type.id === programTypeId && prog.sponsor.id === applicant?.position?.programSponsor?.id,
          );
          delete values.programTypeId;
          values = {
            ...values,
            applicant: { id: values.applicantId },
            program,
            id,
          };
          if (id && program?.id && application.program?.id !== program?.id) {
            // if program changed, update program via separate api
            await familyService.updatePrograms(id, program?.id);
          }
        } else {
          values = setDefaultCountry(values, 'homeAddress');
          values = produce({ id: application.id, ...values }, (draft) => {
            draft.applicant = {
              ...(application.applicant || {}),
              ...(values.applicant || {}),
              position: {
                ...(application?.applicant?.position || {}),
                ...values.applicant?.position,
              },
              homeAddress: draft.homeAddress,
            };
            draft.id = application.id;
            delete draft.homeAddress;
          });
        }
        if (!isFamily) {
          const data = await familyService.saveApplication(values);
          dispatch(actions.setApplication(data));
        }
        next({
          paths: { step: ADD_FAMILY_STEPS.SPONSOR, id: application.id },
          applicationId: application.id,
        });
      } catch (error) {
        message.error(`Unable to ${application?.applicant?.id ? 'update' : 'create'} Sponsor.`);
        newrelic.noticeError(error);
      } finally {
        dispatch(actions.setIsFormSubmitting(false));
      }
    },
    [
      actions,
      applicant?.position?.programSponsor?.id,
      application.applicant,
      application.id,
      application.program?.id,
      dispatch,
      id,
      isFamily,
      next,
      programs,
    ],
  );

  useEffect(() => {
    form.setFieldsValue({
      ...application,
      applicantId: application?.applicant?.id,
      programTypeId: program?.type?.id,
      mccAccount: application?.mccAccount,
      applicant: {
        ...application.applicant,
        position: {
          ...application.applicant?.position,
          programSponsor: {
            ...application.applicant?.position?.programSponsor,
            id: application.applicant?.position?.programSponsor?.id || currentUser?.preferredProgramSponsorId,
          },
        },
      },
      militaryInstallationId: application?.applicant?.position?.militaryInstallationId,
    });
    // Note: this should be separate, otherwise form will not set address values
    form.setFieldsValue({ homeAddress: application?.applicant?.homeAddress });
  }, [application, currentUser?.preferredProgramSponsorId, form, loading, program?.type?.id]);

  return (
    <div className="white-box h-full">
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        onValuesChange={({ applicant, applicantId }) => {
          if (applicant?.position?.programSponsor?.id) {
            form.setFieldValue(['applicant', 'position', 'militaryComponent', 'id'], null);
            form.setFieldValue(['applicant', 'position', 'militaryStatus', 'id'], null);
          } else if (applicant?.position?.militaryComponent?.id) {
            form.setFieldValue(['applicant', 'position', 'militaryStatus', 'id'], null);
          }
          if (applicantId) {
            form.setFieldsValue({
              programTypeId: null,
              eligibilityAcknowledgmentStatus: false,
            });
          }
        }}
        preserve
      >
        {(values) => {
          return (
            <>
              {isFamily ? (
                <FamilyInfoCard application={application} />
              ) : (
                <>
                  <Section
                    heading="Sponsors (Service Member) Contact information"
                    testId="military-section"
                    contentClassName=""
                  >
                    <Form.Item name={['applicant', 'position', 'id']} hidden>
                      <InputNumber />
                    </Form.Item>
                    <div className="w-full grid grid-rows-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 section-px -mt-2 pb-2 border-b-2">
                      <div>
                        <Form.Item
                          name={['applicant', 'position', 'programSponsor', 'id']}
                          label="Branch"
                          rules={[{ required: true, message: 'Branch is required' }]}
                        >
                          <AsyncSelect
                            apiPrefix="/common"
                            optionsApiUrl="/program-sponsors"
                            placeholder="Select"
                            ariaLabel="Select Branch"
                            onChangeFormatter={(v) => v.id}
                            isOptionSelected={(option, [value]) => option.id === value}
                          />
                        </Form.Item>
                      </div>

                      <div>
                        <Form.Item
                          name={['applicant', 'position', 'militaryComponent', 'id']}
                          label="Component"
                          rules={[{ required: true, message: 'Component is required' }]}
                        >
                          <AsyncSelect
                            key={programSponsorId}
                            getOptions={commonService.get}
                            apiPrefix="/common"
                            optionsApiUrl={
                              programSponsorId
                                ? `/program-types/family/components?branchId=${programSponsorId}`
                                : `/program-types/family/components`
                            }
                            placeholder="Select"
                            ariaLabel="Select Component"
                            onChangeFormatter={(v) => v.id}
                            isOptionSelected={(option, [value]) => option.id === value}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          name={['applicant', 'position', 'militaryStatus', 'id']}
                          label="Status"
                          rules={[{ required: true, message: 'Status is required' }]}
                        >
                          <AsyncSelect
                            key={`${programSponsorId}-${militaryComponentId}`}
                            getOptions={commonService.get}
                            apiPrefix="/common"
                            optionsApiUrl={
                              programSponsorId && militaryComponentId
                                ? `/program-types/family/statuses/by-component-branch?programSponsorId=${programSponsorId}&componentId=${militaryComponentId}`
                                : `/program-types/family/statuses`
                            }
                            placeholder="Select"
                            ariaLabel="Select Status"
                            onChangeFormatter={(v) => v.id}
                            isOptionSelected={(option, [value]) => option.id === value}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          name={['applicant', 'position', 'grade']}
                          label="Military grade"
                          rules={[{ required: true, message: 'Military grade is required' }]}
                        >
                          <AsyncSelect
                            getOptions={familyService.getOptions}
                            apiPrefix=""
                            optionsApiUrl="/options/3"
                            placeholder="Grade"
                            ariaLabel="Select Grade"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          name={['applicant', 'position', 'recruiterType']}
                          label="Recruiter Type (If Applicable)"
                        >
                          <AsyncSelect
                            getOptions={familyService.getOptions}
                            apiPrefix=""
                            optionsApiUrl="/options/4"
                            placeholder="Recruiter Type"
                            ariaLabel="Recruiter Type"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          name={['applicant', 'maritalStatus']}
                          label="Marital Status"
                          rules={[{ required: true, message: 'Marital Status is required' }]}
                        >
                          <AsyncSelect
                            getOptions={familyService.getOptions}
                            apiPrefix=""
                            optionsApiUrl="/options/6"
                            placeholder="Marital Status"
                            ariaLabel="Marital Status"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <ContactInfo prefixName={prefixName} isWorkEmailRequired className="section-px -mt-2 pb-2 py-8" />
                  </Section>
                  {/* show commander section for "Weekend Drill (id: 13)" */}
                  {militaryStatusId === 13 && (
                    <CommanderInfo
                      heading="Sponsor's Commander Information"
                      prefixName={['applicant']}
                      infoText={<span>Please add commander and unit information to application </span>}
                    />
                  )}
                  <Section heading="Sponsors (Service Member) Current Home Address" testId="home-address-section">
                    <AddressFields addressKey="homeAddress" id="homeAddress" />
                  </Section>
                  <Form.Item name="militaryInstallationId" hidden>
                    <InputNumber />
                  </Form.Item>
                  {programSponsorId && (
                    <Section heading="Sponsor Installation">
                      <div className="[&_.ant-table]:!min-h-[100px]">
                        <Form.Item name={militaryInstallationIdFieldName}>
                          <SponsorInstallationModal
                            visible={visibleInstallationModal}
                            setVisible={setVisibleInstallationModal}
                            parentForm={form}
                            programSponsorId={programSponsorId}
                            buttonClassName="mb-6 w-1/3"
                            onChange={(militaryInstallationId) => {
                              form.setFieldValue('militaryInstallationId', militaryInstallationId);
                            }}
                          />
                        </Form.Item>
                      </div>
                    </Section>
                  )}
                </>
              )}
            </>
          );
        }}
      </Form>
    </div>
  );
}

const FamilyInfoCard = ({ application }) => {
  const _fullName = [
    (application?.applicant?.prefix?.title || '').toLowerCase() !== 'prefer not to say'
      ? application?.applicant?.prefix?.title
      : null,
    getFullName(application?.applicant) || 'N/A',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <Card title={_fullName}>
      <ApplicationEntityReview entity={application.applicant} description="Application Sponsor" />
    </Card>
  );
};
