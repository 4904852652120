import React, { useCallback, useEffect, useState } from 'react';
import { Alert, message } from 'antd';
import Form from 'components/Form';
import Section from 'components/Section';
import Actions from 'containers/Family/AddApplication/Actions';
import { ADD_FAMILY_STEPS } from 'constants/index';
import UploadFile, { UploadFileEntityTypes } from 'components/UploadFile/UploadFile';
import { FAMILY_FILE_TYPES } from 'services/family.service';
import { familyService } from 'services';
import Card from 'components/Card/Card';

export default function Documents({ id, application, next, setStep, dispatch, actions }) {
  const [form] = Form.useForm();

  const onSubmit = useCallback(
    async (values) => {
      try {
        next({
          paths: { step: ADD_FAMILY_STEPS.SPONSOR, id: application?.id },
        });
      } catch (error) {
        message.error(`Unable to update Sponsor documents.`);
        newrelic.noticeError(error);
      } finally {
        dispatch(actions.setIsFormSubmitting(false));
      }
    },
    [actions, application, dispatch, next],
  );

  const [applicationFiles, setApplicationFiles] = useState(null);
  useEffect(() => {
    familyService
      .getApplicationFiles(id)
      .then((data) => {
        setApplicationFiles(data);
      })
      .catch((error) => {
        console.error('Error fetching application files', error);
      });
  }, [id]);

  return (
    <div>
      <Form onFinish={onSubmit} form={form} layout="vertical">
        <Card noBodyPadding className="mt-8">
          <Section
            collapsible={false}
            className="mb-6"
            contentClassName="px-6"
            heading={
              <>
                MILITARY ORDERS <span className="text-gray-500 normal-case">(if applicable)</span>
              </>
            }
          >
            <p className="mb-4">Upload orders in these specific situations (not required for all service members):</p>

            <ul className="list-disc pl-6">
              <li>
                <strong className="font-bold">Guard/Reserve members:</strong> Upload activation orders showing Title
                10/32 status
              </li>
              <li>
                <strong className="font-bold">Recently deployed/deploying:</strong> Upload deployment orders with
                operation code
              </li>
              <li>
                <strong className="font-bold">Recruiters:</strong> Upload orders or command memo verifying recruiter
                status
              </li>
              <li>
                <strong className="font-bold">PCS within 90 days:</strong> Upload PCS orders showing new duty station
              </li>
            </ul>

            <Alert
              message="COMMON REJECTION REASONS"
              description={
                <ul className="list-disc pl-6">
                  <li>Expired orders (especially for Guard/Reserve)</li>
                  <li>Missing pages from multi-page orders (upload ALL pages)</li>
                  <li>Missing required command signatures</li>
                  <li>Uploading travel vouchers or PCS checklists instead of official orders</li>
                </ul>
              }
              type="warning"
              showIcon
              className="my-6"
            />

            <UploadFile
              entityType={UploadFileEntityTypes.FAMILY}
              fileCode={FAMILY_FILE_TYPES.ORDERS.code}
              files={applicationFiles}
              setFiles={setApplicationFiles}
              applicationId={id}
              className="mt-4"
            />
          </Section>
        </Card>

        <Card noBodyPadding className="mt-8">
          <Section
            collapsible={false}
            className="mb-6"
            contentClassName="px-6"
            heading={
              <>
                CIVILIAN PERSONNEL ACTION FORM <span className="text-gray-500 normal-case">(if applicable)</span>
              </>
            }
          >
            <p className="mb-4">Upload your most recent employment verification form:</p>

            <ul className="list-disc pl-6">
              <li>Must include total annual income or hourly pay rate</li>
              <li>Must be dated within the last calendar year</li>
              <li>
                <b className="font-bold">For Army, Air Force, and Space Force: </b>Upload your SF-50
              </li>
              <li>
                <b className="font-bold">For Marine Corps:</b> Upload your SF-500
              </li>
              <li>
                <b className="font-bold">For Non-Appropriated Funds employees:</b> Upload your DA3434
              </li>
            </ul>

            <Alert
              message="COMMON REJECTION REASONS"
              description={
                <ul className="list-disc pl-6">
                  <li>Your agency code lists an ineligible department</li>
                  <li>
                    Your total annual income or hourly pay rate doesn’t match a recent promotion/demotion based on LES
                  </li>
                </ul>
              }
              type="warning"
              showIcon
              className="my-6"
            />

            <UploadFile
              entityType={UploadFileEntityTypes.FAMILY}
              fileCode={FAMILY_FILE_TYPES.SPONSOR_PAF.code}
              files={applicationFiles}
              setFiles={setApplicationFiles}
              applicationId={id}
              className="mt-4"
            />
          </Section>
        </Card>

        <Card noBodyPadding className="mt-8">
          <Section
            collapsible={false}
            className="mb-6"
            contentClassName="px-6"
            heading={
              <>
                LEAVE AND EARNINGS STATEMENT (LES) <span className="text-red-500 normal-case">(required)</span>
              </>
            }
          >
            <p className="mb-4">ALL service members must upload your most recent LES:</p>

            <ul className="list-disc pl-6">
              <li>Must be dated within 90 days of your childcare start date</li>
              <li>Include ALL pages (typically 2 pages) - many applications are delayed because page 2 is missing</li>
              <li>Must clearly show your current rank and pay information (including BAH and BAS)</li>
              <li>
                <b className="font-bold">For Army only:</b> Special pays (language proficiency, flight pay, etc.) are
                included in income calculations
              </li>
            </ul>

            <Alert
              message="COMMON REJECTION REASONS"
              description={
                <ul className="list-disc pl-6">
                  <li>LES older than 90 days from care start date</li>
                  <li>Missing the second page (which shows required allowance information)</li>
                  <li>Blurry or unreadable document - check before uploading</li>
                  <li>Rank shown doesn't match what you entered on application</li>
                </ul>
              }
              type="warning"
              showIcon
              className="my-6"
            />

            <UploadFile
              entityType={UploadFileEntityTypes.FAMILY}
              fileCode={FAMILY_FILE_TYPES.SPONSOR_LES.code}
              files={applicationFiles}
              setFiles={setApplicationFiles}
              applicationId={id}
              className="mt-4"
            />
          </Section>
        </Card>

        <Card noBodyPadding className="mt-8">
          <Section
            collapsible={false}
            className="mb-6"
            contentClassName="px-6"
            heading={
              <>
                SPECIAL CIRCUMSTANCES DOCUMENTS <span className="text-gray-500 normal-case">(if applicable)</span>
              </>
            }
          >
            <p className="mb-4">Upload if any of these situations apply to you:</p>

            <ul className="list-disc pl-6">
              <li>
                <p className="font-semibold">If child has a split custody situation:</p>

                <ul className="list-[revert] pl-6">
                  <li>Upload your parenting plan and court-ordered legal separation or divorce documents</li>
                  <li>
                    <b className="font-bold">For Army:</b> You must have at least 25% physical custody to be eligible
                  </li>
                  <li>
                    <b className="font-bold">For Air Force/Marine Corps/Space Force:</b> Child must primarily reside
                    with service member
                  </li>
                </ul>
              </li>

              <li>
                <p className="font-semibold">Sponsor wants to exclude income from one of the legal parents:</p>

                <ul className="list-[revert] pl-6">
                  <li>
                    Upload your court-ordered legal separation or divorce documents with sponsor having sole custody of
                    the child
                  </li>
                  <li>
                    Child must reside with the sponsor permanently or during the time fee assistance would be approved
                  </li>
                </ul>
              </li>
            </ul>

            <Alert
              message="HELPFUL TIP"
              description={
                <ul className="list-disc pl-6">
                  <li>These documents help us process your application faster by confirming eligibility</li>
                  <li>If you're not sure if a document applies to you, it's better to upload it than risk delays</li>
                </ul>
              }
              type="info"
              showIcon
              className="my-6"
            />

            <UploadFile
              entityType={UploadFileEntityTypes.FAMILY}
              fileCode={FAMILY_FILE_TYPES.SPONSOR_OTHER.code}
              files={applicationFiles}
              setFiles={setApplicationFiles}
              applicationId={id}
              className="mt-4"
            />
          </Section>
        </Card>

        <Actions onBack={() => setStep(id, ADD_FAMILY_STEPS.PROGRAMS)} testId={`${ADD_FAMILY_STEPS.SPONSOR}`} />
      </Form>
    </div>
  );
}
