import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';

import ProtectedRoute from 'components/ProtectedRoute';
import Card from 'components/Card/Card';
import { getCompletedAddress, getFullName } from 'utils';
import { message, Spin, Tabs, Tag, Typography } from 'antd';
import Space from 'components/Space/Space';
import dayjs from 'dayjs';
import FamilyDocuments from './FamilyDocuments';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useAsync } from 'react-async-hook';
import { familyService } from 'services';
import { ENDASH } from 'constants';
import { FamilyApplicationFamilySummary } from './FamilyApplicationFamilySummary';
import { FamilyApplicationDetails } from './FamilyApplicationDetails';

export default function FamilyApplication({ match }) {
  const history = useHistory();
  const { householdId, id } = match.params;

  const [application, setApplication] = React.useState(null);
  useAsync(async () => {
    try {
      const _application = await familyService.getApplication(id);
      setApplication(_application);
    } catch (error) {
      console.error('Error fetching application', error);
      message.error('Error fetching application');
    }
  });

  const navItems = useMemo(
    () => [
      ...(application
        ? [
            {
              label: 'Application details',
              key: 'application-details',
              navigationKey: [''],
              regex: /^\/families\/\d+\/applications\/\d+$/,
              to: `/families/${householdId}/applications/${application?.id}`,
            },
            {
              label: 'Family summary',
              key: 'family-summary',
              navigationKey: [''],
              regex: /^\/families\/\d+\/applications\/\d+\/family$/,
              to: `/families/${householdId}/applications/${application?.id}/family`,
            },
          ]
        : [
            {
              label: 'Applications',
              key: 'applications',
              navigationKey: ['applications'],
              regex: /^\/families\/\d+$|\/families\/\d+\/applications$/,
              to: `/families/${householdId}/applications`,
            },
          ]),
      {
        label: 'Documents',
        key: 'documents',
        navigationKey: ['documents'],
        regex: /^\/families\/\d+\/applications\/\d+\/documents$/,
        to: `/families/${householdId}/applications/${application?.id}/documents`,
      },
    ],
    [application, householdId],
  );

  if (!application) {
    return <Spin />;
  }

  return (
    <div>
      <div className="mb-10">
        <Typography.Title level={2} className="!mb-2">
          {application?.applicant?.lastName} Family Application
        </Typography.Title>

        <div className="flex items-center flex-wrap gap-12 font-medium">
          <p>
            <Typography.Text type="secondary">Application ID:</Typography.Text> {application?.id}
          </p>
          <p>
            <Typography.Text type="secondary">Submitted:</Typography.Text> {application?.submittedDate || ENDASH}
          </p>
          <p>
            <Typography.Text type="secondary">Program:</Typography.Text> {application?.programType?.title || ENDASH}
          </p>
        </div>

        <div className="flex flex-wrap gap-6 mt-8">
          <Card title="Sponsor" className="w-full sm:w-[calc(50%-1.5rem)] lg:w-[calc(33.3333%-1rem)]">
            <Space className="mb-2">
              {getFullName(application?.applicant)}

              <Tag color="processing">{application?.applicant?.position?.militaryStatus?.title}</Tag>
            </Space>

            <div>
              <Typography.Text type="secondary">
                {application?.applicant?.position?.programSponsor?.title} -{' '}
                {application?.applicant?.position?.grade?.title}
              </Typography.Text>
            </div>

            <div>
              <Typography.Text type="secondary">DOB: {application?.applicant?.dateOfBirth}</Typography.Text>
            </div>
          </Card>

          <Card title="Family Info" className="w-full sm:w-[calc(50%-1.5rem)] lg:w-[calc(33.3333%-1rem)]">
            {application?.additionalParents?.length === 0 && application?.children?.length === 0 && ENDASH}

            {application?.additionalParents?.map((_parent) => {
              return (
                <div key={_parent.id}>
                  <Typography.Text className="font-medium text-lg">{getFullName(_parent)}</Typography.Text>
                </div>
              );
            })}

            {application?.children?.map((_child) => {
              const _age = dayjs().diff(dayjs(_child.dateOfBirth), 'year');

              return (
                <div key={_child.id}>
                  <Typography.Text type="secondary">
                    {getFullName(_child)} (Child, {_age} {_age === 1 ? 'yr' : 'yrs'})
                  </Typography.Text>

                  <div>
                    <Typography.Text type="secondary">{getCompletedAddress(_child.homeAddress)}</Typography.Text>
                  </div>
                </div>
              );
            })}
          </Card>

          <Card title="Application Status" className="w-full sm:w-[calc(50%-1.5rem)] lg:w-[calc(33.3333%-1rem)]">
            <Typography.Text type="secondary">
              <span className="font-semibold">Status:</span> <Tag>{application?.status?.status?.description}</Tag>
            </Typography.Text>

            {application?.assignedTo && (
              <div>
                <Typography.Text type="secondary">
                  <span className="font-semibold">Assigned to:</span> {getFullName(application?.assignedTo)}
                </Typography.Text>
              </div>
            )}
          </Card>
        </div>
      </div>

      <Tabs
        items={navItems}
        activeKey={navItems.find((item) => item.regex.test(window.location.pathname))?.key}
        onChange={(newValue) => {
          const target = navItems.find((item) => item.key === newValue);

          if (target) {
            history.push(target.to);
          }
        }}
      />

      <Switch>
        <ProtectedRoute
          exact
          path="/families/:householdId/applications/:id"
          message="Navigated to Application Overview Page"
        >
          <FamilyApplicationDetails application={application} />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path="/families/:householdId/applications/:id/family"
          message="Navigated to Application Family Summary Page"
        >
          <FamilyApplicationFamilySummary application={application} />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path="/families/:householdId/applications/:id/documents"
          message="Navigated to Application Documents Page"
        >
          <FamilyDocuments application={application} />
        </ProtectedRoute>

        {/* <ProtectedRoute
          exact
          path={['/families/:householdId/applications/:id/providers']}
          message="Navigated to Providers Page"
        >
          <Page>Providers</Page>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={['/families/:householdId/applications/:id/children']}
          message="Navigated to Children Page"
        >
          <Page>Children</Page>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={['/families/:householdId/applications/:id/notes']}
          message="Navigated to Notes Page"
        >
          <Page>Notes</Page>
        </ProtectedRoute>
        <ProtectedRoute path="/families/:householdId/applications/:id/documents" message="Navigated to Documents Page">
          <DocumentsReview breakpoints={breakpoints} entityType={ENTITY_TYPES.FAMILY} />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={['/families/:householdId/applications/:id/profile']}
          message="Navigated to Profile Page"
        >
          <Page>Family Profile</Page>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={['/families/:householdId/applications/:id/messages']}
          message="Navigated to Messages Page"
        >
          <ApplicationEmails className="px-6 py-6" entityType={ENTITY_TYPES.FAMILY} entity={application} />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={['/families/:householdId/applications/:id/alerts']}
          message="Navigated to Alerts Page"
        >
          <Alerts className="px-6 py-6" entityType={ENTITY_TYPES.FAMILY} entityId={id} />
        </ProtectedRoute>
        <ProtectedRoute
          // exact
          path={['/families/:householdId/applications/:id/review']}
          message="Navigated to Family Application Page"
        >
          <FamilyApplicationReview rootRef={rootRef} />
        </ProtectedRoute>
        <Redirect to="/families/:householdId/applications/:id/review" /> */}
      </Switch>
    </div>
  );
}

// function ApplicationHome({ rootRef, history, match, loading }) {
//   const { id, householdId } = match.params;
//   const application = useSelector(selectors.selectApplication);
//   const isFormSubmitting = useSelector(selectors.selectIsFormSubmitting);
//   const status = useMemo(() => application?.status, [application?.status]);
//   const { contentRef } = useScrollLock({
//     topLimit: 90,
//     rootRef,
//   });

//   const [checklistOpen, setChecklistOpen] = React.useState(false);

//   return (
//     <div>
//       <div className="mt-12">
//         <div ref={contentRef}></div>
//         <div className="flex flex-col justify-center space-y-3 md:space-y-0 md:flex-row mb-5">
//           <div className="flex-grow">
//             <h3 className="text-xl">Family Application Dashboard</h3>
//           </div>

//           <div className="actions">
//             <Button
//               icon={<CheckCircleOutlined />}
//               onClick={() => {
//                 setChecklistOpen(true);
//               }}
//             >
//               Checklist
//             </Button>

//             <Button
//               type="primary"
//               onClick={() => history.push(`/families/${householdId}/applications/${id}/review`)}
//               data-testid="application-review-btn"
//             >
//               Review
//             </Button>
//           </div>
//         </div>

//         <CollapsePanel
//           header={
//             <div className="px-4 py-4 bg-white flex items-center">
//               <h4 className="text-base text-primary font-semibold uppercase">My Application Status</h4>
//             </div>
//           }
//           collapseClassName="application-status-collapse"
//         >
//           <ApplicationStatusCard
//             status={status}
//             loading={loading}
//             getPublicStatusList={familyService.getPublicStatusList}
//           />
//         </CollapsePanel>

//         <div className="block mt-6">
//           <CaseCoordinatorActivity
//             entity={application}
//             entityType={ENTITY_TYPES.FAMILY}
//             collapseHeaderClassName="py-4"
//           />
//         </div>
//       </div>

//       <Drawer
//         headerStyle={{ display: 'none' }}
//         bodyStyle={{ padding: 0 }}
//         open={checklistOpen}
//         onClose={() => {
//           setChecklistOpen(false);
//         }}
//       >
//         <FamilyChecklist application={application} id={id} className="py-4" isFormSubmitting={isFormSubmitting} />
//       </Drawer>
//     </div>
//   );
// }
