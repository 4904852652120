import React, { useEffect } from 'react';
import { Input, Button, message, Modal, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import { useAsyncCallback } from 'react-async-hook';
import toLower from 'lodash/toLower';
import { actions, selectActiveRole } from 'features/auth';
import userService from 'services/users.service';
import { selectors } from 'features/auth';
import { passwordValidator } from 'utils';
import Form from 'components/Form';
import FormField from 'components/Authentication/FormField';
import Space, { Spacer } from 'components/Space/Space';
import { ROLES } from 'constants';

export default function ChangeUserName({ setVisible }) {
  const currentUser = useSelector(selectors.selectUser);
  const activeRole = useSelector(selectActiveRole);
  const isInternalUser = ![ROLES.FAMILY, ROLES.PROVIDER].includes(activeRole);

  const dispatch = useDispatch();
  const history = useHistory();

  const onConfirmation = () => {
    dispatch(actions.logout.pending());
    history.push('/');
  };

  const changeUsername = useAsyncCallback(async (values) => {
    return Auth.signIn(currentUser.email, values.password)
      .then(() =>
        userService
          .changeUserName({ currentEmail: currentUser.email, newEmail: toLower(values.newEmail) })
          .then(async () => {
            setVisible(false);
            Modal.success({
              title: 'Please check your email',
              content: (
                <div>We've sent a temporary password to your email address. Please re-login using that password.</div>
              ),
              onOk: () => onConfirmation(),
              onCancel: () => onConfirmation(),
            });
          })
          .catch((err) => {
            message.error(err?.apierror?.message || 'Something went wrong.');
          }),
      )
      .catch((err) => {
        message.error('Password is incorrect.');
      });
  });

  // Focus on password input
  const passwordInputRef = React.useRef(null);
  useEffect(() => {
    if (passwordInputRef?.current) {
      passwordInputRef.current.focus?.();
    }
  }, [passwordInputRef]);

  if (isInternalUser) {
    return <div className="m-7">Internal users cannot change their email address</div>;
  }

  return (
    <Form onFinish={changeUsername.execute} data-testid="change-username-form" layout="vertical">
      <div className="m-7 mb-12">
        <FormField
          label="Current password"
          name="password"
          rules={[{ required: true, message: 'Password is required' }, passwordValidator]}
          className="max-w-[800px]"
        >
          <Input.Password type="password" placeholder="Password" ref={passwordInputRef} />
        </FormField>

        <FormField
          label="New email address"
          name="newEmail"
          rules={[
            { required: true, message: 'Email address is required' },
            { type: 'email', message: 'Email address is invalid' },
            () => ({
              validator(rule, value) {
                if (value && toLower(value) === toLower(currentUser?.email)) {
                  return Promise.reject('New email address cannot be the same as the current one');
                } else return Promise.resolve();
              },
            }),
          ]}
          className="max-w-[800px]"
        >
          <Input placeholder="New email address" />
        </FormField>
      </div>

      <Divider className="mb-0" />

      <Space className="my-4 mx-8">
        <Spacer />

        <Button loading={changeUsername.loading} disabled={changeUsername.loading} type="primary" htmlType="submit">
          Submit
        </Button>
      </Space>
    </Form>
  );
}
