import React, { useState } from 'react';

import Card from 'components/Card/Card';
import TabularList from 'components/TabularList/TabularList';
import { getFormattedPhoneNumber } from 'utils';
import { message, Spin, Typography } from 'antd';
import { Events, User, UserMultiple } from '@carbon/icons-react';
import Space from 'components/Space/Space';
import FormattedAddress from 'components/FormattedAddress/FormattedAddress';
import { useAsync } from 'react-async-hook';
import { commonService, familyService } from 'services';
import { FileList } from 'components/FileList/FileList';
import { FAMILY_FILE_TYPES } from 'services/family.service';
import { startCase } from 'lodash-es';
import dayjs from 'dayjs';
import { DATE_FORMAT_US_SLASH } from 'components/AriaDatePicker/AriaDatePicker';
import classNames from 'classnames';

export function FamilyApplicationDetails({ application }) {
  const [documents, setDocuments] = useState(null);
  useAsync(async () => {
    if (application?.id !== undefined) {
      const _documents = await familyService.getApplicationFiles(application?.id);
      setDocuments(_documents);
    }
  }, [application]);

  const { result: militaryComponentOptions = [] } = useAsync(
    async (programSponsorId) => {
      try {
        if (programSponsorId) {
          return await commonService.get(`common/program-types/family/components?branchId=${programSponsorId}`, {
            cache: { interpretHeader: false },
          });
        }
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Components not found.', 5);
        return [];
      }
    },
    [application?.applicant?.position?.programSponsor?.id],
  );
  const militaryComponent = militaryComponentOptions.find(
    (component) => component.id === application?.applicant?.position?.militaryComponentId,
  );

  const _spouse = application?.additionalParents?.[0];

  return (
    <Card title="Application details">
      <Space className="mb-6">
        <User size={22} />
        <Typography.Title level={4} className="!m-0">
          Sponsor information
        </Typography.Title>
      </Space>

      <TabularList
        data={[
          {
            title: 'Sole parent',
            value: application?.applicant?.singleParent ? 'Yes' : 'No',
            titleRequired: true,
          },
          {
            title: 'Status',
            value:
              [
                {
                  title: 'Fallen Warrior',
                  value: application?.applicant?.position?.fallenWarrior,
                },
                {
                  title: 'Wounded Warrior',
                  value: application?.applicant?.position?.woundedWarrior,
                },
                {
                  title: 'Deployed',
                  value: application?.applicant?.position?.deployed,
                },
              ]
                .filter((item) => item.value)
                .map((item) => item.title)
                .join(', ') ||
              application?.applicant?.position?.militaryStatus?.title ||
              undefined,
            titleRequired: true,
          },
          {
            title: 'Branch',
            value: application?.applicant?.position?.programSponsor?.title,
            titleRequired: true,
          },
          {
            title: 'Program type',
            value: application?.programType?.title,
            titleRequired: true,
          },
          {
            title: 'Last name',
            value: application?.applicant?.lastName,
            titleRequired: true,
          },
          {
            title: 'Middle name',
            value: application?.applicant?.middleName,
          },
          {
            title: 'First name',
            value: application?.applicant?.firstName,
            titleRequired: true,
          },
          {
            title: 'Grade',
            value: application?.applicant?.position?.grade?.title,
            titleRequired: true,
          },
          {
            title: 'Primary phone',
            value: `${getFormattedPhoneNumber(application?.applicant?.phone)}${
              application?.applicant?.phoneExt ? ` x${application?.applicant?.phoneExt}` : ''
            }`,
            titleRequired: true,
          },
          {
            title: 'Email',
            value: application?.applicant?.email,
            titleRequired: true,
          },
          {
            title: 'Address',
            value: (
              <FormattedAddress address={application?.applicant?.homeAddress || application?.applicant?.workAddress} />
            ),
            titleRequired: true,
          },
          {
            title: 'Recruiter type',
            value: application?.applicant?.position?.recruiterType?.title,
            titleRequired: true,
          },
          {
            title: 'Component',
            value: militaryComponent?.title,
            titleRequired: true,
          },
          {
            title: 'Installation',
            value: application?.applicant?.position?.installation?.name,
            titleRequired: true,
          },
          {
            title: 'Sponsor orders',
            value:
              documents === null ? (
                <Spin />
              ) : (
                <FileList files={documents?.filter((file) => file.fileType === FAMILY_FILE_TYPES.ORDERS.code)} />
              ),
            titleRequired: true,
          },
          {
            title: 'Sponsor income',
            value:
              documents === null ? (
                <Spin />
              ) : (
                <FileList files={documents?.filter((file) => file.fileType === FAMILY_FILE_TYPES.SPONSOR_LES.code)} />
              ),
            titleRequired: true,
          },
          {
            title: 'Other sponsor docs',
            value:
              documents === null ? (
                <Spin />
              ) : (
                <FileList files={documents?.filter((file) => file.fileType === FAMILY_FILE_TYPES.SPONSOR_OTHER.code)} />
              ),
            titleRequired: true,
          },
        ]}
      />

      <Space className="mt-12 mb-6">
        <UserMultiple size={22} />
        <Typography.Title level={4} className="!m-0">
          Spouse information
        </Typography.Title>
      </Space>

      {!_spouse ? (
        <Typography.Text type="secondary">No spouse</Typography.Text>
      ) : (
        <TabularList
          data={[
            {
              title: 'Employment status',
              value: _spouse?.employmentStatus,
              titleRequired: true,
            },
            {
              title: 'Last name',
              value: _spouse?.lastName,
              titleRequired: true,
            },
            {
              title: 'Middle name',
              value: _spouse?.middleName,
            },
            {
              title: 'First name',
              value: _spouse?.firstName,
              titleRequired: true,
            },
            {
              title: 'Primary phone',
              value: `${getFormattedPhoneNumber(_spouse?.phone)}${_spouse?.phoneExt ? ` x${_spouse?.phoneExt}` : ''}`,
              titleRequired: true,
            },
            {
              title: 'Address',
              value: <FormattedAddress address={_spouse?.homeAddress || _spouse?.workAddress} />,
              titleRequired: true,
            },
            {
              title: 'Email',
              value: _spouse?.email,
              titleRequired: true,
            },
            {
              title: 'Looking for work',
              value:
                documents === null ? (
                  <Spin />
                ) : (
                  <FileList
                    files={documents?.filter(
                      (file) => file.fileType === FAMILY_FILE_TYPES.SPOUSE_LOOKING_FOR_WORK.code,
                    )}
                  />
                ),
              titleRequired: true,
            },
            {
              title: 'Schooling schedule',
              value:
                documents === null ? (
                  <Spin />
                ) : (
                  <FileList
                    files={documents?.filter((file) => file.fileType === FAMILY_FILE_TYPES.SPOUSE_SCHOOL.code)}
                  />
                ),
              titleRequired: true,
            },
            {
              title: 'Spouse income',
              value:
                documents === null ? (
                  <Spin />
                ) : (
                  <FileList
                    files={documents?.filter((file) => file.fileType === FAMILY_FILE_TYPES.SPOUSE_PAYSTUB.code)}
                  />
                ),
              titleRequired: true,
            },
            {
              title: 'Spouse employment',
              value:
                documents === null ? (
                  <Spin />
                ) : (
                  <FileList files={documents?.filter((file) => file.fileType === FAMILY_FILE_TYPES.SPOUSE_WORK.code)} />
                ),
              titleRequired: true,
            },
            {
              title: 'Other spouse documents',
              value:
                documents === null ? (
                  <Spin />
                ) : (
                  <FileList
                    files={documents?.filter((file) => file.fileType === FAMILY_FILE_TYPES.SPOUSE_OTHER.code)}
                  />
                ),
              titleRequired: true,
            },
          ]}
        />
      )}

      <Space className="mt-12 mb-6">
        <Events size={22} />
        <Typography.Title level={4} className="!m-0">
          Child information
        </Typography.Title>
      </Space>

      {application?.children?.length === 0 ? (
        <Typography.Text type="secondary">No children</Typography.Text>
      ) : (
        application?.children.map((_child, index) => (
          <>
            {application?.children.length > 1 && (
              <p className={classNames('text-xl font-bold mb-4', { 'mt-12': index !== 0 })}>Child #{index + 1}</p>
            )}

            <TabularList
              key={_child.id}
              data={[
                {
                  title: 'Last name',
                  value: _child?.lastName,
                  titleRequired: true,
                },
                {
                  title: 'Middle name',
                  value: _child?.middleName,
                  titleRequired: true,
                },
                {
                  title: 'First name',
                  value: _child?.firstName,
                  titleRequired: true,
                },
                {
                  title: 'Date of birth',
                  value: _child.dateOfBirth ? dayjs(_child.dateOfBirth).format(DATE_FORMAT_US_SLASH) : undefined,
                  titleRequired: true,
                },
                {
                  title: 'Sex',
                  value: _child.gender ? startCase(_child.gender?.toLowerCase?.()) : undefined,
                  titleRequired: true,
                },
                {
                  title: 'Statement of non-availability',
                  value:
                    documents === null ? (
                      <Spin />
                    ) : (
                      <FileList
                        files={documents?.filter((file) => file.fileType === FAMILY_FILE_TYPES.CHILD_CYS.code)}
                      />
                    ),
                  titleRequired: true,
                },
                {
                  title: 'Provider cost verification form',
                  value:
                    documents === null ? (
                      <Spin />
                    ) : (
                      <FileList
                        files={documents?.filter((file) => file.fileType === FAMILY_FILE_TYPES.PROVIDER_RATES.code)}
                      />
                    ),
                  titleRequired: true,
                },
                {
                  title: 'Other child documents',
                  value:
                    documents === null ? (
                      <Spin />
                    ) : (
                      <FileList
                        files={documents?.filter((file) => file.fileType === FAMILY_FILE_TYPES.CHILD_OTHER.code)}
                      />
                    ),
                  titleRequired: true,
                },
              ]}
            />
          </>
        ))
      )}
    </Card>
  );
}
