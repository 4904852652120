import React from 'react';
import { Button, Typography, Divider } from 'antd';
import Modal from 'components/Modal';

const ResetPasswordModal = ({ visible, user, onCancel, onConfirm, loading }) => {
  return (
    <Modal
      visible={visible}
      setVisible={onCancel}
      destroyOnClose
      title={
        <Typography.Title level={4} className="!m-0">
          Reset Password
        </Typography.Title>
      }
      className="flex flex-row"
    >
      <div>
        Are you sure you want to initiate a password reset for &nbsp;
        <span className="font-bold">{user?.username}</span>? They will receive an email with instructions to reset their
        password.
      </div>
      <Divider />
      <div className="flex justify-end items-center">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={onConfirm} loading={loading} className="ml-6">
          Reset Password
        </Button>
      </div>
    </Modal>
  );
};

export default ResetPasswordModal;
