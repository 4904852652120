import { Email, Reset } from '@carbon/icons-react';
import { Button, Input, message, Tooltip } from 'antd';
import classNames from 'classnames';
import Card from 'components/Card/Card';
import Table from 'components/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { commonService, userService } from 'services';
import { getFullName, isResetPasswordAllowedForUser } from 'utils';
import { USER_STATUSES } from '../Users';
import useAsyncFn from 'hooks/useAsyncFn';
import PageMeta from 'components/PageMeta/PageMeta';
import { useUrlParams } from 'hooks/useUrlParams';
import ResetPasswordModal from './ResetPasswordModal';

const PAGE_SIZE = 10;

const { Search } = Input;

export function FamilyUsers() {
  const { get: getUrlParams, update: updateUrlParams } = useUrlParams();
  const urlParamsSnapshot = getUrlParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const state = useMemo(() => {
    return {
      pagination: {
        current: urlParamsSnapshot?.current !== undefined ? urlParamsSnapshot?.current : 1,
        pageSize: urlParamsSnapshot?.pageSize !== undefined ? urlParamsSnapshot?.pageSize : PAGE_SIZE,
        showSizeChanger: true,
        hideOnSinglePage: false,
        total: data?.totalElements,
      },
      query: urlParamsSnapshot?.query || '',
    };
  }, [data, urlParamsSnapshot]);

  // Password reset
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [resetPasswordUser, setResetPasswordUser] = useState(null);

  const fetchData = useCallback(
    (configs) => {
      const { params: configParams } = configs || {};

      setLoading(true);

      const _searchQuery = getUrlParams()?.query;
      commonService
        .get('/users/admin/families/search', { params: { query: _searchQuery || '', ...configParams } })
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          console.log('Error fetching applications', error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [getUrlParams],
  );

  useEffect(() => {
    const _urlParams = getUrlParams();
    if (!_urlParams.sort) {
      updateUrlParams({
        sort: 'firstName,ascend',
      });
    }

    fetchData({
      params: {
        current: _urlParams?.current || 1,
        size: _urlParams?.pageSize || PAGE_SIZE,
        sort: _urlParams?.sort
          ? `${_urlParams.sort[0]},${_urlParams.sort[1] === 'ascend' ? 'asc' : 'desc'}`
          : 'firstName,asc',
        query: _urlParams?.query || '',
      },
    });
  }, [fetchData, getUrlParams, updateUrlParams]);

  const onChange = useCallback(
    (pagination, filters, sorter) => {
      const _params = { query: state?.query };
      if (sorter.field !== undefined) {
        if (sorter.field === 'name') {
          _params.sort = sorter.order === 'ascend' ? 'firstName,asc' : 'firstName,desc';
        } else {
          _params.sort = sorter.order === 'ascend' ? `${sorter.field},asc` : `${sorter.field},desc`;
        }
      }

      // Update query params
      updateUrlParams({
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        sort:
          sorter?.columnKey === undefined
            ? null
            : `${sorter?.columnKey === 'name' ? 'firstName' : sorter?.columnKey},${sorter?.order}`,
      });

      fetchData({
        params: {
          ..._params,
          current: pagination?.current,
          size: pagination?.pageSize,
        },
      });
    },
    [fetchData, state?.query, updateUrlParams],
  );

  const [{ loading: sendingEmail }, resendEmail] = useAsyncFn(async (_email) => {
    try {
      await userService.resendConfirmationEmail({ username: _email });
      message.success('Confirmation email has been sent again', 5);
    } catch (error) {
      message.error(error?.apierror?.message || error?.message || 'Unable to resend confirmation email', 5);
      newrelic.noticeError(error);
      console.log('Error resending confirmation email', error);
    }
  }, []);

  const resetPassword = useCallback(async (user) => {
    try {
      setLoading(true);
      await userService.resetPassword(user.username);
      message.success('Password reset link has been sent through email', 5);
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to reset password', 3);
      console.log('Error resetting password', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const initiateUserResetPassword = (userRecord) => {
    setResetPasswordUser(userRecord);
    setShowResetPasswordModal(true);
  };

  const triggerUserResetPassword = () => {
    resetPassword(resetPasswordUser);
    setShowResetPasswordModal(false);
  };

  const allColumns = [
    {
      title: 'ID',
      skip: true,
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      sortOrder: urlParamsSnapshot?.sort?.[0] === 'id' ? urlParamsSnapshot?.sort?.[1] : undefined,
      width: 100,
    },
    {
      title: 'Name',
      skip: true,
      headerText: 'Name',
      dataIndex: 'name',
      sorter: true,
      sortOrder: urlParamsSnapshot?.sort?.[0] === 'firstName' ? urlParamsSnapshot?.sort?.[1] : undefined,
      key: 'name',
      render: (_value, record) => {
        return <span className="font-medium text-md">{getFullName(record)}</span>;
      },
      width: 250,
    },
    {
      title: 'Email',
      headerText: 'Email',
      dataIndex: ['username'],
      sorter: true,
      sortOrder: urlParamsSnapshot?.sort?.[0] === 'username' ? urlParamsSnapshot?.sort?.[1] : undefined,
      key: 'username',
      width: 200,
      render: (username) => {
        return <span className="font-medium">{username}</span>;
      },
    },

    {
      title: 'Roles',
      skip: true,
      headerText: 'Roles',
      dataIndex: ['roles'],
      key: 'roles',
      className: 'truncate max-w-xs',
      render: (roles, record) => {
        roles = roles.join(', ');
        if (!roles.includes('Family') && record?.userType === 'Family') {
          roles = `Family${roles ? `, ${roles}` : ''}`;
        }

        return <span title={roles}>{roles || 'NA'}</span>;
      },
      width: 160,
    },
    {
      title: 'Actions',
      headerText: 'Actions',
      key: 'actions',
      width: 100,
      maxWidth: 100,
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <div className="flex space-x-2 items-center">
          <Tooltip
            title={isResetPasswordAllowedForUser(record) ? 'Reset password' : 'User must first confirm their account'}
          >
            <Button
              icon={<Reset />}
              onClick={() => initiateUserResetPassword(record)}
              aria-label="Reset password"
              disabled={!isResetPasswordAllowedForUser(record)}
            />
          </Tooltip>
          <Tooltip title="Resend verification email">
            <Button
              icon={<Email />}
              className="icon-btn"
              onClick={() => {
                resendEmail(record.username);
              }}
              loading={sendingEmail}
              disabled={record.userStatus !== USER_STATUSES.UNCONFIRMED}
              aria-label="Resend verification email"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <PageMeta title="Family users" />

      <Card
        noBodyPadding
        title={
          <>
            {state?.pagination?.total || 0} family user{(state?.pagination?.total || 0) !== 1 ? 's' : ''}
          </>
        }
        extra={
          <div className="flex flex-row space-x-3">
            <Search
              allowClear
              placeholder="Username/email"
              value={state?.query}
              onChange={(e) => {
                updateUrlParams({ query: e.target.value });
              }}
              onSearch={(value) => {
                // Update query params
                updateUrlParams({ current: 1, query: value?.trim?.() });

                if (value) {
                  fetchData({
                    params: {
                      current: 1,
                      size: state?.pagination?.pageSize,
                      query: value?.trim?.(),
                    },
                  });
                }
              }}
              onClear={() => {
                // Update query params
                updateUrlParams({ current: 1, query: null });

                fetchData({
                  params: {
                    current: 1,
                    size: state?.pagination?.pageSize,
                    query: '',
                  },
                });
              }}
              enterButton
              data-testid="search-input"
              style={{ width: 260 }}
            />
          </div>
        }
      >
        <Table
          showColSeparator={false}
          rowKey="id"
          className={classNames('w-full')}
          allColumns={allColumns}
          loading={loading || loading}
          data={data?.content}
          pagination={state?.pagination}
          onChange={onChange}
        />
      </Card>

      <ResetPasswordModal
        visible={showResetPasswordModal}
        user={resetPasswordUser}
        onCancel={() => setShowResetPasswordModal(false)}
        onConfirm={triggerUserResetPassword}
        loading={loading}
      />
    </div>
  );
}
