import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Menu, message, Popover, Radio, Spin, Tooltip } from 'antd';
import { Edit, Search } from '@carbon/icons-react';
import Space, { Spacer } from 'components/Space/Space';
import { truncate } from 'lodash';
import { providerService } from 'services';
import { EMDASH } from 'constants';

export const UNASSIGNED_ID = 'ASSIGN_TO__UNASSIGNED';

export function DashboardTableAssignedToCell({ fetchData, options, record }) {
  const assignedTo = record?.assignedTo?.fullName;

  const [showPopover, setShowPopover] = React.useState(false);
  const containerRef = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(null);

  // Filter options based on search text
  const filteredOptions = [{ text: 'Unassigned', value: UNASSIGNED_ID }, ...options].filter((filter) =>
    filter.text.toLowerCase().includes(searchText.toLowerCase()),
  );

  // Create Menu items dynamically
  const menuItems = filteredOptions.map((filter) => ({
    key: `${filter.value}`,
    label: (
      <Radio
        checked={
          selectedFilter === filter.value || (filter.value === UNASSIGNED_ID && typeof selectedFilter !== 'number')
        }
        className="pl-3"
      >
        {truncate(filter.text, { length: 27 })}
      </Radio>
    ),
    onClick: () => setSelectedFilter(filter.value),
  }));

  const [savingUpdate, setSavingUpdate] = useState(false);
  const handleApply = () => {
    setShowPopover(false);

    // Determine if we are assigning or un-assigning
    const _promise =
      selectedFilter === UNASSIGNED_ID
        ? providerService.unAssignCoordinator([
            {
              applicationId: record?.id,
            },
          ])
        : providerService.assignCoordinator(
            [
              {
                userId: selectedFilter,
                applicationId: record?.id,
              },
            ],
            selectedFilter,
          );

    _promise
      .then(() => {
        message.success('Done');

        fetchData();
      })
      .catch((error) => {
        console.log(
          selectedFilter === UNASSIGNED_ID ? 'Error unassigning coordinator' : 'Error saving assignedTo value',
          error,
        );
        message.error('Something went wrong');
      })
      .finally(() => {
        setSavingUpdate(false);
      });
  };

  // Close popover on clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        !findPopoverParent(document.querySelector(`#assignedTo-popover-${record?.id}`))?.contains(event.target)
      ) {
        setShowPopover(false);
      }
    };

    if (showPopover) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, record?.id, showPopover]);

  useEffect(() => {
    if (record?.assignedTo?.id) {
      setSelectedFilter(record?.assignedTo?.id);
    }
  }, [record]);

  const searchInputRef = useRef(null);
  useEffect(() => {
    if (showPopover) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 30);
    }
  }, [showPopover]);

  return (
    <div
      className="w-full"
      ref={containerRef} // Attach ref to the container div
      style={{ cursor: 'pointer', display: 'inline-block' }}
    >
      <Spin spinning={savingUpdate} className="w-full">
        <Tooltip title="Click to edit">
          <div
            onClick={() => {
              setShowPopover(!showPopover);
            }}
            className="w-full"
          >
            <Button type="text" className="w-[120%] text-left -ml-4 gap-0" data-testid="assigned-to-selection">
              {truncate(assignedTo || EMDASH, { length: 15 })}

              <Spacer />

              <Edit className="flex-shrink-0 -mr-1" />
            </Button>
          </div>
        </Tooltip>

        <Popover
          styles={{ body: { padding: 0 } }}
          content={
            <div style={{ width: 250 }}>
              <div className="p-2">
                {/* Search Input */}
                <Input
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  prefix={<Search />}
                  ref={searchInputRef}
                />
              </div>

              {/* Menu with Radio-like Selection */}
              <Menu
                inlineIndent={0}
                items={menuItems}
                selectedKeys={typeof selectedFilter === 'number' ? [`${selectedFilter}`] : [UNASSIGNED_ID]}
                className="h-[300px] overflow-y-scroll border-y border-solid border-[#f0f0f0]"
                mode="inline"
              />

              {/* Action Buttons */}
              <Space className="p-2">
                <Spacer />

                <Button size="small" type="primary" onClick={handleApply} data-testid="assigned-to-action">
                  {selectedFilter === UNASSIGNED_ID ? 'Save' : 'Assign'}
                </Button>
              </Space>
            </div>
          }
          id={`assignedTo-popover-${record?.id}`}
          open={showPopover}
          placement="left"
          arrow={false}
        />
      </Spin>
    </div>
  );
}

function findPopoverParent(element) {
  while (element) {
    if (element.classList && element.classList.contains('ant-popover')) {
      return element;
    }
    element = element.parentElement;
  }

  return null;
}
