import React from 'react';
import Card from 'components/Card/Card';
import TabularList from 'components/TabularList/TabularList';
import { getFullName } from 'utils';
import { Typography } from 'antd';
import { Events, User, UserMultiple } from '@carbon/icons-react';
import Space from 'components/Space/Space';

export function FamilyApplicationFamilySummary({ application }) {
  return (
    <Card title="Family summary">
      <Space className="mb-6">
        <User size={22} />
        <Typography.Title level={4} className="!m-0">
          Sponsor information
        </Typography.Title>
      </Space>

      <TabularList
        data={[
          {
            title: 'Name',
            value: getFullName(application?.applicant),
          },
          {
            title: 'Branch of service',
            value: application?.applicant?.position?.programSponsor?.title,
          },
          {
            title: 'Pay grade',
            value: application?.applicant?.position?.grade?.title,
          },
          {
            title: 'Status',
            value: application?.applicant?.position?.militaryStatus?.title,
          },
          {
            title: 'Date of birth',
            value: application?.applicant?.dateOfBirth,
          },
          {
            title: 'Contact email',
            value: application?.applicant?.workEmail,
          },
        ]}
      />

      <Space className="mt-12 mb-6">
        <UserMultiple size={22} />
        <Typography.Title level={4} className="!m-0">
          Spouse information
        </Typography.Title>
      </Space>

      {application?.additionalParents?.length === 0 ? (
        <Typography.Text type="secondary">No spouse</Typography.Text>
      ) : (
        (application?.additionalParents).map((_additionalParent, index) => (
          <TabularList
            key={_additionalParent.id}
            className={index !== 0 ? 'mt-8' : ''}
            data={[
              {
                title: 'Name',
                value: getFullName(_additionalParent),
              },
              {
                title: 'Date of birth',
                value: _additionalParent.dateOfBirth,
              },
              {
                title: 'Email',
                value: _additionalParent.email,
              },
              {
                title: 'Employment status',
                value: _additionalParent.employmentStatus,
              },
              {
                title: 'Employer',
                value: _additionalParent.position?.companyName,
              },
            ]}
          />
        ))
      )}

      <Space className="mt-12 mb-6">
        <Events size={22} />
        <Typography.Title level={4} className="!m-0">
          Child information
        </Typography.Title>
      </Space>

      {application?.children?.length === 0 ? (
        <Typography.Text type="secondary">No children</Typography.Text>
      ) : (
        application?.children.map((_child, index) => (
          <TabularList
            key={_child.id}
            className={index !== 0 ? 'mt-8' : ''}
            data={[
              {
                title: 'Name',
                value: getFullName(_child),
              },
              {
                title: 'Date of birth',
                value: _child.dateOfBirth,
              },
            ]}
          />
        ))
      )}
    </Card>
  );
}

// function ApplicationHome({ rootRef, history, match, loading }) {
//   const { id, householdId } = match.params;
//   const application = useSelector(selectors.selectApplication);
//   const isFormSubmitting = useSelector(selectors.selectIsFormSubmitting);
//   const status = useMemo(() => application?.status, [application?.status]);
//   const { contentRef } = useScrollLock({
//     topLimit: 90,
//     rootRef,
//   });

//   const [checklistOpen, setChecklistOpen] = React.useState(false);

//   return (
//     <div>
//       <div className="mt-12">
//         <div ref={contentRef}></div>
//         <div className="flex flex-col justify-center space-y-3 md:space-y-0 md:flex-row mb-5">
//           <div className="flex-grow">
//             <h3 className="text-xl">Family Application Dashboard</h3>
//           </div>

//           <div className="actions">
//             <Button
//               icon={<CheckCircleOutlined />}
//               onClick={() => {
//                 setChecklistOpen(true);
//               }}
//             >
//               Checklist
//             </Button>

//             <Button
//               type="primary"
//               onClick={() => history.push(`/families/${householdId}/applications/${id}/review`)}
//               data-testid="application-review-btn"
//             >
//               Review
//             </Button>
//           </div>
//         </div>

//         <CollapsePanel
//           header={
//             <div className="px-4 py-4 bg-white flex items-center">
//               <h4 className="text-base text-primary font-semibold uppercase">My Application Status</h4>
//             </div>
//           }
//           collapseClassName="application-status-collapse"
//         >
//           <ApplicationStatusCard
//             status={status}
//             loading={loading}
//             getPublicStatusList={familyService.getPublicStatusList}
//           />
//         </CollapsePanel>

//         <div className="block mt-6">
//           <CaseCoordinatorActivity
//             entity={application}
//             entityType={ENTITY_TYPES.FAMILY}
//             collapseHeaderClassName="py-4"
//           />
//         </div>
//       </div>

//       <Drawer
//         headerStyle={{ display: 'none' }}
//         bodyStyle={{ padding: 0 }}
//         open={checklistOpen}
//         onClose={() => {
//           setChecklistOpen(false);
//         }}
//       >
//         <FamilyChecklist application={application} id={id} className="py-4" isFormSubmitting={isFormSubmitting} />
//       </Drawer>
//     </div>
//   );
// }
