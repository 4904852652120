import React, { useMemo, useEffect, useCallback } from 'react';
import { Button, message, Spin, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-async-hook';

import Table from 'components/Table';
import ColumnTitle from 'components/ColumnTitle';
import useTablePagination from 'hooks/useTablePagination';
import { formatDate, getFullName, hasApplicationExpired } from 'utils';
import { actions } from 'features/family';
import commonService from 'services/common.service';
import config from 'config';
import { Add, Edit, Renew } from '@carbon/icons-react';
import Card from 'components/Card/Card';
import Space from 'components/Space/Space';

const PAGE_SIZE = 10;
function getCommonColumn(programs) {
  return [
    {
      title: (props) => <ColumnTitle {...props} title="ID" dataIndex="id" colKey="id" showSorting />,
      headerText: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: 150,
    },
    {
      title: (props) => (
        <ColumnTitle
          {...props}
          title={<span>Sponsor Name</span>}
          dataIndex={['applicant', 'firstName']}
          colKey="applicant"
          showSorting
        />
      ),
      headerText: 'Sponsor Name',
      dataIndex: ['applicant', 'firstName'],
      sorter: true,
      key: 'applicant',
      render: (value, { applicant }) => getFullName(applicant),
    },
    {
      title: (props) => (
        <ColumnTitle {...props} title="Submitted Date" dataIndex="submittedDate" colKey="submittedDate" showSorting />
      ),
      headerText: 'Submitted Date',
      dataIndex: 'submittedDate',
      key: 'submittedDate',
      sorter: true,
      render: (submittedDate) => {
        return submittedDate ? <span className="font-medium">{formatDate(submittedDate)}</span> : 'Not Submitted';
      },
      width: 250,
    },
    {
      title: (props) => <ColumnTitle {...props} title="Programs" dataIndex="program" colKey="programs" />,
      headerText: 'Programs',
      dataIndex: 'program',
      key: 'programs',
      render: (programId) => {
        const filteredProgram = programId && programs?.find?.((item) => item.id === programId);
        return (
          <span>
            {filteredProgram ? `${filteredProgram?.type?.title} (${filteredProgram?.sponsor?.title})` : 'N/A'}
          </span>
        );
      },
      width: 250,
    },
    {
      title: (props) => (
        <ColumnTitle
          {...props}
          colKey="status"
          title="Status"
          dataIndex={['status', 'status', 'publicStatus', 'title']}
          showSorting
        />
      ),
      width: 150,
      headerText: 'Status',
      dataIndex: ['status', 'status', 'publicStatus', 'title'],
      key: 'status',
      render: (status) => status || 'N/A',
      sorter: true,
    },
  ];
}

export default function FamilyApplications({ householdId, className }) {
  return householdId ? (
    <Applications householdId={householdId} className={className} />
  ) : (
    <Spin className="h-screen" spinning size="large">
      <div className="h-screen flex justify-center items-center">Loading Household Information...</div>
    </Spin>
  );
}
function Applications({ className, householdId }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { result: programs } = useAsync(async () => {
    try {
      return await commonService.getPrograms();
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Programs not found.', 5);
      return [];
    }
  }, []);

  const params = useMemo(
    () => ({
      current: 1,
      pageSize: PAGE_SIZE,
      sort: 'id,desc',
    }),
    [],
  );
  const applications = useTablePagination({
    url: `/families/households/${householdId}/applications`,
    params,
  });
  const reload = useCallback(async () => {
    return await applications.fetchMore({
      current: 1,
      pageSize: PAGE_SIZE,
    });
  }, [applications]);

  useEffect(() => {
    dispatch(actions.setApplication(null));
  }, [dispatch]);

  const commonColumns = useMemo(() => getCommonColumn(programs), [programs]);
  const allColumns = useMemo(
    () => [
      ...commonColumns,
      {
        title: <span className="action-header">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (_, record, index) => {
          return (
            <Space>
              <Tooltip placement="top" title="Edit">
                <Button
                  className="icon-btn edit-button"
                  onClick={() => {
                    history.push(`/family/application/${record.id}/dashboard`);
                    dispatch(actions.setApplication({}));
                  }}
                  icon={<Edit />}
                  aria-label="Edit"
                />
              </Tooltip>
            </Space>
          );
        },
      },
    ],
    [commonColumns, history, dispatch],
  );
  return (
    <div className={(className, 'p-6')}>
      <Card
        title="Applications"
        noBodyPadding
        extra={
          <Space>
            <Button type="primary" icon={<Add />} onClick={() => history.push('/family/application')}>
              New Application
            </Button>

            <Button
              icon={<Renew />}
              disabled={applications.loading}
              loading={applications.loading}
              onClick={reload}
              data-testid="reload-btn"
            />
          </Space>
        }
      >
        <Table
          allColumns={allColumns}
          data={applications.data}
          loading={applications.loading}
          pagination={applications.pagination}
          onChange={applications.onChange}
          rowClassName={(record, index) => {
            const isExpired = hasApplicationExpired(record, index);
            return isExpired && config.ENV !== 'uat'
              ? '[&_td]:!bg-red-100 h-12'
              : index % 2 === 0
              ? '[&_td]:!bg-light-bg h-12'
              : '[&_td]:!bg-white h-12';
          }}
          xsCols={['id', 'actions']}
          rowKey="id"
          showColSeparator={false}
        />
      </Card>
    </div>
  );
}
