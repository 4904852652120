import classNames from 'classnames';

// Styles
import './GridList.less';
import { EMDASH } from 'constants';

export default function GridList({ className, data }) {
  if (!data) {
    return null;
  }

  return (
    <div className={classNames('component-GridList', 'w-full flex-col bg-[#FAFAFA]', className)}>
      <div className={classNames('border border-solid border-gray-200', 'flex flex-wrap gap-4 gap-y-0')}>
        {data.map((d, rowIndex) => (
          <div
            key={typeof d?.title === 'string' ? d?.title : rowIndex}
            className="w-[calc(33.33333%-0.666666rem)] p-4 py-4 component-TabularList__content"
          >
            <div className="font-bold">
              {d?.titleRequired && <span className="text-red-500">*</span>}
              {d?.title}
            </div>

            <div>
              <div>{d?.value === undefined || d?.value === null ? EMDASH : d?.value}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
