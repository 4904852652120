import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

// Custom hook for URL parameter management that uses window.location directly
export function useUrlParams() {
  const history = useHistory();

  // Function to get the latest URL parameters directly from window.location
  const get = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};

    for (const [key, value] of searchParams.entries()) {
      if (value) {
        try {
          // First, try to decode the value
          const decodedValue = decodeURIComponent(value);

          // Check if it looks like JSON (starts with { or [)
          if (
            (decodedValue.startsWith('{') && decodedValue.endsWith('}')) ||
            (decodedValue.startsWith('[') && decodedValue.endsWith(']'))
          ) {
            try {
              params[key] = JSON.parse(decodedValue);
              continue; // Skip remaining checks if successful
            } catch (e) {
              // Not valid JSON despite appearances, continue with normal parsing
            }
          }
        } catch (e) {
          // Decoding failed, proceed with normal parsing
        }
      }

      // Handle comma-separated values for arrays
      if (value.includes(',')) {
        params[key] = value.split(',');
      }
      // Convert booleans and numbers
      else if (value === 'true') {
        params[key] = true;
      } else if (value === 'false') {
        params[key] = false;
      } else if (!isNaN(value) && value !== '') {
        params[key] = Number(value);
      } else {
        params[key] = value;
      }
    }

    return params;
  }, []);

  // Create a ref that will always hold the current params
  const paramsRef = useRef(get());

  // Set up a listener for URL changes using the popstate event
  useEffect(() => {
    // Function to update our ref when URL changes
    const updateParamsRef = () => {
      paramsRef.current = get();
    };

    // Listen for popstate events (browser back/forward)
    window.addEventListener('popstate', updateParamsRef);

    // Also create a MutationObserver to detect URL changes from history.replace
    const observer = new MutationObserver((mutations) => {
      // Check if the URL has changed
      updateParamsRef();
    });

    // Start observing the document title (as a proxy for URL changes)
    observer.observe(document.querySelector('title'), {
      subtree: true,
      characterData: true,
      childList: true,
    });

    return () => {
      window.removeEventListener('popstate', updateParamsRef);
      observer.disconnect();
    };
  }, [get]);

  // Function to update URL parameters
  const update = useCallback(
    (newParams = {}) => {
      const searchParams = new URLSearchParams(window.location.search);

      Object.entries(newParams).forEach(([key, value]) => {
        if (value === undefined || value === null || value === '') {
          searchParams.delete(key);
        } else if (typeof value === 'object') {
          // Automatically stringify and encode objects/arrays
          searchParams.set(key, encodeURIComponent(JSON.stringify(value)));
        } else {
          searchParams.set(key, value);
        }
      });

      const searchStr = searchParams.toString();
      const newPath = window.location.pathname + (searchStr ? `?${searchStr}` : '');

      history.replace(newPath);

      // Update the ref immediately after changing the URL
      paramsRef.current = get();
    },
    [get, history],
  );

  return { get, paramsRef, update };
}
