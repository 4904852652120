import { message, Spin, Typography } from 'antd';
import { DATE_FORMAT_US_SLASH } from 'components/AriaDatePicker/AriaDatePicker';
import Card from 'components/Card/Card';
import { FileList } from 'components/FileList/FileList';
import FormattedAddress from 'components/FormattedAddress/FormattedAddress';
import FormattedContact from 'components/FormattedContact/FormattedContact';
import TabularList from 'components/TabularList/TabularList';
import dayjs from 'dayjs';
import { capitalize } from 'lodash-es';
import { useState } from 'react';
import { useAsync } from 'react-async-hook';
import { providerService } from 'services';
import { PROVIDER_FILE_TYPES } from 'services/providers.service';

const { Title } = Typography;

export function CareLocationDetails({ application }) {
  const [careFacility, setCareFacility] = useState(null);
  useAsync(async () => {
    if (application?.careFacility?.id !== undefined) {
      try {
        const _careFacility = await providerService.getCenterById(Number(application?.careFacility?.id));
        setCareFacility(_careFacility);
      } catch (error) {
        console.log('Error fetching care facility', error);
        message.error('Unable to get care facility information', 5);
      }
    }
  }, [application]);

  // Fetch care facility files
  const [careFacilityFiles, setCareFacilityFiles] = useState(null);
  useAsync(async () => {
    if (application !== null && application?.careFacility?.id) {
      try {
        const _files = await providerService.getCareFacilityFiles(application?.careFacility?.id);
        setCareFacilityFiles(_files);
      } catch (error) {
        console.log('Error fetching care facility files', error);
        message.error('Something went wrong');
      }
    }
  }, [application?.careFacility?.id]);

  // Fetch organization files
  const [orgFiles, setOrgFiles] = useState(null);
  useAsync(async () => {
    if (application?.careFacility?.orgId !== undefined) {
      try {
        const _orgFiles = await providerService.getOrgFiles(application?.careFacility?.orgId);
        setOrgFiles(_orgFiles);
      } catch (error) {
        console.error('Error fetching organization files', error);
        message.error('Something went wrong');
      }
    }
  }, [application?.careFacility?.orgId]);

  const careFacilityAndOrgFiles = [...(careFacilityFiles || []), ...(orgFiles || [])];
  const _w9Files = careFacilityAndOrgFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.W9.code);
  const _einFiles = careFacilityAndOrgFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.EIN.code);
  const _licenseFiles = careFacilityAndOrgFiles?.filter(
    (file) => file?.fileType === PROVIDER_FILE_TYPES.STATE_LICENSE.code,
  );
  const _voidedCheckOrBankLetterFiles = careFacilityAndOrgFiles?.filter(
    (file) => file?.fileType === PROVIDER_FILE_TYPES.DIRECT_DEPOSIT.code,
  );

  return (
    <Card style={{ borderTop: '0px' }}>
      <Title level={3}>Care location details</Title>

      <Spin spinning={careFacility === null}>
        {careFacility && (
          <>
            <TabularList
              data={[
                {
                  title: 'Care location type',
                  titleRequired: true,
                  value: careFacility?.type?.title,
                },
                {
                  title: 'Doing business as (DBA)',
                  value: careFacility?.doingBusinessAs || careFacility?.businessLegalName,
                },
                {
                  title: 'Care location address',
                  titleRequired: true,
                  value: <FormattedAddress address={careFacility?.address} />,
                },
                {
                  title: 'Mailing address',
                  titleRequired: true,
                  value: <FormattedAddress address={careFacility?.mailingAddress} />,
                },
                {
                  title: 'Care location phone number',
                  titleRequired: true,
                  value: (
                    <>
                      {careFacility?.facilityPhone}
                      {careFacility?.facilityPhoneExt && <span> x{careFacility?.facilityPhoneExt}</span>}
                    </>
                  ),
                },
                {
                  title: 'Care location contact',
                  titleRequired: true,
                  value: <FormattedContact contact={careFacility?.facilityContact} />,
                },
              ]}
            />

            <Title level={3} className="mt-8">
              Care location tax details
            </Title>
            <TabularList
              data={[
                {
                  title: 'Business legal name',
                  titleRequired: true,
                  value: careFacility?.businessLegalName,
                },
                {
                  title: 'Tax classification',
                  titleRequired: true,
                  value: careFacility?.taxClassification?.title,
                },
                {
                  title: 'TIN',
                  titleRequired: true,
                  value: careFacility?.taxIdNumber,
                },
                {
                  title: 'W-9',
                  titleRequired: true,
                  value: <FileList files={_w9Files} />,
                },
                {
                  title: 'EIN certificate',
                  value: <FileList files={_einFiles} />,
                },
              ]}
            />

            <Title level={3} className="mt-8">
              Care location payment details
            </Title>
            <TabularList
              data={[
                {
                  title: 'Payment method',
                  titleRequired: true,
                  value: careFacility?.banking?.directDeposit ? 'Direct deposit' : 'Check',
                },
                ...(careFacility?.banking?.directDeposit
                  ? [
                      {
                        title: 'Routing number',
                        titleRequired: true,
                        value: careFacility?.banking?.routingNumber,
                      },
                      {
                        title: 'Account number',
                        titleRequired: true,
                        value: careFacility?.banking?.accountNumber,
                      },
                    ]
                  : []),
                {
                  title: 'Make check payable to',
                  titleRequired: true,
                  value: careFacility?.banking?.paymentName,
                },
                ...(careFacility?.banking?.directDeposit
                  ? [
                      {
                        title: 'Voided check or bank letter',
                        value: <FileList files={_voidedCheckOrBankLetterFiles} />,
                      },
                    ]
                  : []),
              ]}
            />

            <Title level={3} className="mt-8">
              Licenses
            </Title>

            <TabularList
              data={[
                {
                  title: 'License documents',
                  titleRequired: true,
                  value: <FileList files={_licenseFiles} />,
                },
              ]}
            />

            {careFacility?.licenses?.map((license, index) => (
              <>
                <Title level={4} className="mt-6">
                  License {index + 1}
                </Title>

                <TabularList
                  key={index}
                  data={[
                    {
                      title: 'License type',
                      titleRequired: true,
                      value: license.type?.title,
                    },
                    {
                      title: 'License term',
                      titleRequired: true,
                      value: capitalize((license?.duration?.split('_').join(' ') || '').toLowerCase()),
                    },
                    {
                      title: 'License number',
                      titleRequired: true,
                      value: license.licenseNumber,
                    },
                    {
                      title: 'Start date',
                      titleRequired: true,
                      value: dayjs(license.startDate).format(DATE_FORMAT_US_SLASH),
                    },
                    {
                      title: 'License end date',
                      value: license.neverExpires
                        ? 'Does not expire'
                        : dayjs(license.endDate).format(DATE_FORMAT_US_SLASH),
                    },
                    {
                      title: 'Date of last licensing inspection',
                      titleRequired: true,
                      value: dayjs(license.lastInspectionDate).format(DATE_FORMAT_US_SLASH),
                    },
                    {
                      title: 'Capacity',
                      value: license.totalCapacity,
                    },
                  ]}
                />
              </>
            ))}

            <Title level={3} className="mt-8">
              QRIS rating
            </Title>

            <TabularList
              data={[
                {
                  title: 'Do you have a quality rating by your state QRIS system?',
                  value: careFacility?.qrisList?.length > 0 ? 'Yes' : 'No',
                },
                ...(careFacility?.qrisList?.length
                  ? [
                      {
                        title: 'QRIS documents',
                        titleRequired: true,
                        value: <FileList files={_licenseFiles} />,
                      },
                    ]
                  : []),
              ]}
            />

            {careFacility?.qrisList?.map((qris, index) => (
              <>
                <Title level={4} className="mt-6">
                  QRIS {index + 1}
                </Title>

                <TabularList
                  key={index}
                  data={[
                    {
                      title: 'QRIS system',
                      titleRequired: true,
                      value: qris.system?.title,
                    },
                    {
                      title: 'Level',
                      titleRequired: true,
                      value: qris.level?.order,
                    },
                    {
                      title: 'Start date',
                      titleRequired: true,
                      value: dayjs(qris.startDate).format(DATE_FORMAT_US_SLASH),
                    },
                    {
                      title: 'End date',
                      value: qris.neverExpires ? 'Does not expire' : dayjs(qris.endDate).format(DATE_FORMAT_US_SLASH),
                    },
                  ]}
                />
              </>
            ))}
          </>
        )}
      </Spin>
    </Card>
  );
}
