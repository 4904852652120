import React from 'react';
import { useHistory } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom';
import { Tabs, Typography } from 'antd';
import PageMeta from 'components/PageMeta/PageMeta';
import { InternalUsers } from './__components/InternalUsers';
import { ProviderUsers } from './__components/ProvidersUsers';
import { FamilyUsers } from './__components/FamilyUsers';

export const USER_STATUSES = {
  FORCE_CHANGE_PASSWORD: 'Force Change Password',
  RESET_REQUIRED: 'Reset Required',
  UNCONFIRMED: 'Unconfirmed',
};

const USER_TYPES = {
  INTERNAL: 'internal',
  PROVIDERS: 'providers',
  FAMILIES: 'families',
};

export default function Users() {
  const history = useHistory();
  const location = history.location;
  const _activeTab = location?.pathname?.includes?.('/internal')
    ? USER_TYPES.INTERNAL
    : location?.pathname?.includes?.('/providers')
    ? USER_TYPES.PROVIDERS
    : USER_TYPES.FAMILIES;

  return (
    <>
      <Typography.Title level={1} className="!mb-0">
        Users
      </Typography.Title>

      <Tabs
        items={[
          {
            label: 'Internal',
            key: USER_TYPES.INTERNAL,
          },
          {
            label: 'Providers',
            key: USER_TYPES.PROVIDERS,
          },
          {
            label: 'Families',
            key: USER_TYPES.FAMILIES,
          },
        ]}
        activeKey={_activeTab}
        onChange={(key) => {
          history.push(`/admin/users/${key}`);
        }}
      />

      <Switch>
        <Route path="/admin/users/internal">
          <PageMeta title="Internal users" />

          <InternalUsers />
        </Route>

        <Route path="/admin/users/providers">
          <ProviderUsers />
        </Route>

        <Route path="/admin/users/families">
          <FamilyUsers />
        </Route>
      </Switch>
    </>
  );
}
