import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Button } from 'antd';
import { Link } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';

import Header from 'components/Header';
import useBreakpoints from 'hooks/useBreakpoints';
import NavigationTabs from 'components/NavigationTabs';
import styles from './Home.module.less';
import { actions } from 'features/search';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa';
import classNames from 'classnames';
// import { useFlags } from 'launchdarkly-react-client-sdk';
import { AUTH_STATES } from 'constants';
import { Spacer } from 'components/Space/Space';

const { Footer } = Layout;

export default function Home() {
  // const { showFamilySignupLinks } = useFlags();
  const { authState } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { screen } = useBreakpoints();
  const height = use100vh();
  const pageStyles = {};
  if (screen === 'xs') {
    pageStyles.height = height;
  }
  useEffect(() => {
    dispatch(actions.resetSearch());
  }, [dispatch]);

  return (
    <Layout className={styles.home} style={{ minHeight: height }}>
      <Header
        position="relative"
        className="page-header bg-white shadow-xl"
        centerContent={
          authState === AUTH_STATES.LOGGED_IN ? (
            <div className="flex flex-col header-center-content">
              <NavigationTabs theme="primary" className="center w-full" />
            </div>
          ) : null
        }
        theme="primary"
      />

      <div className="home-content px-5 md:px-12 xl:px-16 flex flex-col xl:flex-row text-center xl:text-left items-center">
        <div>
          <div className="title-container relative z-50">
            <h1 className="text-white">
              Child Care Aware®
              <br />
              of America Provider
              <br />
              Fee Assistance Application
            </h1>
          </div>

          <div className="stats-container">
            <div className={classNames('py-6 pt-0 text-white')}>
              <p className="text-[16px] leading-7 max-w-[500px] m-auto xl:m-0 whitespace-pre-wrap">
                Child Care Aware® of America is proud to partner with the United States Military and Department of
                Defense to serve and support their families through the Fee Assistance and Respite Child Care Programs.
              </p>

              <p className="block text-[16px] leading-7 max-w-[500px] mt-10 m-auto xl:mx-0">
                If you are a new provider, click below to register
              </p>

              <div className="flex space-x-3 my-5 mt-3 justify-center xl:justify-start">
                {/* {showFamilySignupLinks && (
                    <Link to="/family/signup">
                      <Button
                        type="primary"
                        className="!bg-blue-800 hover:!bg-blue-900 transition-all w-52 h-12 !rounded-xl"
                      >
                        Register as Family
                      </Button>
                    </Link>
                  )} */}

                <Link to="/provider/signup">
                  <Button
                    type="primary"
                    className="!bg-blue-800 hover:!bg-blue-900 transition-all w-52 h-12 !rounded-xl shadow-xl"
                  >
                    Sign Up Here
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Spacer />

        <div>
          <img
            src="/images/homepage.jpeg"
            alt=""
            className="w-full max-h-[400px] max-w-[600px] rounded-lg rounded-b-none xl:rounded-b-lg shadow-2xl mb-[-2px]"
          />
        </div>
      </div>

      <Footer className="footer w-full z-50 bg-white shadow-xl pb-36 xl:pb-7">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center h-full">
          <div className="flex flex-col xl:flex-row justify-start gap-4 xl:gap-6">
            <div>
              <a
                className="text-[#000] hover:text-primary"
                href="https://www.childcareaware.org/about/site-terms/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Site Terms
              </a>
            </div>

            <div>
              <a
                className="text-[#000] hover:text-primary"
                href="https://www.childcareaware.org/about/privacy-policy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </div>

            <div>
              <span>Copyright 2025 Child Care Aware® of America</span>
            </div>
          </div>

          <div className="flex justify-start xl:justify-between space-x-8 xl:space-x-3 h-7 mt-8 xl:mt-0 -ml-1 xl:ml-0">
            <a
              className="text-[#000] text-[28px]"
              href="https://www.facebook.com/ChildCareAware/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit us on Facebook"
            >
              <FaFacebookF />
            </a>

            <a
              className="text-[#000] text-[28px]"
              href="https://twitter.com/childcareaware"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit us on Twitter"
            >
              <FaTwitter />
            </a>

            <a
              className="text-[#000] text-[28px]"
              href="https://www.youtube.com/channel/UCeaTKlEpRfc00TJ537v0RZg"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit us on Youtube"
            >
              <FaYoutube />
            </a>

            <a
              className="text-[#000] text-[28px]"
              href="https://www.linkedin.com/company/childcareaware/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit us on Linkedin"
            >
              <FaLinkedinIn />
            </a>

            <a
              className="text-[#000] text-[28px]"
              href="https://www.instagram.com/childcareawareusa/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit us on Instagram"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </Footer>
    </Layout>
  );
}
