import React from 'react';

import Form from 'components/Form';
import { getFullName } from 'utils';
import ApplicationEntityReview from '../../__components/ApplicationEntityReview';
import { AsyncSelect } from 'components/Select';
import Card from 'components/Card/Card';
import classNames from 'classnames';
import { UserMultiple } from '@carbon/icons-react';
import Space from 'components/Space/Space';

export default function ChildInfoTab({ application, childIds, childId, profileData, index }) {
  const child = profileData?.children?.find((_child) => _child.id === childId);

  const _fullName = [
    (child?.prefix?.title || '').toLowerCase() !== 'prefer not to say' ? child?.prefix?.title : null,
    getFullName(child) || 'N/A',
  ]
    .filter(Boolean)
    .join(' ');
  const _sponsorFullName = getFullName(application?.applicant) || 'N/A';

  return (
    <Card
      title={_fullName}
      className={classNames('bg-white', {
        'mb-6': index !== childIds.length - 1,
        'mt-6': index !== 0,
      })}
    >
      <ApplicationEntityReview className="rounded-b-none" entity={child} isChild />

      <Card
        title={
          <Space size={3}>
            <UserMultiple />
            Relationship to sponsor
          </Space>
        }
        className="mt-10"
      >
        <div className="flex gap-3 items-center">
          <span className="font-bold">{_sponsorFullName}</span>

          <span>is the</span>

          <Form.Item
            name={[childId, 'relationshipType']}
            rules={[{ required: true, message: 'This field is required.' }]}
            className="m-0"
          >
            <AsyncSelect
              name={[childId, 'relationshipType']}
              placeholder="Relation"
              ariaLabel="Relationship to Sponsor"
              optionsApiUrl="/options/26"
              apiPrefix="/families"
              isClearable={false}
            />
          </Form.Item>

          <span>of</span>

          <span className="font-bold">{_fullName}</span>
        </div>
      </Card>
    </Card>
  );
}
