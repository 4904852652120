import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Button, message, Modal as AntdModal } from 'antd';
import { FaPlus } from 'react-icons/fa';
import { BiPlus } from 'react-icons/bi';
import { useAsyncCallback } from 'react-async-hook';
import throttle from 'lodash/throttle';

import Form from 'components/Form';
import Modal from 'components/Modal';
import FormItem from 'components/FormItem';
import SwitchField from 'components/Switch/SwitchField';
import { SecondParentSummary } from './Tabs';
import { ADD_FAMILY_STEPS } from 'constants/index';
import familyService from 'services/family.service';
import { getFullName } from 'utils';
import { AsyncSelect } from 'components/Select';
import classNames from 'classnames';
import SecondParentCreation from './Tabs/SecondParentCreation';
import Actions from '../../Actions';

const CREATE_NEW_ID = '__cc__app__entity__create-new__';

export default function SecondParent({
  id,
  tab,
  nestedTab,
  application,
  setStep,
  dispatch,
  openSteps,
  actions,
  household,
  ...rest
}) {
  const sponsorId = application?.applicant?.id;

  // const [familyRelationData, setFamilyRelationData] = useState({});
  const [newParentIds, setNewParentIds] = useState([]);
  // Stores parent info based on the __id__ of the parent
  const [newParentsForm] = Form.useForm();

  const [parentForm] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const singleParent = Form.useWatch('singleParent', parentForm);
  useEffect(() => {
    // Populate the `singleParent`-switch data
    if (application?.applicant?.id && singleParent === undefined) {
      parentForm.setFieldValue('singleParent', application?.applicant?.singleParent ?? false);
    }

    /*
     * Get family relation data for all the new parents
     * And populate the antd Form with the data
     */
    const getFamilyRelationData = async (_newParentIds) => {
      const _familyRelationData = {};
      _newParentIds.forEach(async (_parentId) => {
        const _relationshipData = await familyService.getFamilyRelation(household?.id, _parentId);
        const _relationshipToSponsor = _relationshipData.find(
          (_relationshipData) => _relationshipData?.relatedPerson?.id === sponsorId,
        );

        if (_relationshipToSponsor) {
          _familyRelationData[_parentId] = _relationshipToSponsor;

          newParentsForm.setFieldValue([_parentId, 'relationshipType'], _relationshipToSponsor?.relationshipType);
        }
      });

      // setFamilyRelationData(_familyRelationData);
    };

    if (
      newParentIds.length === 0 &&
      Array.isArray(application?.additionalParents) &&
      application?.additionalParents?.length !== 0
    ) {
      const _newParentIds = application?.additionalParents?.map((parent) => parent.id);
      setNewParentIds(_newParentIds);

      getFamilyRelationData(_newParentIds);
    }
  }, [
    application?.additionalParents,
    application?.applicant?.id,
    application?.applicant?.singleParent,
    household?.id,
    newParentsForm,
    newParentIds,
    parentForm,
    singleParent,
    sponsorId,
  ]);

  const { loading: savingSponsor, execute: setParentSingle } = useAsyncCallback(
    throttle(
      async () => {
        try {
          if (application?.id) {
            const singleParent = parentForm.getFieldValue('singleParent');
            const data = await familyService.addUpdateAdult(application.householdId, application.applicant?.id, {
              ...application.applicant,
              singleParent,
            });
            dispatch(
              actions.setApplication({
                ...application,
                applicant: data,
              }),
            );
          }
        } catch (error) {
          message.error('Unable to save information.');
          newrelic.noticeError(error);
        }
      },
      500,
      { trailing: true },
    ),
    [
      actions,
      application?.additionalParents?.length,
      application?.id,
      application?.sponsor?.id,
      dispatch,
      parentForm,
      rest,
    ],
  );

  const { execute: onDeleteParentConfirm } = useAsyncCallback(async (parentId) => {
    try {
      await familyService.deleteParent(application.id, parentId);
      const updatedParentArray = application.additionalParents.filter((parent) => parent.id !== parentId);
      // order of statements matters
      dispatch(actions.setApplication({ ...application, additionalParents: updatedParentArray }));
    } catch (error) {
      message.error('Unable to delete Parent.');
      newrelic.noticeError(error);
    }
  }, []);

  const onDeleteParent = useCallback(
    (index) => {
      const _id = newParentIds[index];
      AntdModal.confirm({
        title: 'Are you sure you want to delete this parent?',
        icon: null,
        async onOk() {
          // await onDeleteParentConfirm(id);

          // If already a registered parent, delete from db
          if (!_isNewUnregisteredParent(_id)) {
            await onDeleteParentConfirm(_id);
          }

          // delete index from parentIds
          const _newParentIds = newParentIds.filter((_, i) => i !== index);
          setNewParentIds(_newParentIds);
        },
        onCancel() {},
      });
    },
    [newParentIds, onDeleteParentConfirm],
    // [onDeleteParentConfirm],
  );

  const skipStep = useCallback(() => {
    rest.next({
      paths: {
        step: ADD_FAMILY_STEPS.SECOND_PARENTS,
        id: application.id,
      },
    });
  }, [application.id, rest]);

  useEffect(() => {
    if (application?.applicant?.singleParent !== singleParent) {
      setParentSingle();
    }
  }, [application?.applicant?.singleParent, setParentSingle, singleParent]);

  const commonProps = useMemo(
    () => ({
      id,
      tab,
      nestedTab,
      application,
      setStep,
      dispatch,
      actions,
      parentForm,
      household,
      ...rest,
    }),
    [id, tab, nestedTab, application, setStep, dispatch, actions, parentForm, household, rest],
  );

  // const disabledParentTabs = useFamilyDisabledTabs({ step: ADD_FAMILY_STEPS.SECOND_PARENTS, openSteps });

  const { loading, execute: addSecondParent } = useAsyncCallback(
    async (values) => {
      setNewParentIds((ids) => [...ids, values.secondParentId]);
      // setNewParentForms();

      setVisible(false);

      // try {
      //   await familyService.addSecondParent(id, values.secondParentId);
      //   setVisible(false);
      //   const newApplication = await familyService.getApplication(id);
      //   dispatch(actions.setApplication(newApplication));
      // } catch (e) {
      //   message.error({
      //     content: 'Unable to add parent to application.',
      //     duration: 5,
      //   });
      // }
    },
    [application, dispatch, actions, id],
  );

  const { execute: saveNewParents } = useAsyncCallback(
    async (values) => {
      try {
        newParentIds.forEach(async (_parentId) => {
          const _payload = values[_parentId];
          if (_payload) {
            if (_isNewUnregisteredParent(_parentId)) {
              const _registeredParent = await familyService.addUpdateAdult(household?.id, null, [_payload]);
              const _registeredParentId = _registeredParent?.[0]?.id;

              await familyService.addSecondParent(id, _registeredParentId);
              await familyService.addUpdateFamilyRelationship(household?.id, _registeredParentId, {
                // "typeOfMember": {
                //     "title": "Adult",
                //     "id": "adults"
                // },
                relatedPerson: {
                  id: sponsorId,
                },
                relationshipType: _payload.relationshipType,
                person: {
                  id: _registeredParentId,
                },
              });
            } else {
              await familyService.addSecondParent(id, _parentId);
              await familyService.addUpdateFamilyRelationship(household?.id, _parentId, {
                relatedPerson: {
                  id: sponsorId,
                },
                relationshipType: _payload.relationshipType,
                person: {
                  id: _parentId,
                },
              });
            }
          }
        });

        const newApplication = await familyService.getApplication(id);
        dispatch(actions.setApplication(newApplication));

        await new Promise((resolve) => setTimeout(resolve, 300));

        rest.next({
          paths: {
            step: ADD_FAMILY_STEPS.SECOND_PARENTS,
            id: application.id,
          },
        });
      } catch (e) {
        message.error({
          content: 'Unable to add parent(s) to application.',
          duration: 5,
        });
      }
    },
    [actions, familyService, id, newParentIds],
  );

  return (
    <>
      {newParentIds.length !== 0 && (
        <Form layout="vertical" form={newParentsForm} onFinish={saveNewParents}>
          {newParentIds.map((_id, i) => (
            <div key={_id}>
              {!_isNewUnregisteredParent(_id) && (
                <SecondParentSummary
                  {...commonProps}
                  onDelete={() => {
                    onDeleteParent(i);
                  }}
                  parentIds={newParentIds}
                  parentId={_id}
                  index={i}
                />
              )}

              {_isNewUnregisteredParent(_id) && (
                <SecondParentCreation
                  {...commonProps}
                  onDelete={() => {
                    onDeleteParent(i);
                  }}
                  parentIds={newParentIds}
                  parentId={_id}
                  index={i}
                />
              )}
            </div>
          ))}
        </Form>
      )}

      <div
        className={classNames('white-box', {
          'mt-20': newParentIds.length !== 0,
        })}
      >
        {newParentIds.length === 0 && (
          <div className="section-px pt-6">
            <Form form={parentForm} className="flex flex-col xl:flex-row mb-3 xl:space-x-4 w-full">
              <div className="flex-1">
                <FormItem name={['singleParent']} label="Single parent?" valuePropName="checked">
                  <SwitchField>
                    I would like to skip this step as I currently have no second parent, spouse or guardian relation.
                  </SwitchField>
                </FormItem>
              </div>
            </Form>
          </div>
        )}

        {singleParent && (
          <div className="section-px py-6 flex flex-col xl:flex-row xl:justify-between">
            <div className="w-52 flex xl:flex-col space-x-3 xl:space-x-0 xl:space-y-3">
              <Button
                htmlType="submit"
                className="w-full"
                onClick={() => skipStep()}
                disabled={!singleParent}
                loading={savingSponsor}
              >
                Skip This Step
              </Button>
            </div>
          </div>
        )}

        {!singleParent && newParentIds.length === 0 && (
          <div className="section-px py-6 flex flex-col xl:flex-row xl:justify-between items-center">
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
              }}
              icon={
                <div className="w-4 h-4">
                  <FaPlus className="w-4 h-4" />
                </div>
              }
              disabled={singleParent}
            >
              Add Spouse/Guardian
            </Button>
          </div>
        )}

        <Modal width={700} visible={visible} setVisible={setVisible} destroyOnClose>
          <Form layout="vertical" onFinish={addSecondParent}>
            <h3 className="block text-md font-semibold uppercase text-primary mt-2">Add Parent</h3>
            <p className="instructions mb-5">Please add a parent to the application.</p>
            <div className="w-full xxl:w-2/3">
              <Form.Item
                name="secondParentId"
                label="Additional Parent"
                rules={[{ required: true, message: 'Select additional parent.' }]}
              >
                <AsyncSelect
                  apiPrefix="/families"
                  optionsApiUrl={`/households/${household?.id}/adults`}
                  optionFormatter={(opt) => {
                    const _uniqueId = `${CREATE_NEW_ID}${_generateRandomId()}`;

                    return [
                      {
                        icon: <BiPlus className="w-4 h-4 align-middle" />,
                        label: 'Create New',
                        id: _uniqueId,
                        value: _uniqueId,
                      },
                    ].concat(opt.filter((_opt) => _opt?.id !== sponsorId && !newParentIds.includes(_opt.id)));
                  }}
                  getOptionLabel={(opt) => {
                    return _isNewUnregisteredParent(opt?.id) ? opt.label : getFullName(opt);
                  }}
                  isOptionSelected={(opt, [value]) => {
                    return opt.id === value;
                  }}
                  onChangeFormatter={(v) => v?.id}
                />
              </Form.Item>
            </div>

            <div className="actions flex">
              <Button onClick={() => setVisible(false)}>Cancel</Button>

              <Button data-testid="submit-btn" loading={loading} disabled={loading} type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal>
      </div>

      <Actions
        hideSaveBtn={newParentIds.length === 0}
        onSubmit={async () => {
          try {
            await newParentsForm.validateFields();
            newParentsForm.submit();
          } catch (error) {}
        }}
        onBack={() => {
          setStep(id, ADD_FAMILY_STEPS.SPONSOR);
        }}
        style={{ marginTop: '1rem' }}
      />
    </>
  );
}

const _isNewUnregisteredParent = (parentId) => `${parentId || ''}`.includes(CREATE_NEW_ID);
function _generateRandomId() {
  let result = Math.floor(Math.random() * 9 + 1).toString();

  for (let i = 1; i < 10; i++) {
    result += Math.floor(Math.random() * 10).toString();
  }

  return parseInt(result);
}
