import { useParams } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Spin,
  Tabs,
  Card,
  Form,
  Typography,
  Row,
  Col,
  Select,
  message,
  Button,
  Divider,
  Popconfirm,
  Tooltip,
  Dropdown,
  Breadcrumb,
} from 'antd';
import PageMeta from 'components/PageMeta/PageMeta';
import ApplicationDetails from './ApplicationDetails';
import { CareLocationDetails } from './CareLocationDetails';
import { DocumentDetails } from './DocumentDetails';
import { BusinessDetails } from './BusinessDetails';
import { formatDate, getFullName, hasPermission } from 'utils';
import { PROVIDER_APPLICATION_PROGRAM_TYPES } from 'containers/Provider/SelectProgram/SelectProgram';
import FormattedAddress from 'components/FormattedAddress/FormattedAddress';
import RadioSelect from 'components/Select/RadioSelect';
import { useAsync } from 'react-async-hook';
import { commonService, providerService } from 'services';
import { ROLES } from 'constants';
import { selectRoles, selectUser } from 'features/auth';
import { startCase } from 'lodash-es';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import Space, { Spacer } from 'components/Space/Space';
import { Chemistry, ChemistryReference, ChevronDown } from '@carbon/icons-react';
import { ENTITY_TYPES } from 'constants';
import ProviderApplicationNotesCard from './ApplicationDetails/ProviderApplicationNotesCard';
import { PROVIDER_STATUSES } from 'constants';

const { Paragraph, Title, Link } = Typography;

const ProviderApplication = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const currentUser = useSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState(null);
  const [form] = Form.useForm();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [assigneePlaceholder, setAssigneePlaceholder] = useState('');

  const { result: statusOptions = [] } = useAsync(async () => {
    return commonService.get('/providers/application-status-types/internal', { cache: { interpretHeader: false } });
  }, []);

  const { result: assigneeOptions = [] } = useAsync(async () => {
    return commonService.get(`/users/all?role=${ROLES.PROVIDER_COORDINATOR}`);
  }, []);

  const fetchApplication = useCallback(() => {
    setLoading(true);
    providerService
      .getApplication(id)
      .then((data) => {
        setApplication(data);
      })
      .catch((error) => {
        console.error('Error fetching application', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    fetchApplication();
  }, [fetchApplication, id]);

  useEffect(() => {
    if (application && statusOptions?.length && assigneeOptions?.length) {
      setSelectedStatus(application?.status?.status?.id);
      setSelectedAssignee(application?.assignedTo?.id);
      setAssigneePlaceholder('Unassigned');
    }
  }, [application, statusOptions, assigneeOptions]);

  useEffect(() => {
    // Check if 'activeTab' exists, and if not, set it to '1'
    if (!searchParams.activeTab) {
      searchParams.activeTab = 'application-details';
      history.replace(`${location.pathname}?${queryString.stringify(searchParams)}`);
    }
  }, [history, location.pathname, searchParams]); // Re-run if location or history changes

  const getProgramNameById = (programId) => {
    const program = Object.values(PROVIDER_APPLICATION_PROGRAM_TYPES).find((program) => program.id === programId);
    return program ? program.name : 'N/A';
  };

  const handleSave = async (assignee) => {
    try {
      setLoading(true);
      setSelectedAssignee(assignee);
      await providerService.assignCoordinator([
        {
          userId: assignee,
          applicationId: id,
        },
      ]);
    } catch (error) {
      console.error('Error assigning application', error);
      message.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      setLoading(true);
      setSelectedAssignee(null);
      await providerService.unAssignCoordinator([{ applicationId: id }]);
    } catch (error) {
      console.error('Error unassigning application', error);
      message.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const statusChanged = async (newStatus) => {
    try {
      setLoading(true);
      setSelectedStatus(newStatus);

      const _newApplication = await providerService.updateApplicationStatus(id, newStatus);
      setApplication(_newApplication);
    } catch (error) {
      console.error('Error updating application status', error);
      message.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const _isTestAccount = application?.careFacility?.organization?.testRecord === true;
  // Only taken into account when the application __IS__ a test account
  const [popConfirmIsOpen, setPopConfirmIsOpen] = useState(false);

  const userRoles = useSelector(selectRoles);
  const _userHasPermissionToToggleAsTest = hasPermission(userRoles, [ROLES.PROVIDER_MANAGER]);

  return (
    <Spin size="large" spinning={loading}>
      <div className="min-h-screen">
        <PageMeta title="Application Details" />

        <Breadcrumb separator=">">
          <Breadcrumb.Item
            onClick={() => {
              history.push('/providers');
            }}
            className="cursor-pointer underline decoration-primary"
          >
            <span className="text-primary">Provider application queue</span>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            {application?.careFacility?.businessLegalName || application?.careFacility?.doingBusinessAs || 'N/A'}
          </Breadcrumb.Item>
        </Breadcrumb>

        {/* Header Section */}
        <Card className="mt-8 mb-6">
          <div className="flex items-center">
            <Title level={1} className="!m-0">
              {application?.careFacility?.businessLegalName || application?.careFacility?.doingBusinessAs || 'N/A'}
            </Title>

            <Spacer />

            {application?.careFacility?.organization && (
              <Popconfirm
                placement="bottomLeft"
                description={
                  <div className="w-[70vw] max-w-[250px]">
                    {!_isTestAccount ? (
                      <>
                        You are about to change this to a test account. This will remove it from processing and
                        reporting.
                        <p className="block mt-4">Are you sure you'd like to proceed?</p>
                      </>
                    ) : (
                      <>
                        You are about to convert this record to a real account. This will include it in processing and
                        reporting.
                        <p className="block mt-4">Are you sure you'd like to proceed?</p>
                      </>
                    )}
                  </div>
                }
                onConfirm={() => {
                  if (!_userHasPermissionToToggleAsTest) {
                    return;
                  }

                  const _newTestRecord = !_isTestAccount;

                  providerService
                    .updateOrganization(application?.careFacility?.organization?.id, {
                      testRecord: _newTestRecord,
                    })
                    .then(() => {
                      message.success('Done');

                      // Refresh the application
                      fetchApplication();
                    })
                    .catch((error) => {
                      console.error('Error updating organization', error);
                      message.error('Something went wrong');
                    });
                }}
                onCancel={null}
                okText={!_isTestAccount ? 'Mark as Test' : 'Mark as Real Account'}
                okButtonProps={{ danger: true }}
                cancelText="Cancel"
                open={_isTestAccount ? popConfirmIsOpen : undefined}
                onOpenChange={(open) => {
                  if (_isTestAccount && open === false) {
                    setPopConfirmIsOpen(open);
                  }
                }}
              >
                <Tooltip
                  title={!_userHasPermissionToToggleAsTest ? 'Only Provider Managers can toggle the test status' : ''}
                >
                  {!_isTestAccount ? (
                    <Button disabled={!_userHasPermissionToToggleAsTest} icon={<ChemistryReference />}>
                      Mark as Test Account
                    </Button>
                  ) : _userHasPermissionToToggleAsTest ? (
                    <Dropdown
                      menu={{
                        items: [
                          {
                            label: 'Mark as real account',
                            key: 'mark-as-real',
                            onClick: () => {
                              setPopConfirmIsOpen(true);
                            },
                          },
                        ],
                      }}
                      trigger={['click']}
                    >
                      <Button icon={<Chemistry className="mb-[-1px] ml-[-1px] mr-[-2px]" />}>
                        <Space size={3}>
                          Test Account
                          {_userHasPermissionToToggleAsTest && <ChevronDown className="mb-[-1px]" />}
                        </Space>
                      </Button>
                    </Dropdown>
                  ) : (
                    <Button disabled>Test Account</Button>
                  )}
                </Tooltip>
              </Popconfirm>
            )}
          </div>

          <Divider />

          <Row gutter={[8, 8]} className="mt-4 w-3/4">
            <Col span={8}>
              <Paragraph className="!mb-1">
                <strong>Application ID:</strong> {application?.id}
              </Paragraph>
              <Paragraph className="!mb-1">
                <strong>Submitted:</strong> {formatDate(application?.submittedDate)}
              </Paragraph>
              <Paragraph className="!mb-1">
                <strong>Program:</strong> {getProgramNameById(application?.programType?.id)}
              </Paragraph>
            </Col>

            <Col span={8}>
              <Paragraph className="!mb-1">
                <strong>Care location address:</strong>
                <br />
                {application?.careFacility?.address ? (
                  <FormattedAddress address={application.careFacility.address} />
                ) : (
                  'N/A'
                )}
              </Paragraph>
            </Col>

            <Col span={8}>
              <Paragraph className="!mb-1">
                <strong>Applicant:</strong>{' '}
                {application?.careFacility?.organization?.businessContact?.displayName || 'N/A'}
                <br />
                {application?.careFacility?.organization?.businessContact?.position || 'N/A'}
                <br />
                {application?.careFacility?.organization?.businessContact?.email && (
                  <Link href={`mailto:${application.careFacility.organization.businessContact.email}`}>
                    {application.careFacility.organization.businessContact.email}
                  </Link>
                )}
              </Paragraph>
            </Col>
          </Row>
        </Card>

        {/* Main Section */}
        <div className="flex flex-col lg:flex-row gap-4">
          {/* Left Column */}
          <div className="lg:w-2/3 w-full">
            <Tabs
              activeKey={searchParams.activeTab}
              items={[
                {
                  key: 'application-details',
                  label: 'Application details',
                  children: <ApplicationDetails />,
                },
                {
                  key: 'care-location-details',
                  label: 'Care location details',
                  children: <CareLocationDetails application={application} />,
                },
                {
                  key: 'business-details',
                  label: 'Business details',
                  children: <BusinessDetails application={application} />,
                },
                {
                  key: 'documents',
                  label: 'Documents',
                  children: <DocumentDetails application={application} />,
                },
              ]}
              onChange={(key) => {
                // update activeKey search param
                searchParams.activeTab = key;
                history.replace(`${location.pathname}?${queryString.stringify(searchParams)}`);
              }}
              tabBarStyle={{
                marginBottom: '0px',
                border: 'none',
              }}
            />
          </div>

          {/* Right Column */}
          <div className="lg:w-1/3 w-full">
            <Card>
              <Typography.Title level={3} className="!mb-6">
                Review application
              </Typography.Title>

              <Form form={form} layout="vertical" data-testid="provider-application-review-form">
                <Tooltip
                  title={
                    application?.status?.status?.title === PROVIDER_STATUSES.INCOMPLETE
                      ? 'Status cannot be updated when application is incomplete'
                      : undefined
                  }
                >
                  <Form.Item label="Status" required>
                    <Select
                      name="review-status"
                      options={statusOptions
                        .sort((a, b) => {
                          const order = ['new', 'reviewed', 'incomplete'];
                          return order.indexOf(a.title.toLowerCase()) - order.indexOf(b.title.toLowerCase());
                        })
                        .map((o) => ({ value: o.id, label: startCase(o?.title?.toLowerCase()) }))}
                      value={selectedStatus}
                      onChange={statusChanged}
                      aria-label="Review Status"
                      data-testid="application-assign-status"
                      disabled={application?.status?.status?.title === PROVIDER_STATUSES.INCOMPLETE}
                    ></Select>
                  </Form.Item>
                </Tooltip>

                <Form.Item label="Assignee" required>
                  <RadioSelect
                    value={selectedAssignee}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    saveText="Save"
                    cancelText="Clear"
                    placeholder={assigneePlaceholder}
                    options={[
                      { label: getFullName(currentUser), value: currentUser?.id },
                      ...(assigneeOptions || [])
                        .filter((assignee) => assignee.id !== currentUser?.id)
                        .map((assignee) => ({ label: getFullName(assignee), value: assignee.id })),
                    ]}
                    data-testid="application-assign-reviewer"
                  />
                </Form.Item>
              </Form>
            </Card>

            <ProviderApplicationNotesCard
              entityType={ENTITY_TYPES.CARE_FACILITY}
              applicationId={id}
              mode="compact"
              className="mt-6"
            />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ProviderApplication;
