import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Input, Button, message } from 'antd';
import { Auth } from 'aws-amplify';

import Form from 'components/Form';
import GccModal from 'components/Modal';
import { passwordValidator } from 'utils';

export default function UpdatePasswordModal({ visible, setVisible }) {
  const [passwordForm] = Form.useForm();
  const [loading, setLoading] = useState(false);

  return (
    <GccModal
      visible={visible}
      setVisible={(flag) => {
        passwordForm.resetFields();
        setVisible(flag);
      }}
      width={320}
      getContainer="#profile-form-container"
    >
      <div className="section">
        <h3 className="section-title">Change Password</h3>
        <Form
          form={passwordForm}
          layout="vertical"
          onFinish={async (values) => {
            try {
              setLoading(true);
              const authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
              await Auth.changePassword(authUser, values.oldPassword, values.newPassword);
              message.success('Password updated successfully.');
              setVisible(false);
              passwordForm.resetFields();
            } catch (error) {
              newrelic.noticeError(error);
              if (error.code === 'NotAuthorizedException') {
                message.error('Old Password is incorrect.');
              } else {
                message.error(error.message || 'Unable to update password.');
              }
            } finally {
              setLoading(false);
            }
          }}
        >
          <Form.Item
            name="oldPassword"
            label="Old Password"
            rules={[{ required: true, message: 'Old Password is required' }, passwordValidator]}
          >
            <Input.Password type="password" placeholder="Old Password" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            // hasFeedback
            rules={[
              { required: true, message: 'New Password is required' },
              // { min: 12, message: 'Password must have at least 12 characters' },
              passwordValidator,
            ]}
          >
            <Input.Password type="password" placeholder="New Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm New Password"
            // hasFeedback
            rules={[
              { required: true, message: 'Confirm New Password is required' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Password does not match');
                },
              }),
            ]}
            dependencies={['newPassword']}
          >
            <Input.Password type="password" placeholder="Confirm New Password" />
          </Form.Item>
          <div className="actions flex">
            <Button onClick={setVisible} disabled={loading}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
              Save
            </Button>
          </div>
        </Form>
      </div>
    </GccModal>
  );
}

UpdatePasswordModal.propTypes = {
  setVisible: PropTypes.any,
  visible: PropTypes.any,
};
