import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { selectActiveRole, selectUser } from 'features/auth';
import Page from 'components/Layout/Page';
import FamilySteps from 'components/StepsLayout/FamilySteps';
import useBreadcrumbsParams from 'hooks/useBreadcrumbsParams';
import { selectors, actions } from 'features/family';
import { ADD_FAMILY_STEPS } from 'constants/index';
import { Sponsor, SecondParent, ChildrenInfo, Agreement, Submitted, Pograms, Start } from './Steps';
import { Spin } from 'antd';
import { useAsyncCallback } from 'react-async-hook';
import { familyService } from 'services';
import { message } from 'utils';
import ChildrenDocs from './Steps/ChildrenDocs/ChildrenDocs';
import SecondParentDocs from './Steps/SecondParentDocs/SecondParentDocs';

export default function AddApplication({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  useBreadcrumbsParams('id');
  let { id, step = ADD_FAMILY_STEPS.START, tab, nestedTab } = useParams();
  const openSteps = useSelector(selectors.selectOpenSteps);
  const navigationState = useRef(openSteps);
  navigationState.current = openSteps;
  const isFormSubmitting = useSelector(selectors.selectIsFormSubmitting);
  const isApplicationLoading = useSelector(selectors.selectLoading);
  const application = useSelector(selectors.selectApplication, (curr, prev) => isEqual(curr, prev));
  const household = useSelector(selectors.selectHousehold, (curr, prev) => isEqual(curr, prev));
  const isSubmitted = useSelector(selectors.selectApplicationSubmitted);
  const userInfo = useSelector(selectUser);
  const activeRole = useSelector(selectActiveRole);

  /*
   * Load profile data
   */
  const { execute: loadProfile, result: profileData } = useAsyncCallback(async () => {
    try {
      const houseHold = await familyService.getHouseHold();
      const children = await familyService.getChildren(houseHold.id);
      const adults = await familyService.getAdults(houseHold.id);
      dispatch(actions.setHousehold({ ...houseHold, children, adults }));
      return { ...houseHold, adults, children };
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to load profile data.', 5);
      return { adults: [], children: [] };
    }
  }, []);
  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  const setStep = useCallback(
    async (applicationId, newStep, tab, nestedTab) => {
      history.push(
        `/family/application/${applicationId}/${newStep}${tab === undefined ? '' : `/${tab}`}${
          nestedTab ? `/${nestedTab}` : ''
        }`,
      );
    },
    [history],
  );
  const next = useCallback(
    ({ paths, applicationId }) => {
      dispatch(
        actions.goNext({
          paths,
          history,
          prefixUrl: `/family/application/${applicationId ? applicationId : id}`,
          activeRole,
        }),
      );
    },
    [activeRole, dispatch, history, id],
  );
  const onCancel = useCallback(() => {
    history.push('/family/applications');
  }, [history]);

  useEffect(() => {
    dispatch(actions.getApplication(id));
  }, [dispatch, id]);
  useEffect(() => {
    /* istanbul ignore else */
    if (id && Number.isNaN(Number(id))) {
      history.push('/family/application');
    }
  }, [history, id]);
  useEffect(() => {
    dispatch(actions.resetOpenSteps());
  }, [dispatch]);

  useEffect(() => {
    /* istanbul ignore else */
    if (step || tab || nestedTab) {
      setTimeout(() => {
        const container = document.getElementById('page-top-placeholder');
        container?.scrollIntoView(
          {
            behavior: 'smooth',
          },
          300,
        );
      }, 500);
    }
  }, [nestedTab, step, tab]);

  const steps = useMemo(
    () => [
      { title: 'Sponsor', description: 'Select/Add Sponsor', key: ADD_FAMILY_STEPS.START },
      { title: 'Programs', description: 'Select Programs', key: ADD_FAMILY_STEPS.PROGRAMS },
      { title: 'Sponsor Info', description: 'Documents', key: ADD_FAMILY_STEPS.SPONSOR },
      { title: "Addt'l Parents", description: 'Profile', key: ADD_FAMILY_STEPS.SECOND_PARENTS },
      // ...((application?.additionalParents || []).length !== 0
      //   ? [{ title: 'Parents', description: 'Documents', key: ADD_FAMILY_STEPS.SECOND_PARENTS_DOCS }]
      //   : []),
      { title: 'Parents', description: 'Documents', key: ADD_FAMILY_STEPS.SECOND_PARENTS_DOCS },
      // { title: 'Eligibility', description: 'Family Eligibility', key: ADD_FAMILY_STEPS.ELIGIBILITY },
      { title: 'Child(ren)', description: 'Profiles', key: ADD_FAMILY_STEPS.CHILDREN },
      { title: 'Child Info', description: 'Documents', key: ADD_FAMILY_STEPS.CHILDREN_DOCUMENTS },
      { title: 'Agreement', description: 'E-sign', key: ADD_FAMILY_STEPS.AGREEMENT },
      { title: 'Submission', key: ADD_FAMILY_STEPS.SUBMITTED },
    ],
    [],
    // [application],
  );
  const commonProps = useMemo(
    () => ({
      activeRole,
      onCancel,
      setStep,
      actions,
      selectors,
      dispatch,
      history,
      step,
      tab,
      nestedTab,
      id,
      isFormSubmitting,
      isApplicationLoading,
      application,
      household,
      profileData,
      userInfo,
      openSteps,
      next,
    }),
    [
      activeRole,
      onCancel,
      setStep,
      dispatch,
      history,
      step,
      tab,
      nestedTab,
      id,
      isFormSubmitting,
      isApplicationLoading,
      application,
      household,
      profileData,
      userInfo,
      openSteps,
      next,
    ],
  );

  // Take user to final step if application is submitted
  useEffect(() => {
    if (isSubmitted) {
      setStep(application.id, ADD_FAMILY_STEPS.SUBMITTED);
    }
  }, [application, isSubmitted, setStep]);

  return (
    <div className="">
      <FamilySteps
        steps={steps}
        heading="Welcome, please verify your eligibility and we will help with next steps for your family."
        step={step}
        setStep={(stepKey) => {
          setStep(application.id, stepKey);
        }}
        openSteps={openSteps.steps}
        isSubmitted={isSubmitted}
      />

      <Spin spinning={isApplicationLoading}>
        <Page className="pt-0">
          <div id="page-top-placeholder" className="sr-only"></div>
          {step === ADD_FAMILY_STEPS.START && <Start {...commonProps} />}
          {step === ADD_FAMILY_STEPS.PROGRAMS && <Pograms {...commonProps} />}
          {step === ADD_FAMILY_STEPS.SPONSOR && <Sponsor {...commonProps} />}
          {step === ADD_FAMILY_STEPS.SECOND_PARENTS && <SecondParent {...commonProps} />}
          {step === ADD_FAMILY_STEPS.SECOND_PARENTS_DOCS && <SecondParentDocs {...commonProps} />}
          {step === ADD_FAMILY_STEPS.CHILDREN && <ChildrenInfo {...commonProps} />}
          {step === ADD_FAMILY_STEPS.CHILDREN_DOCUMENTS && <ChildrenDocs {...commonProps} />}
          {step === ADD_FAMILY_STEPS.AGREEMENT && <Agreement {...commonProps} />}
          {step === ADD_FAMILY_STEPS.SUBMITTED && <Submitted {...commonProps} />}
        </Page>
      </Spin>
    </div>
  );
}

export const APPLICATION_DEMO_DATA = {
  1: {
    sponsor: 'create_new',
    prefix: {
      id: 257,
      type: 2,
      sequenceNumber: 1,
      title: 'Mr',
      description: 'Mr',
    },
    firstName: 'John',
    middleName: 'R',
    lastName: 'Smith',
    maritalStatus: {
      description: 'Married',
      id: 57,
      sequenceNumber: 1,
      title: 'Married',
    },
    email: 'email@email.com',
    employmentStatus: 'Employed',
    phone: '+15555555555',
    homeAddress: {
      street: '1720 East 16th Street',
      street2: '',
      state: 'CA',
      city: 'Los Angeles',
      postalCode: '90021',
      location: {
        lat: 34.02301319999999,
        lon: -118.2417677,
      },
      description: '1720, East 16th Street, Los Angeles, CA, USA',
      placeId: 'ChIJ5SpCpiHGwoARHyA_uer6Ry4',
    },
    militaryEmployee: true,
    position: {
      selfEmployed: false,
      militaryComponent: { id: 1 },
      programSponsor: { id: 2 },
      militaryStatus: { id: 2 },
      grade: {
        id: 25,
        sequenceNumber: 2,
        title: 'E-1',
        description: 'E-1',
      },
    },

    // TODO - ADD to form
    enrolledInSchool: true,
    enrolledInUndergrad: true,
    enrolledInGraduate: false,
    enrolledInOther: false,
    dateOfBirth: '1990-02-08T00:12:52.962Z',
    workAddress: {
      street: '1720 East 16th Street',
      street2: '',
      state: 'CA',
      city: 'Los Angeles',
      postalCode: '90021',
      location: {
        lat: 34.02301319999999,
        lon: -118.2417677,
      },
      description: '1720, East 16th Street, Los Angeles, CA, USA',
      placeId: 'ChIJ5SpCpiHGwoARHyA_uer6Ry4',
    },
  },
  3: {
    applicant: {
      position: {
        militaryStatusId: 1,
      },
    },
    militaryGrade: {
      id: 25,
      sequenceNumber: 2,
      title: 'E-1',
      description: 'E-1',
    },
    recruiterType: {
      id: 49,
      sequenceNumber: 1,
      title: 'Non-recruiter',
      description: 'Non-recruiter',
    },
    state: 'FL',
  },
  4: {
    hasAdditionalAdult: true,
    prefix: {
      id: 373,
      sequenceNumber: 2,
      title: 'Mrs',
      description: 'Mrs',
    },
    firstName: 'Jane',
    middleName: 'K',
    lastName: 'Smith',
    maritalStatus: {
      id: 57,
      sequenceNumber: 1,
      title: 'Married',
      description: 'Married',
    },
    email: 'janeksmith@email.com',
    workEmail: 'MrsSmith@email.com',
    phone: '+15555555555',
    phoneType: 'Home',
    currentlyEmployed: false,
    enrolledInSchool: true,
  },
  5: {
    sponsor: 'create_new',
    prefix: {
      id: 257,
      type: 2,
      sequenceNumber: 1,
      title: 'Mr',
      description: 'Mr',
    },
    firstName: 'John',
    middleName: 'R',
    lastName: 'Smith',
    suffix: {
      id: 380,
      type: 2,
      sequenceNumber: 2,
      title: 'Sr',
      description: 'Sr',
    },
    maritalStatus: {
      description: 'Married',
      id: 57,
      sequenceNumber: 1,
      title: 'Married',
    },
    email: 'email@email.com',
    employmentStatus: 'Employed',
    phone: '+15555555555',
    homeAddress: {
      street: '1720 East 16th Street',
      street2: '',
      state: 'CA',
      city: 'Los Angeles',
      postalCode: '90021',
      location: {
        lat: 34.02301319999999,
        lon: -118.2417677,
      },
      description: '1720, East 16th Street, Los Angeles, CA, USA',
      placeId: 'ChIJ5SpCpiHGwoARHyA_uer6Ry4',
    },
    dateOfBirth: '1990-02-08T00:12:52.962Z',
    workAddress: {
      street: '1720 East 16th Street',
      street2: '',
      state: 'CA',
      city: 'Los Angeles',
      postalCode: '90021',
      location: {
        lat: 34.02301319999999,
        lon: -118.2417677,
      },
      description: '1720, East 16th Street, Los Angeles, CA, USA',
      placeId: 'ChIJ5SpCpiHGwoARHyA_uer6Ry4',
    },
  },
  6: {
    firstName: 'Josh',
    middleName: 'P',
    lastName: 'Smith',
    suffix: {
      description: 'II',
      id: 382,
      sequenceNumber: 4,
      title: 'II',
    },
    gender: 'MALE',
    homeAddress: {
      id: 392,
      street: '1720 East 16th Street',
      street2: '',
      state: 'CA',
      city: 'Los Angeles',
      postalCode: '90021',
      location: {
        lat: 34.02301319999999,
      },
      placeId: 'ChIJ5SpCpiHGwoARHyA_uer6Ry4',
    },
    dateOfBirth: '2018-06-01T12:12:52.962Z',
    workAddress: {
      street: '1720 East 16th Street',
      street2: '',
      state: 'CA',
      city: 'Los Angeles',
      postalCode: '90021',
      location: {
        lat: 34.02301319999999,
        lon: -118.2417677,
      },
      description: '1720, East 16th Street, Los Angeles, CA, USA',
      placeId: 'ChIJ5SpCpiHGwoARHyA_uer6Ry4',
    },
  },
  7: {
    enrolledInPreK: true,
    institutionName: 'Campbell Elementary',
    address: {
      street: '737 South Carlin Springs Road',
      street2: '',
      state: 'VA',
      city: 'Arlington',
      postalCode: '22204',
      location: {
        lat: 38.8583039,
        lon: -77.12569289999999,
      },
      description: '737 South Carlin Springs Road, Arlington, VA, USA',
      placeId:
        'EjE3MzcgU291dGggQ2FybGluIFNwcmluZ3MgUm9hZCwgQXJsaW5ndG9uLCBWQSwgVVNBIjESLwoUChIJWzKkw3S0t4kRqO36zHMJ5j0Q4QUqFAoSCcExtlpztLeJETcoXtGa-dmC',
    },
    type: {
      id: 4,
      title: 'Elementary School',
      description: 'Elementary School',
      sequenceNumber: 1,
      gradeRequired: true,
      isAdult: false,
    },
    grade: {
      id: 437,
      sequenceNumber: 2,
      title: 'Kindergarten',
      description: 'Kindergarten',
    },
    startDate: '2023-09-28T04:00:00.000Z',
    endDate: '2024-06-02T04:00:00.000Z',
  },
};
