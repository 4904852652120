import React from 'react';
import { useComponentSize } from 'react-use-size';

import { GroupContext } from 'components/Group';
import Switch from 'components/Switch';
import classNames from 'classnames';

const EligibilityOptions = ({ name, title, subtitle, description, aboutUrl, value, defaultChecked }) => {
  const { ref, width } = useComponentSize();
  return (
    <div className="relative h-full eligibility-option transition-all">
      <div className="relative h-full" ref={ref}>
        <h3 className="program-title text-black font-semibold mb-0">{title}</h3>
        <p
          className={classNames('program-sub-heading text-gray-400 font-semibold mb-4', {
            '!mb-2': width < 320,
          })}
        >
          {subtitle}
        </p>
        <GroupContext value={value}>
          <Switch
            yesLabel=""
            noLabel=""
            className={classNames('switch-btn absolute right-0 top-0 mb-5 !min-w-0', {
              '!relative': width < 320,
            })}
            defaultChecked={defaultChecked}
          />
        </GroupContext>
        <p className="program-description text-black font-normal">{description}</p>
        {/* <a
          href={aboutUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary hover:text-primary font-semibold block mt-2"
        >
          About the Program
        </a> */}
        {/* <a href="" className="text-primary font-semibold hover:text-primary block">
          Provider Requirements
        </a> */}
      </div>
    </div>
  );
};

export default EligibilityOptions;
