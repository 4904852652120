import React, { useCallback, useEffect, useContext, useMemo } from 'react';
import { Col, Row, message, Spin } from 'antd';
import classNames from 'classnames';
import { useAsync } from 'react-async-hook';

import Form from 'components/Form';
import Group from 'components/Group';
import Section from 'components/Section';
import SwitchField from 'components/Switch/SwitchField';
import EligibilityOptions from 'components/EligibilityOptions';
import Actions from 'containers/Family/AddApplication/Actions';
import familyService from 'services/family.service';
import commonService from 'services/common.service';
import { BRANCHES, PROGRAM_TYPES, ADD_FAMILY_STEPS } from 'constants/index';
import { getBooleanRequiredValidator, LayoutContext } from 'utils';

export default function Eligibility({ id, dispatch, next, actions, application, loading, setStep }) {
  const [form] = Form.useForm();
  const position = useMemo(() => application?.applicant?.position || {}, [application?.applicant?.position]);

  const { loading: programTypesLoading, result: programTypes = [] } = useAsync(
    async (branchId, componentId, statusId) => {
      if (branchId && componentId && statusId) {
        return await commonService.getProgramTypes(branchId, componentId, statusId);
      }
      return [];
    },
    [position?.programSponsor?.id, position?.militaryComponent?.id, position?.militaryStatus?.id],
  );
  const { result: programs = [] } = useAsync(async () => {
    return await commonService.get(`/common/programs?active=true`, { cache: { interpretHeader: false } });
  }, []);

  const onSubmit = useCallback(
    async (values) => {
      const { programTypeId } = values;
      const program = programs.find(
        (prog) => prog.type.id === programTypeId && prog.sponsor.id === position?.programSponsorId,
      );
      delete values.programTypeId;
      try {
        dispatch(actions.setIsFormSubmitting(true));
        await familyService.updateEligibilityProgram(application.id, program.id);

        const data = await familyService.saveApplication({
          id: application.id,
          ...values,
        });
        // order of statements matters
        dispatch(actions.setApplication(data));
        next({
          paths: {
            step: ADD_FAMILY_STEPS.PROGRAMS,
            id: data.id,
          },
        });
      } catch (error) {
        newrelic.noticeError(error);
        message.error(`Unable to ${application.id ? 'update' : 'create'} Application.`);
      } finally {
        dispatch(actions.setIsFormSubmitting(false));
      }
    },
    [actions, application.id, dispatch, next, position?.programSponsorId, programs],
  );

  useEffect(() => {
    form.setFieldsValue({
      id,
      ...application,
    });
  }, [application, form, id]);
  useEffect(() => {
    if (programTypes.length > 0 && programs.length > 0 && position?.programSponsorId && application?.program?.id) {
      const program = programs.find((prog) => prog.id === application?.program?.id);
      if (program) {
        form.setFieldsValue({
          programTypeId: program.type.id,
        });
      }
    }
  }, [
    position?.programSponsorId,
    programs,
    programTypes,
    application?.program?.id,
    form,
    application?.eligibilityAcknowledgmentStatus,
  ]);

  return (
    <div
      className={classNames(
        'h-full [&_ol]:list-decimal [&_ol]:pl-6 [&_ul]:list-disc [&_ul]:pl-6 [&_hr]:my-5 [&_h4]:text- [&_h4]:text-sm [&_h4]:font-semibold [&_h4]:uppercase [&_h4]:tracking-widest [&_h4]:text-primary [&_h4]:leading-4 [&_h4]:mb-5 [&_br]:last:hidden [&_h5]:text-sm [&_h5]:font-medium [&_h5]:uppercase [&_h5]:tracking-widest [&_h5]:text-gray-600  [&_h5]:leading-4 [&_h5]:my-5 [&_tr]:border [&_tr]:h-8 [&_table]:w-4/5 [&_table]:my-5 [&_td]:border [&_td]:pl-2',
      )}
    >
      <div className="white-box h-full">
        <Form
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          onValuesChange={({ programTypeId }) => {
            if (programTypeId) {
              form.setFieldsValue({ eligibilityAcknowledgmentStatus: null });
            }
          }}
        >
          {(values, form) => {
            return (
              <>
                <EligibilitySection
                  programTypes={programTypes}
                  programTypesLoading={programTypesLoading}
                  loading={loading}
                  form={form}
                  householdId={application?.householdId}
                />

                <Actions
                  onBack={() => setStep(id, ADD_FAMILY_STEPS.SECOND_PARENTS)}
                  onClear={() => form.resetFields()}
                  testId={`${ADD_FAMILY_STEPS.PROGRAMS}`}
                />
              </>
            );
          }}
        </Form>
      </div>
    </div>
  );
}

export function EligibilitySection({ householdId, application, programTypes, loading, programTypesLoading, form }) {
  const { collapsed } = useContext(LayoutContext);
  const position = application?.applicant?.position;
  const branch = position?.programSponsor?.id;
  const programTypeId = Form.useWatch(['programTypeId'], form);

  return (
    <>
      <Section
        heading="Select the Program you are applying  for:"
        headingClassName="page-title"
        className="section-border"
        collapsible={false}
      >
        <p className="instructions mb-6 opacity-60">
          Fields marked with * are mandatory. Please complete all necessary fields to determine if you are eligible for
          fee assistance programs.
        </p>
        <Form.Item
          name={['programTypeId']}
          id="center-info_services"
          rules={[
            {
              validator: async (_, value) => {
                if (!value || value?.length === 0) {
                  throw new Error('Please select at-least one program to continue.');
                }
              },
            },
          ]}
        >
          <Group comparator={(v) => v}>
            <Spin spinning={!loading && programTypesLoading} className="w-full flex justify-center">
              <Row gutter={[30, 30]}>
                {/* <Col xs={24}>
                      <p id="application_programTypes" className="text-red-500 text-xs mt-4">
                        {eligibilityTypesError}
                      </p>
                    </Col> */}
                {programTypes.map((program, index) => (
                  <Col
                    xs={24}
                    md={collapsed ? 12 : 24}
                    xl={collapsed ? 10 : 24}
                    xxl={collapsed ? 8 : 12}
                    flex="auto"
                    key={program.id}
                  >
                    <EligibilityOptions
                      name={program.title}
                      title={program.title}
                      // subtitle="Regularly scheduled child care"
                      description={program?.category?.description}
                      collapsed={collapsed}
                      value={program.id}
                      defaultChecked={!!application?.programType?.id}
                      // aboutUrl="https://www.childcareaware.org/fee-assistancerespite/feeassistancerespiteproviders/feeassistance"
                    />
                  </Col>
                ))}
                {/* {programTypes.length === 0 && <Col className="w-full h-48" xs={24}></Col>} */}
                {programTypes.length === 0 && (
                  <p className="w-full my-20 font-medium text-center text-gray-500">
                    No programs found for selected Branch, Component and Status. Please choose a valid combination
                  </p>
                )}
              </Row>
            </Spin>
          </Group>
        </Form.Item>
      </Section>
      {/* Navy branch "id = 4" and program type id "6 === CCYH" */}
      {branch === BRANCHES.Navy && programTypeId === PROGRAM_TYPES.CCYH && (
        <NavyProgramSection form={form} application={application} />
      )}
    </>
  );
}

function NavyProgramSection({ form, application }) {
  useEffect(() => {
    form.setFieldsValue({
      mccAccount: application?.mccAccount,
    });
  }, [application?.mccAccount, form]);
  return (
    <Section
      heading="Confirm Military Child Care Account and Profile Creation"
      collapsible={false}
      className="section-border"
    >
      <Form.Item
        name="mccAccount"
        valuePropName="checked"
        rules={[getBooleanRequiredValidator('This field is required.')]}
      >
        <SwitchField>I confirm that I created a MCC account and household profile</SwitchField>
      </Form.Item>
    </Section>
  );
}
