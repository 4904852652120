import { useMemo, useEffect, useCallback, useState } from 'react';
import { Breadcrumb, Button, ConfigProvider, Empty, Input, Typography } from 'antd';

import Form from 'components/Form';
// import BatchAssignment from '../Dashboard/BatchAssignment';
// import AdvancedSearchForm from '../Dashboard/AdvanceSearchForm';

import { parseFamilyData } from 'utils';
import { DASHBOARD_TYPES, FAMILY_DASHBOARD_COLUMNS, PROVIDER_DASHBOARD_COLUMNS } from 'constants/index';
import Card from 'components/Card/Card';
import Space, { Spacer } from 'components/Space/Space';
import { Close, Search, SearchLocate } from '@carbon/icons-react';
import PageMeta from 'components/PageMeta/PageMeta';
import DashboardTable from './DashboardTable';
import ProviderAdvancedSearchForm from './ProviderAdvanceSearchForm';
import { commonService } from 'services';
import { useSelector } from 'react-redux';
import { selectActiveRole, selectUser } from 'features/auth';
import { uniq } from 'lodash-es';
import DashboardFiltersForm from './DashboardFiltersForm';
import { useUrlParams } from 'hooks/useUrlParams';

const { Title } = Typography;

const PAGE_SIZE = 10;

const INITIAL_SORTER_INFO = {
  family: {
    columnKey: 'caseId',
    order: 'desc',
  },
  provider: {
    columnKey: 'submittedDate',
    order: 'desc',
  },
};

function processFiltersQueryParam(_filters) {
  return {
    ..._filters,
    programIds:
      typeof _filters?.programIds === 'string'
        ? _filters?.programIds?.split?.(',')?.map((d) => Number(d))
        : _filters?.programIds,
    statuses: typeof _filters?.statuses === 'string' ? _filters?.statuses?.split?.(',') : _filters?.statuses,
  };
}

function Applications({ advanced, isFamilyDashboard }) {
  const { get: getUrlParams, update: updateUrlParams } = useUrlParams();
  const urlParamsSnapshot = getUrlParams();

  const [query, setQuery] = useState(urlParamsSnapshot?.query);
  const user = useSelector(selectUser);
  const activeRole = useSelector(selectActiveRole);
  const _userIsManager = activeRole?.toLowerCase?.().includes?.('manager');
  const _userProgramTypes = uniq(user?.teams?.flatMap((_team) => _team.programTypes));

  const [form] = Form.useForm();
  const [quickSearchForm] = Form.useForm();
  const searchQuery = Form.useWatch('query', quickSearchForm);
  const includeTests = Form.useWatch('includeTests', quickSearchForm) || false;
  const [advanceSearchForm] = Form.useForm();

  // Dashboard filters
  const statuses = Form.useWatch('statuses', form);
  const programIds = Form.useWatch('programIds', form);
  const assigned = Form.useWatch('assigned', form) ?? null;
  const assigneeId = Form.useWatch('assigneeId', form) ?? null;
  const highPriorityOnly = Form.useWatch('highPriorityOnly', form) ?? null;

  // Get auto filters based on user role
  const getAutoFilters = useCallback(() => {
    return {
      assigneeId: !isFamilyDashboard && !_userIsManager ? user?.id : undefined,
      programIds: !isFamilyDashboard ? _userProgramTypes || undefined : undefined,
    };
  }, [isFamilyDashboard, _userIsManager, _userProgramTypes, user?.id]);

  // Set initial filters
  const [filteredInfo, setFilteredInfo] = useState(() => {
    if (urlParamsSnapshot?.filters) {
      return processFiltersQueryParam(urlParamsSnapshot?.filters);
    } else if (urlParamsSnapshot?.includeTests !== true && !urlParamsSnapshot?.query) {
      const _autoFilters = getAutoFilters();

      updateUrlParams({ filters: _autoFilters });
      return _autoFilters;
    }
  });
  const [sorterInfo, setSorterInfo] = useState(
    urlParamsSnapshot?.sort
      ? {
          columnKey: urlParamsSnapshot?.sort?.[0],
          field: urlParamsSnapshot?.sort?.[1],
          order: urlParamsSnapshot?.sort?.[2],
        }
      : INITIAL_SORTER_INFO[isFamilyDashboard ? 'family' : 'provider'],
  );
  const [hasInitiatedAdvancedSearch, setHasInitiatedAdvancedSearch] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: urlParamsSnapshot?.current !== undefined ? urlParamsSnapshot?.current : 1,
    pageSize: urlParamsSnapshot?.pageSize !== undefined ? urlParamsSnapshot?.pageSize : PAGE_SIZE,
    showSizeChanger: true,
  });

  const columns = useMemo(
    () => (isFamilyDashboard ? FAMILY_DASHBOARD_COLUMNS : PROVIDER_DASHBOARD_COLUMNS),
    [isFamilyDashboard],
  );

  const getFormattedSortFields = (sorterField) => {
    if (sorterField) {
      const isNestedValues = Array.isArray(sorterField);
      return isNestedValues ? sorterField.reduce((total, current) => `${total}.${current}`) : sorterField;
    }
    return null;
  };

  const dashboardFilters = useMemo(() => {
    const filters = {};
    if (statuses?.length) filters.statuses = statuses;
    if (programIds?.length) filters.programIds = programIds;
    if (assigned !== undefined) filters.assigned = assigned;
    if (highPriorityOnly !== undefined) filters.highPriorityOnly = highPriorityOnly;
    if (assigneeId) filters.assigneeId = assigneeId;
    return filters;
  }, [assigned, assigneeId, highPriorityOnly, programIds, statuses]);

  const params = useMemo(() => {
    const _queryParams = getUrlParams();
    const _searchQuery = _queryParams?.query;

    const formattedSortFields = getFormattedSortFields(sorterInfo.field);
    return {
      size: pagination?.pageSize,
      page: (pagination?.current || 1) - 1,
      ...(_searchQuery || advanced ? { query: _searchQuery } : dashboardFilters),
      sort: formattedSortFields
        ? `${formattedSortFields},${sorterInfo.order === 'ascend' ? 'asc' : 'desc'}`
        : undefined,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanced, dashboardFilters, pagination, sorterInfo]);

  const fetchData = useCallback(
    (configs) => {
      const { params: configParams, body: configBody } = configs || {};

      setLoading(true);

      const _searchQuery = getUrlParams()?.query;
      const apiUrl = isFamilyDashboard ? '/families/applications/search' : '/providers/applications';

      commonService
        .post(apiUrl, configBody, { query: _searchQuery, ...configParams })
        .then((res) => {
          setData(res.content);
          setPagination((_prev) => ({ ..._prev, total: res.totalElements }));
        })
        .catch((error) => {
          console.log('Error fetching applications', error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [getUrlParams, isFamilyDashboard],
  );

  const onChange = useCallback(
    (pagination, filters, sorter, customParams) => {
      setSorterInfo(sorter);
      setPagination((_prev) => ({
        ..._prev,
        current: pagination.current,
        page: pagination.current - 1,
        pageSize: pagination.pageSize,
        size: pagination.pageSize,
      }));

      // Update query params
      updateUrlParams({
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        sort: sorter?.columnKey === undefined ? null : `${sorter?.columnKey},${sorter?.field},${sorter?.order}`,
      });

      const _newFetchParams = {
        ...pagination,
        page: pagination.current - 1,
      };

      if (sorter?.columnKey) {
        if (customParams?.sort) {
          _newFetchParams.sort = customParams.sort;
        } else if (sorter.field && !_newFetchParams.sort) {
          const fieldSorter = getFormattedSortFields(sorter.field);
          _newFetchParams.sort = `${fieldSorter},${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
        }
      } else {
        _newFetchParams.sort = undefined;
      }

      // Add payload to body
      let body = { ...filteredInfo };

      //Check for advanced search form data
      if (advanced) {
        body = { ...advanceSearchForm.getFieldsValue() };
      }

      fetchData({
        params: { ...params, ..._newFetchParams, size: _newFetchParams.pageSize || params.size },
        body,
      });
    },
    [advanceSearchForm, advanced, fetchData, filteredInfo, params, updateUrlParams],
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const state = useMemo(() => {
    return {
      data,
      loading,
      onChange,
      pagination,
      selectedRowKeys,
      setSelectedRowKeys,
    };
  }, [data, loading, onChange, pagination, selectedRowKeys]);

  const mapper = useMemo(() => (isFamilyDashboard ? parseFamilyData : null), [isFamilyDashboard]);

  const onSearch = useCallback(
    (_query) => {
      setQuery(_query);

      // Clear all filters and sorting
      const _newFilters = !!_query
        ? {
            includeTests: true,
          }
        : getAutoFilters();
      setFilteredInfo(_newFilters);
      setSorterInfo({});

      // Update URL query params
      updateUrlParams({
        filters: _newFilters,
        query: _query,
      });

      fetchData({
        params: {
          ...params,
          current: 1,
          query: !!_query ? _query : undefined,
        },
        body: _newFilters,
      });
    },
    [fetchData, getAutoFilters, params, updateUrlParams],
  );

  const _noResults = state.data?.length === 0 && !state.loading;

  const filtersAreApplied = Object.values(filteredInfo).some((d) => d !== null);
  const sortingIsApplied = sorterInfo?.order !== undefined;
  const filterOrSortingApplied = filtersAreApplied || sortingIsApplied;

  useEffect(() => {
    if (!advanced) {
      fetchData({
        params: {
          ...params,
        },
        body: {
          includeTests: false,
          ...filteredInfo,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanced]);

  return (
    <div className="mt-2 min-h-min flex space-x-4 flex-row items-start">
      {advanced && !query && !isFamilyDashboard && (
        <Card className="min-w-[280px] mt-6" noBodyPadding>
          <ProviderAdvancedSearchForm
            form={advanceSearchForm}
            state={state}
            isFamilyDashboard={isFamilyDashboard}
            onSubmit={(data) => {
              const params = {
                current: 1,
                size: PAGE_SIZE,
              };

              setHasInitiatedAdvancedSearch(true);
              fetchData({ params, body: data });
            }}
          />
        </Card>
      )}

      {advanced && !hasInitiatedAdvancedSearch ? (
        <div className="flex justify-center items-center min-w-[50%] min-h-[400px]">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Start your advanced search" />
        </div>
      ) : (
        <div className="w-full">
          <Form onFinish={({ query }) => onSearch(query.trim?.())} initialValues={{ query }} form={quickSearchForm}>
            <Card
              className={advanced ? 'min-w-[50%] mt-6' : 'w-full mt-6'}
              noBodyPadding
              title={
                <Space className="flex-col md:flex-row items-start md:items-center my-4 md:my-0" vCentered={false}>
                  <div>
                    {state.loading !== true ? (
                      advanced ? (
                        <p data-testid="search-heading">
                          {_noResults ? (
                            <Space>
                              <span className="text-xs mb-[-1px]">❌</span> No results
                            </Space>
                          ) : (
                            <Space size={1}>
                              <span>{state.pagination?.total || 0}</span>result
                              {state.pagination?.total !== 1 ? 's' : ''}
                            </Space>
                          )}
                        </p>
                      ) : (
                        <>
                          <span>{state.pagination?.total || 0}</span> {isFamilyDashboard ? 'family' : 'provider'}{' '}
                          application
                          {state.pagination?.total !== 1 ? 's' : ''}
                        </>
                      )
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <Spacer />

                  <Form.Item name="query" className="mb-0">
                    <Input.Search
                      className="w-[400px]"
                      placeholder={
                        isFamilyDashboard
                          ? 'Sponsor name, app or family ID'
                          : 'Provider name, provider or application ID'
                      }
                      onSearch={() => quickSearchForm.submit()}
                      suffix={
                        <Button
                          onClick={() => {
                            quickSearchForm.setFieldsValue({ query: '' });
                            onSearch('');
                          }}
                          className="-mr-1"
                          style={{
                            display: !searchQuery ? 'none' : undefined,
                          }}
                          shape="circle"
                          icon={<Close />}
                          size="small"
                          type="text"
                          aria-label="Clear search"
                        />
                      }
                      enterButton={
                        <Button
                          type="primary"
                          aria-label="Search by provider name or ID"
                          data-testid="quick-search-button"
                        >
                          Search <Search />
                        </Button>
                      }
                      data-testid="quick-search-text"
                    />
                  </Form.Item>
                </Space>
              }
            >
              {!advanced && (
                <DashboardFiltersForm
                  fetchData={(_newFilters) => {
                    setPagination((_prev) => ({ ..._prev, page: 0, current: 1 }));
                    fetchData({
                      params: {
                        ...params,
                        page: 0,
                      },
                      body: { ...filteredInfo, ..._newFilters },
                    });
                  }}
                  isFamilyDashboard={isFamilyDashboard}
                  filters={filteredInfo}
                  setFilters={setFilteredInfo}
                />
              )}

              {state.data && (
                <ConfigProvider
                  renderEmpty={() => (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        state.loading
                          ? null
                          : filterOrSortingApplied || searchQuery || advanced
                          ? 'There are no results that match your search. Try clearing filters.'
                          : 'No data to show'
                      }
                    />
                  )}
                >
                  <DashboardTable
                    colKeys={columns}
                    state={state}
                    isFamilyDashboard={isFamilyDashboard}
                    mapper={mapper}
                    filteredInfo={filteredInfo}
                    sorterInfo={sorterInfo}
                    rowKey="id"
                    hideSelection
                    fetchData={() => {
                      fetchData({
                        params: {
                          ...params,
                          page: pagination?.page || 0,
                        },
                        body: { ...filteredInfo, includeTests },
                      });
                    }}
                  />
                </ConfigProvider>
              )}
            </Card>
          </Form>
        </div>
      )}

      {/* <BatchAssignment
        state={state}
        roles={roles}
        isFamilyDashboard={isFamilyDashboard}
        reload={reload}
        visible={state.showBatchAssign}
      /> */}
    </div>
  );
}

export default function ApplicationsDashboard({ match, history }) {
  const { path } = match;

  const [dashboardType, setDashboardType] = useState(
    path.startsWith('/families') ? DASHBOARD_TYPES.FAMILIES : DASHBOARD_TYPES.PROVIDERS,
  );
  const isFamilyDashboard = dashboardType === DASHBOARD_TYPES.FAMILIES;

  const [advanced, setAdvanced] = useState(false);

  const setAdvancedSearch = (isAdvanced) => {
    if (advanced === isAdvanced) return;
    setAdvanced(isAdvanced);

    if (isAdvanced) {
      history.push(`${isFamilyDashboard ? '/families/search' : '/providers/search'}`);
    } else {
      history.push(`${isFamilyDashboard ? '/families' : '/providers'}`);
    }
  };

  useEffect(() => {
    if (path.startsWith('/families')) {
      setDashboardType(DASHBOARD_TYPES.FAMILIES);
    } else if (path.startsWith('/providers')) {
      setDashboardType(DASHBOARD_TYPES.PROVIDERS);
    }
    if (path.endsWith('/search')) {
      setAdvancedSearch(true);
    } else {
      setAdvancedSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <>
      <PageMeta title={`${isFamilyDashboard ? 'Family' : 'Provider'} Applications`} />

      {advanced && (
        <Breadcrumb separator=">">
          <Breadcrumb.Item
            onClick={() => setAdvancedSearch(false)}
            className="cursor-pointer underline decoration-primary"
          >
            <span className="text-primary">{isFamilyDashboard ? 'Family' : 'Provider'} application queue</span>
          </Breadcrumb.Item>
          {advanced && <Breadcrumb.Item>Advanced search</Breadcrumb.Item>}
        </Breadcrumb>
      )}

      <Space className="flex-wrap">
        <Title level={1} className={advanced ? 'mt-4' : '!mb-2'}>
          {advanced ? 'Advanced search' : `${isFamilyDashboard ? 'Family' : 'Provider'} application queue`}
        </Title>

        <Spacer />

        {!isFamilyDashboard && !advanced && (
          <Button
            href="#"
            data-testid="advanced-search-button"
            onClick={(e) => {
              e?.preventDefault?.();

              setAdvancedSearch(true);
            }}
          >
            <span className="flex gap-2 items-center">
              Advanced search <SearchLocate className="mb-[-2px]" />
            </span>
          </Button>
        )}
      </Space>

      <Applications
        isFamilyDashboard={isFamilyDashboard}
        advanced={advanced}
        setAdvancedSearch={setAdvancedSearch}
        dashboardType={dashboardType}
      />
    </>
  );
}
