import { Spin } from 'antd';
import { DATE_FORMAT_US_SLASH } from 'components/AriaDatePicker/AriaDatePicker';
import Card from 'components/Card/Card';
import { showFilePreview } from 'components/FileList/FileList';
import Table from 'components/Table';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useAsync } from 'react-async-hook';
import { familyService } from 'services';
import { FAMILY_FILE_TYPES } from 'services/family.service';

export default function FamilyDocuments({ application }) {
  const [filePreviewLoading, setFilePreviewLoading] = useState(false);

  const [documents, setDocuments] = useState(null);
  useAsync(async () => {
    if (application?.id !== undefined) {
      const _documents = await familyService.getApplicationFiles(application?.id);
      setDocuments(_documents);
    }
  }, [application]);

  if (documents === null) {
    return <Spin />;
  }

  return (
    <Spin spinning={filePreviewLoading}>
      <Card
        title={
          documents.length === 0
            ? 'No documents found'
            : `${documents.length} Document${documents.length !== 1 ? 's' : ''}`
        }
        noBodyPadding
      >
        {!!documents?.length && (
          <Table
            columns={[
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => (
                  <a
                    href="#"
                    className="hover:underline"
                    onClick={(e) => {
                      e?.preventDefault?.();

                      showFilePreview(record, setFilePreviewLoading);
                    }}
                  >
                    {text}
                  </a>
                ),
              },
              {
                title: 'Type',
                dataIndex: 'fileType',
                key: 'fileType',
                render: (text) => FAMILY_FILE_TYPES?.[text]?.title,
              },
              {
                title: 'Upload Date',
                dataIndex: 'createdDate',
                key: 'createdDate',
                render: (text) => dayjs(text).format(DATE_FORMAT_US_SLASH),
              },
            ]}
            data={documents}
          />
        )}
      </Card>
    </Spin>
  );
}
