import { Typography } from 'antd';
import GridList from 'components/GridList/GridList';
import useMilitaryInfo from 'hooks/useMilitaryInfo';
import { formatDate, getCompletedAddress, getFormattedPhoneNumber, getFullName } from 'utils';

export default function ApplicationEntityReview({ className, entity, isChild, title, description, onDelete }) {
  const { status, installation, component, branch } = useMilitaryInfo({ person: entity });

  const _fullName = [
    (entity?.prefix?.title || '').toLowerCase() !== 'prefer not to say' ? entity?.prefix?.title : null,
    getFullName(entity) || 'N/A',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <>
      <div>
        <Typography.Title level={4}>Profile</Typography.Title>
        {isChild ? (
          <GridList
            data={[
              {
                title: 'Full Name',
                value: _fullName,
              },
              {
                title: 'Date of Birth',
                value: formatDate(entity?.dateOfBirth),
              },
            ]}
          />
        ) : (
          <GridList
            data={[
              {
                title: 'Full Name',
                value: _fullName,
              },
              {
                title: 'Date of Birth',
                value: formatDate(entity?.dateOfBirth),
              },
              {
                title: 'Marital Status',
                value: entity?.maritalStatus?.title,
              },
            ]}
          />
        )}

        <Typography.Title level={4} className="mt-10">
          Contact information
        </Typography.Title>
        {isChild ? (
          <GridList
            data={[
              {
                title: 'Home Address',
                value: getCompletedAddress(entity?.homeAddress) || 'N/A',
              },
            ]}
          />
        ) : (
          <GridList
            data={[
              {
                title: 'Prefered Phone',
                value: getFormattedPhoneNumber(entity?.phone) || 'N/A',
              },
              {
                title: 'Secondary Phone',
                value: getFormattedPhoneNumber(entity?.secondaryPhone) || 'N/A',
              },
              {
                title: 'Prefered Email',
                value: entity?.email || 'N/A',
              },
              {
                title: 'Secondary Email',
                value: entity?.workEmail || 'N/A',
              },
              {
                title: 'Home Address',
                value: getCompletedAddress(entity?.homeAddress) || 'N/A',
              },
            ]}
          />
        )}
      </div>

      {!isChild && !!entity?.position && (
        <>
          <Typography.Title level={4} className="mt-10">
            Military
          </Typography.Title>
          <GridList
            data={[
              {
                title: 'Branch',
                value: branch?.title || 'N/A',
              },
              {
                title: 'Component',
                value: component?.title || 'N/A',
              },
              {
                title: 'Status',
                value: status?.title || 'N/A',
              },
              {
                title: 'Grade',
                value: entity?.position?.grade?.title || 'N/A',
              },
              {
                title: 'Recruiter Type',
                value: entity?.position?.recruiterType?.title || 'N/A',
              },
              {
                title: 'Installation',
                value: installation?.name || 'N/A',
              },
            ]}
          />

          <Typography.Title level={4} className="mt-10">
            Employment
          </Typography.Title>
          <GridList
            data={[
              {
                title: 'Employment Status',
                value: entity?.employmentStatus || 'N/A',
              },
              {
                title: 'Commander Name',
                value:
                  [entity?.position?.supervisorCommanderFn, entity?.position?.supervisorCommanderln]
                    .filter(Boolean)
                    .join(' ') || 'N/A',
              },
              {
                title: 'Employment Details',
                value: entity?.militaryEmployee ? 'Military Personnel' : entity?.employmentStatus || 'N/A',
              },
              {
                title: 'Commander Phone',
                value: getFormattedPhoneNumber(entity?.position?.supervisorCommanderPhone) || 'N/A',
              },
              {
                title: 'Work Address',
                value: getCompletedAddress(entity?.workAddress) || 'N/A',
              },
              {
                title: 'Unit of Assignment',
                value: entity?.position?.unitOfAssignment || 'N/A',
              },
              {
                title: 'Unit ID Number',
                value: entity?.position?.unitIdCode || 'N/A',
              },
            ]}
          />
        </>
      )}
    </>
  );
}
