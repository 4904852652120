import React, { useCallback, useEffect, useMemo } from 'react';
import { message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync } from 'react-async-hook';
import dayjs from 'dayjs';

import { getBooleanRequiredValidator } from 'utils';
import Form from 'components/Form';
import Section from 'components/Section';
import Html from 'Html';
import { ADD_FAMILY_STEPS, DATE_FORMAT } from 'constants/index';
import CheckboxField from 'components/Switch/CheckboxField';
import FormItem from 'components/FormItem';
import Actions from 'containers/Family/AddApplication/Actions';
import familyService from 'services/family.service';
import commonService from 'services/common.service';
import { actions } from 'features/family';
import { selectors as documentSelectors } from 'features/documents';
import ScrollableTermsView from 'components/ScrollableTermsView/ScrollableTermsView';

export default function Agreement({ id, setStep, next, application, onCancel, step }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isEnabledAgreementCheckBox = useMemo(() => application?.agreementAccepted, [application?.agreementAccepted]);
  const { documents = {} } = useSelector(documentSelectors.selectDocumentsState);

  const { result: agreements = [], loading: loadingAgreement } = useAsync(async () => {
    if (application?.programType?.id) {
      return await commonService.get(`/common/agreements/family?programIds=${application?.programType?.id}`);
    }
    // step must be in dependency array to re-fetch agreement when step changes
  }, [application?.programType?.id, step]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        dispatch(actions.setIsFormSubmitting(true));
        if (!isEnabledAgreementCheckBox && values.agreementAccepted) {
          await familyService.acceptAgreement(id, {
            applicationId: Number(id),
            agreementIds: (agreements || []).map((agr) => agr.id),
          });
        }
        if (!application.submittedDate) {
          await familyService.submitApplication(id);
        }
        dispatch(
          actions.setApplication({
            agreementAccepted: true,
            agreementAcceptedDate: dayjs().format(DATE_FORMAT[2]),
          }),
        );
        next({
          paths: { step: ADD_FAMILY_STEPS.AGREEMENT, id },
        });
      } catch (error) {
        message.error('Unable to update application.', 5);
        newrelic.noticeError(error);
      } finally {
        dispatch(actions.setIsFormSubmitting(false));
      }
    },
    [dispatch, isEnabledAgreementCheckBox, application.submittedDate, next, id, agreements],
  );

  useEffect(() => {
    form.setFieldsValue({ agreementAccepted: application?.agreementAccepted, documents });
  }, [application?.agreementAccepted, documents, form]);

  return (
    <div className="h-full white-box">
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Spin spinning={loadingAgreement}>
          <div>
            <Section
              contentClassName="px-5 md:px-7 xl:px-6 -mt-2 mb-4 pb-2"
              heading="Acknowledgement and Agreement"
              collapsible={false}
            >
              <ScrollableTermsView className="mt-4">
                {agreements.map(({ url, id, name, content }, index) => (
                  <div key={id} className="mb-6">
                    <p className="text-center uppercase text-lg font-medium mb-5">{name}</p>
                    <Html html={content} />
                  </div>
                ))}
              </ScrollableTermsView>
            </Section>
          </div>

          <Section
            heading="Parent/Guardian Acknowledgement"
            headingClassName="text-gray-400 font-medium text-sm uppercase"
            contentClassName="pb-2"
            collapsible={false}
          >
            <FormItem
              name="agreementAccepted"
              valuePropName="checked"
              rules={[getBooleanRequiredValidator('You must Agree to Terms and Conditions.')]}
              className="px-5 md:px-7 xl:px-8"
            >
              <CheckboxField
                required
                textClassName="text-gray-600"
                checkboxProps={{ disabled: isEnabledAgreementCheckBox }}
              >
                <span>By selecting this checkbox, I acknowledge and agree to all terms in the above agreement.</span>
              </CheckboxField>
            </FormItem>

            {application?.agreementAcceptedDate && (
              <div className="absolute right-6 bottom-4">
                <span>Accepted Date: {application?.agreementAcceptedDate}</span>
              </div>
            )}
          </Section>
        </Spin>

        <Actions
          onBack={() => {
            setStep(id, ADD_FAMILY_STEPS.CHILDREN_DOCUMENTS);
          }}
          saveBtnLabel="Submit Application"
          testId={`${ADD_FAMILY_STEPS.AGREEMENT}`}
        />
      </Form>
    </div>
  );
}
