import React from 'react';
import { getFullName } from 'utils';
import Form from 'components/Form';
import ApplicationEntityReview from 'containers/Family/AddApplication/__components/ApplicationEntityReview';
import classNames from 'classnames';
import { AsyncSelect } from 'components/Select';
import Card from 'components/Card/Card';
import { Button } from 'antd';
import { TrashCan, UserMultiple } from '@carbon/icons-react';
import Space from 'components/Space/Space';

export default function Summary({ application, profileData, parentId, parentIds, index, onDelete }) {
  const parent = profileData?.adults?.find((adult) => adult.id === parentId);

  const _fullName = [
    (parent?.prefix?.title || '').toLowerCase() !== 'prefer not to say' ? parent?.prefix?.title : null,
    getFullName(parent) || 'N/A',
  ]
    .filter(Boolean)
    .join(' ');
  const _sponsorFullName = getFullName(application?.applicant) || 'N/A';

  return (
    <>
      <Card
        title={_fullName}
        className={classNames('bg-white', {
          'mb-6': index !== parentIds.length - 1,
          'mt-6': index !== 0,
        })}
        extra={
          <Button icon={<TrashCan />} danger onClick={onDelete}>
            Delete parent
          </Button>
        }
      >
        <ApplicationEntityReview entity={parent} />

        <Card
          title={
            <Space size={3}>
              <UserMultiple />
              Relationship to Sponsor
            </Space>
          }
          className="mt-10"
        >
          <div className="flex gap-3 items-center">
            <span className="font-bold">{_fullName}</span>
            <span>is the</span>
            <Form.Item
              name={[parentId, 'relationshipType']}
              rules={[{ required: true, message: 'This field is required.' }]}
              className="m-0"
            >
              <AsyncSelect
                name={[parentId, 'relationshipType']}
                placeholder="Relation"
                ariaLabel="Relationship to Sponsor"
                optionsApiUrl="/options/24"
                apiPrefix="/families"
                isClearable={false}
              />
            </Form.Item>

            <span>of</span>

            <span className="font-bold">{_sponsorFullName}</span>
          </div>
        </Card>
      </Card>
    </>
  );
}
