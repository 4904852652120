import React from 'react';
import Actions from '../../Actions';
import { ADD_FAMILY_STEPS } from 'constants/index';
import Documents from '../ChildrenInfo/Documents';

export default function ChildrenDocs({
  id,
  application,
  next,
  setStep,
  actions,
  dispatch,
  tab = '0',
  openSteps,
  onCancel,
  household,
  ...rest
}) {
  return (
    <div>
      <Documents {...{ ...rest, id, application, next, setStep, actions, dispatch, tab, openSteps }} />

      <Actions
        onSubmit={() => {
          next({
            paths: {
              step: ADD_FAMILY_STEPS.CHILDREN_DOCUMENTS,
              id,
            },
          });
        }}
        onBack={() => {
          setStep(id, ADD_FAMILY_STEPS.CHILDREN);
        }}
        style={{ marginTop: '1rem' }}
      />
    </div>
  );
}
